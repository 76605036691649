import React from 'react';

import likeInfo from '../../../assets/images/likeInfo.svg';

import styles from './LikeInfo.module.scss';

const LikeInfo = ({ length }) => {
  return (
    <div className={styles.like__wrapper}>
      <img src={likeInfo} alt="like icon" />
      <span>{length}</span>
    </div>
  );
};

export default LikeInfo;
