import React from 'react';
import { Hue, Saturation } from 'react-color/lib/components/common';
import { CustomPicker } from 'react-color';
import styles from './ColorPicker.module.scss';

const Picker = () => {
  return <div className={styles.picker} />;
};

const HuePicker = () => {
  return <div className={styles.hue_picker} />;
};

export const MyPicker = ({ onChange, ...rest }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.saturation}>
        <Saturation {...rest} onChange={onChange} pointer={Picker} />
      </div>

      <div className={styles.hue}>
        <Hue
          className={styles.test}
          {...rest}
          onChange={onChange}
          direction="vertical"
          pointer={HuePicker}
        />
      </div>
    </div>
  );
};

export default CustomPicker(MyPicker);
