import React, { useState } from 'react';

import Like from '../Like/Like';
import LikeInfo from '../LikeInfo/LikeInfo';

import CreateComment from '../CreateComment/CreateComment';
// import CommentList from '../CommentList/CommentList';
import AvatarUser from '../../AvatarUser/AvatarUser';

import differentTime from '../../../helpers/differentTime.helpers';

import comment from '../../../assets/images/comment.svg';

import styles from './Reply.module.scss';

const Reply = ({
  currentComment,
  commentId,
  length,
  createdAt,
  user,
  postId,
  text,
  count,
  likedByCurrentUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    avatar,
    //  id,
    firstname,
    lastname,
  } = user;
  return (
    <div className={styles.reply__wrapper}>
      <div className={styles.reply__content}>
        <AvatarUser avatar={avatar} />
        <div className={styles.reply__info}>
          <span className={styles.reply__info__name}>{`${firstname} ${lastname}`}</span>
          <p className={styles.reply__info__text}>{text}</p>
          {createdAt && (
            <span className={styles.reply__info__time}>{differentTime(createdAt)}</span>
          )}
        </div>
      </div>
      <div className={styles.reply__reacted}>
        <div className={styles.reply__reacted__left}>
          <Like
            likedByCurrentUser={likedByCurrentUser}
            parentCommentId={commentId}
            id={currentComment}
            type="comments"
            isReply
          />
          <span className={styles.reply__comment} onClick={() => setIsOpen(!isOpen)}>
            <img src={comment} />
            <span>Reply</span>
          </span>
        </div>
        <div className={styles.reply__reacted__rigth}>
          <LikeInfo length={count.usersLiked} />
        </div>
      </div>
      {isOpen && (
        <CreateComment commentId={commentId} withPatent id={postId} userId={user?.id} isReply />
      )}
    </div>
  );
};

export default Reply;
