import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBitcoin } from '../../features/bitcoin/bitcoin.slice';

import likeCudosReceived from '../../assets/images/likeCudosReceived.svg';
import likeCudosGiven from '../../assets/images/likeCudosGiven.svg';
import addonReceived from '../../assets/images/addonReceived.svg';
import addonGiven from '../../assets/images/addonGiven.svg';
import analyticBitcoin from '../../assets/images/analyticBitcoin.svg';

import styles from './AnalyticsInfo.module.scss';

const AnalyticsInfo = ({ value, title, imageType }) => {
  const dispatch = useDispatch();
  const coins = useSelector((state) => state.bitcoin.data);
  const url = 'https://api.coingecko.com/api/v3/coins/bitcoin?vs_currency=usd';

  useEffect(() => {
    if (coins && !coins.market_data) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => dispatch(setBitcoin(data)))
        // eslint-disable-next-line
        .catch((error) => console.log('Error:', error));
    }

    // eslint-disable-next-line
  }, [url]);

  const handleImage = (type) => {
    if (type === 'cudoReceived') {
      return (
        <img className={styles.analycs__icon} src={likeCudosReceived} alt="like Cudos Received" />
      );
    }
    if (type === 'cudoGiven') {
      return <img className={styles.analycs__icon} src={likeCudosGiven} alt="like Cudos Given" />;
    }
    if (type === 'addonReceived') {
      return <img className={styles.analycs__icon} src={addonReceived} alt="addon Received" />;
    }
    if (type === 'addonGiven') {
      return <img className={styles.analycs__icon} src={addonGiven} alt="addon Given" />;
    }
    return null;
  };

  const bitcoinValue = coins?.market_data?.current_price?.usd;
  const result = (value / bitcoinValue).toFixed(4);
  return (
    <div className={styles.analytics}>
      {handleImage(imageType)}
      <div>
        <span className={styles.analytics__subtitle}>{title}</span>
        <div className={styles.analytics__content}>
          <span className={styles.analytics__value}>${value}</span>
          <span className={styles.analytics__bitcoin}>
            <img src={analyticBitcoin} alt="bitcoin icon" />
            <span>{result} BTC</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsInfo;
