import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postRequest } from '../../../api/post';
import getRequest from '../../../api/get';

import styles from './CreateComment.module.scss';

import postArrow from '../../../assets/images/postArrow.svg';
import smile from '../../../assets/images/smile.svg';

import AvatarUser from '../../AvatarUser/AvatarUser';

import { updateReplyItems } from '../../../features/reply/reply.slice';
import { updateCommentItem, setComment } from '../../../features/comment/comment.slice';
import { setPostItem } from '../../../features/posts/posts-slice';

const CreateComment = ({ userId, commentId, withPatent, id, isReply }) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.comment.list);
  const posts = useSelector((state) => state.posts.list);

  const avatar = useSelector((state) => state.user.avatar);
  const [text, setText] = useState('');
  const handlerAddon = () => {
    let data = {
      text,
      postId: id,
    };

    if (withPatent) {
      data = {
        text,
        postId: id,
        parentId: commentId,
        replyToUserId: userId,
      };
    }

    postRequest(`kudos-wall/comments`, data).then((res) => {
      try {
        if (res) {
          if (commentId) {
            getRequest(`kudos-wall/comments/${commentId}`).then((res) => {
              try {
                if (res) {
                  const data = {
                    type: res,
                  };
                  comments?.find((item, index) => {
                    if (item.id === commentId) {
                      data.index = index;
                      return true;
                    }
                    return false;
                  });
                  dispatch(updateCommentItem(data));
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
            getRequest(`kudos-wall/comments/replies?commentId=${commentId}&page=1`).then((res) => {
              try {
                if (res && res.list) {
                  dispatch(updateReplyItems(res.list));
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
          } else {
            getRequest(`kudos-wall/posts/${id}`).then((res) => {
              try {
                if (res) {
                  const data = {
                    post: res,
                  };
                  res.isOpen = true;
                  posts?.find((item, index) => {
                    if (item.id === id) {
                      data.index = index;
                      return true;
                    }
                    return false;
                  });
                  dispatch(setPostItem(data));
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
            getRequest(`kudos-wall/comments?page=1&postId=${id}`).then((res) => {
              try {
                if (res && res.list) {
                  dispatch(setComment(res.list));
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
          }
          setText('');
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    });
  };

  return (
    <div className={styles.comment}>
      <AvatarUser avatar={avatar} />
      <label htmlFor="text">
        <input
          type="text"
          placeholder="Add a comment"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <img className={styles.comment__smile} src={smile} alt="smile" />
      </label>
      <button
        disabled={!text}
        className={styles.comment__button}
        style={{ opacity: text ? 1 : 0.7, cursor: text ? 'pointer' : 'not-allowed' }}
        type="button"
        onClick={() => handlerAddon()}
      >
        <img src={postArrow} alt="arrow" />
        <span>Post</span>
      </button>
    </div>
  );
};

export default CreateComment;
