import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LogoSmall } from '../../assets/images/logo-small.svg';
import styles from './AsideNav.module.scss';
import AsideNavItem from './AsideNavItem';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-collapse-aside.svg';
import { menuLinks } from '../../constants/menuLinks';

function AsideNav() {
  const { firstname, lastname, avatar, role } = useSelector((state) => state.user);

  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const links = menuLinks[role];

  return (
    <nav className={cn(styles.aside, { [styles.aside_collapsed]: menuCollapsed })}>
      <div className={styles.logoWrapper}>{menuCollapsed ? <LogoSmall /> : <Logo />}</div>
      <div className={styles.navLinks}>
        {links?.map((link, index) => (
          <AsideNavItem
            collapsed={menuCollapsed}
            key={index}
            link={link.link}
            title={link.title}
            icon={link.icon}
          />
        ))}
      </div>
      <div className={styles.profileLinkWrapper}>
        <NavLink
          className={({ isActive }) =>
            cn(styles.profileLink, { [styles.profileLink_active]: isActive })
          }
          to="/profile"
        >
          <div
            className={styles.userImage}
            style={{
              background: avatar && `url(${avatar}) no-repeat center / cover`,
            }}
          />
          {!menuCollapsed && <div>{firstname && lastname ? `${firstname} ${lastname}` : ''}</div>}
        </NavLink>
      </div>
      <div
        className={cn(styles.collapseArrow, { [styles.collapseArrow_collapsed]: menuCollapsed })}
        onClick={() => {
          setMenuCollapsed(!menuCollapsed);
        }}
      >
        <Arrow />
      </div>
    </nav>
  );
}

export default AsideNav;
