import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilterSortByRecent } from '../../features/transactions/transactions.slice';

import { ReactComponent as Letter } from '../../assets/images/filterLetter.svg';
import arrowDown from '../../assets/icons/arrowDown.svg';
import arrowUp from '../../assets/icons/arrowUp.svg';

import styles from './AnalitycsRecent.module.scss';

const AnalyticsInfo = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const sortBy = useSelector((state) => state.transactions.filter.sortBy);
  const orderDesc = useSelector((state) => state.transactions.filter.orderDesc);
  const type = useSelector((state) => state.transactions.type);

  useEffect(() => {
    if (sortBy !== 'DateTime') {
      setOpen(false);
    }
  }, [sortBy]);

  useEffect(() => {
    if (sortBy === 'DateTime' && orderDesc === true) {
      setOpen(true);
    }
    if (sortBy === 'DateTime' && orderDesc === false) {
      setOpen(false);
    }
  }, [orderDesc]);

  useEffect(() => {
    setOpen(false);
  }, [type]);

  return (
    <div className="filter">
      {isOpen ? (
        <div
          className={styles.filter__select}
          style={{ padding: 0 }}
          onClick={() => {
            setOpen(!isOpen);
            const data = {
              sortBy: 'DateTime',
              orderDesc: false,
            };
            dispatch(setFilterSortByRecent(data));
          }}
        >
          <div className={styles.filter__select__open}>
            <Letter />
            <span>Most Recent</span>
          </div>
          <span className={styles.filter__arrow__open}>
            <img src={arrowUp} alt="arrow down" />
          </span>
        </div>
      ) : (
        <div
          className={styles.filter__select}
          onClick={() => {
            setOpen(!isOpen);
            const data = {
              sortBy: 'DateTime',
              orderDesc: true,
            };
            dispatch(setFilterSortByRecent(data));
          }}
        >
          <Letter />
          <span>Most Recent</span>
          <img className={styles.filter__arrow__close} src={arrowDown} alt="arrow down" />
        </div>
      )}
    </div>
  );
};

export default AnalyticsInfo;
