import { postRequest } from './post';

export const postKudos = async (data, type, page) => {
  const { from, to, orderDesc, userIds, sortBy } = data;
  if (data && data.from !== '') {
    const filterFrom = JSON.parse(from);
    const filterTo = JSON.parse(to);
    const filterData = {
      page,
      orderDesc,
      userIds,
      sortBy,
      range: {
        from: filterFrom,
        to: filterTo,
      },
    };
    const result = await postRequest(`kudos/statistic/${type}/kudos`, filterData);
    return result.transactions;
  }
  const filter = {
    page,
    orderDesc,
    userIds,
    sortBy,
    range: {},
  };
  const result = await postRequest(`kudos/statistic/${type}/kudos`, filter);
  return result.transactions;
};

export const postAddons = async (data, type, page) => {
  if (data && Object.keys(data).length === 0) {
    const result = await postRequest(`kudos/statistic/${type}/addon`, { page });
    return result.transactions;
  }
  const { from, to, orderDesc, sortBy, userIds } = data;
  if (data && data.from !== '') {
    const filterFrom = JSON.parse(from);
    const filterTo = JSON.parse(to);
    const filterData = {
      page,
      orderDesc,
      userIds,
      sortBy,
      range: {
        from: filterFrom,
        to: filterTo,
      },
    };
    const result = await postRequest(`kudos/statistic/${type}/addon`, filterData);

    return result.transactions;
  }
  const filter = {
    page,
    orderDesc,
    userIds,
    sortBy,
    range: {},
  };
  const result = await postRequest(`kudos/statistic/${type}/addon`, filter);
  return result.transactions;
};
