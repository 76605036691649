import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './CsvUploader.module.scss';
import { ReactComponent as Upload } from '../../assets/icons/upload-cloud.svg';

function CsvUploader({ setCsv }) {
  const onDrop = useCallback((acceptedFiles) => {
    setCsv(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: false,
  });

  return (
    // eslint-disable-next-line
    <div
      // eslint-disable-next-line
      {...getRootProps({ className: 'dropzone disabled' })}
      className={styles.dropzoneWrapper}
      onClick={() => open()}
    >
      {/* eslint-disable-next-line */}
      <input {...getInputProps()} />

      <div className={styles.uploadButton}>
        <div className={styles.cloudWrapper}>
          <Upload />
        </div>
        <p>
          <span>Click to upload</span> or drag and drop
        </p>
        <p>.CSV format</p>
      </div>
    </div>
  );
}

export default CsvUploader;
