import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: {
    list: [],
    numberOfSelected: 0,
  },
  companies: {
    list: [],
    numberOfSelected: 0,
  },
};

const clientsSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setClients(state, action) {
      state.users.list = [
        ...state.users.list,
        ...action.payload.map((user) => {
          return { ...user, fullName: `${user.firstname} ${user.lastname}`, selected: false };
        }),
      ];
    },
    setCompanies(state, action) {
      state.companies.list = [
        ...state.companies.list,
        ...action.payload.map((company) => {
          return { ...company, selected: false };
        }),
      ];
    },
    clearClients(state) {
      state.users.list = [];
      state.users.numberOfSelected = 0;
    },
    clearCompanies(state) {
      state.companies.list = [];
      state.companies.numberOfSelected = 0;
    },

    toggleClientSelected(state, action) {
      const isUserSelected = state.users.list.find((user) => user.id === action.payload).selected;
      if (!isUserSelected) {
        state.users.numberOfSelected += 1;
      } else {
        state.users.numberOfSelected -= 1;
      }
      state.users.list.find((user) => user.id === action.payload).selected = !isUserSelected;
    },
    toggleCompanySelected(state, action) {
      const isCompanySelected = state.companies.list.find(
        (company) => company.id === action.payload
      ).selected;
      if (!isCompanySelected) {
        state.companies.numberOfSelected += 1;
      } else {
        state.companies.numberOfSelected -= 1;
      }
      state.companies.list.find((company) => company.id === action.payload).selected =
        !isCompanySelected;
    },
    setAllClientsSelected(state, action) {
      state.users.list = state.users.list.map((user) => {
        return { ...user, selected: action.payload };
      });
      if (action.payload) {
        state.users.numberOfSelected = state.users.list.length;
      } else {
        state.users.numberOfSelected = 0;
      }
    },
    setAllCompaniesSelected(state, action) {
      state.companies.list = state.companies.list.map((company) => {
        return { ...company, selected: action.payload };
      });
      if (action.payload) {
        state.companies.numberOfSelected = state.companies.list.length;
      } else {
        state.companies.numberOfSelected = 0;
      }
    },
  },
});

export const {
  setClients,
  toggleClientSelected,
  setAllClientsSelected,
  clearClients,
  setCompanies,
  toggleCompanySelected,
  setAllCompaniesSelected,
  clearCompanies,
} = clientsSlice.actions;
export default clientsSlice.reducer;
