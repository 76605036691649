import { createSlice } from '@reduxjs/toolkit';
import { capitalizeFirstLetter } from '../../helpers/common.helpers';

const role = localStorage.getItem('userRole');

const initialState = {
  id: null,
  role: role || null,
  avatar: null,
  firstname: null,
  lastname: null,
  username: null,
  email: null,
  dateOfBirth: null,
  startOfWork: null,
  phone: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.avatar = action.payload.avatar;
      state.firstname = capitalizeFirstLetter(action.payload.firstname);
      state.lastname = capitalizeFirstLetter(action.payload.lastname);
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.dateOfBirth = action.payload.dateOfBirth;
      state.startOfWork = action.payload.startOfWork;
      state.phone = action.payload.phone;
    },
    updateAvatar(state, action) {
      state.avatar = action.payload;
    },
  },
});

export const { setUserInfo, updateAvatar } = userSlice.actions;
export default userSlice.reducer;
