import React from 'react';
import ReactDatePicker from 'react-datepicker';
import './DatePicker.scss';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';

function DatePicker({ placeholder = 'DD/MM/YY', label, name, startDate, onChange }) {
  const years = range(1930, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <label className="react-datepicker-label">
      {label}
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 20px',
            }}
          >
            <button
              type="button"
              style={{
                background: 'none',
                outline: 'none',
                border: 'none',
                fontSize: '20px',
                color: 'var(--color-primary-5)',
                cursor: 'pointer',
              }}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>

            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              style={{
                background: 'none',
                outline: 'none',
                border: 'none',
                fontSize: '20px',
                color: 'var(--color-primary-5)',
                cursor: 'pointer',
              }}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        dateFormat="MM-dd-yyyy"
        placeholderText={placeholder}
        selected={(startDate && new Date(startDate)) || null}
        onChange={(date) => {
          onChange(name, date.toISOString());
        }}
      />
    </label>
  );
}

export default DatePicker;
