import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import styles from '../scss/pages/Auth.module.scss';
import AdminSignupForm from '../components/SignUpForms/AdminSignupForm/AdminSignupForm';
import LoginForm from '../components/LoginForm/LoginForm';

function Auth() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Routes>
          <Route path="signup" element={<AdminSignupForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
      </div>
      <div className={styles.right}>
        <div className={styles.rightText}>
          <h2>
            Welcome to a world of <br /> <span>Crypto at work</span>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Auth;
