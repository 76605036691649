import React, { useEffect } from 'react';
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from './StepThree/StepThree';

function AdminSignupForm() {
  const [currentStep, setCurrentStep] = React.useState(1);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (Number(query.get('step'))) {
      setCurrentStep(Number(query.get('step')));
    }
  }, []);
  return (
    <>
      {currentStep === 1 && <StepOne currentStep={currentStep} setStep={setCurrentStep} />}
      {currentStep === 2 && <StepTwo currentStep={currentStep} setStep={setCurrentStep} />}
      {currentStep === 3 && <StepThree currentStep={currentStep} setStep={setCurrentStep} />}
    </>
  );
}

export default AdminSignupForm;
