import React from 'react';
import { format } from 'date-fns';

import AvatarUser from '../../AvatarUser/AvatarUser';
import ButtonAddon from '../../ButtonAddon/ButtonAddon';

import styles from './Birthdays.module.scss';

const Birthdays = ({ id, avatar, lastname, firstname, dateOfBirth, addedOnByCurrentUser }) => {
  const time = format(new Date(dateOfBirth), 'MMMM dd');

  return (
    <div className={styles.birthdays__wrapper}>
      <div className={styles.birthdays__content}>
        <AvatarUser avatar={avatar} backgroundColor="grey" />
        <div className={styles.birthdays__info}>
          <a
            href="#"
            alt={`${firstname} ${lastname}`}
            className={styles.birthdays__detail}
          >{`${firstname} ${lastname}`}</a>
          <span className={styles.birthdays__time}>{time}</span>
        </div>
      </div>
      <div>
        <ButtonAddon
          addedOnByCurrentUser={addedOnByCurrentUser}
          id={id}
          type="Birthday"
          // eslint-disable-next-line
          tooltip={true}
        />
      </div>
    </div>
  );
};

export default Birthdays;
