import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state) {
      // eslint-disable-next-line no-param-reassign,no-plusplus
      state.isOpen = true;
    },
    hideModal(state) {
      // eslint-disable-next-line no-param-reassign
      state.isOpen = false;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
