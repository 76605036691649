import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './StepProgress.module.scss';
import { ReactComponent as Checkmark } from '../../assets/icons/step-checkmark.svg';

function StepProgress({ steps, currentStep = 1 }) {
  const stepsArr = [...Array(steps)].map((item, index) => index + 1);
  return (
    <div className={styles.wrapper}>
      {stepsArr.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.stepWrapper} key={index}>
          <div
            className={cn(styles.step, {
              [styles.active]: currentStep === step,
            })}
          >
            {currentStep > step ? <Checkmark /> : step}
          </div>
        </div>
      ))}
    </div>
  );
}

StepProgress.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number,
};

StepProgress.defaultProps = {
  currentStep: 1,
};

export default StepProgress;
