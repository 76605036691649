import { configureStore } from '@reduxjs/toolkit';
import modalReducer from '../features/modal/modal-slice';
import authReducer from '../features/auth/auth-slice';
import userReducer from '../features/user/user.slice';
import companyReducer from '../features/company/company.slice';
import postsReducer from '../features/posts/posts-slice';
import commentReducer from '../features/comment/comment.slice';
import replyReducer from '../features/reply/reply.slice';
import anniversariesReducer from '../features/anniversaries/anniversaries.slice';
import birthdaysReducer from '../features/birthdays/birthdays.slice';
import hashtagsReducers from '../features/hashtags/hashtags.slice';
import chartReducers from '../features/chart/chart.slice';
import bitcoinReducers from '../features/bitcoin/bitcoin.slice';
import clientsReducer from '../features/clients/clients.slice';
import transactionsReducer from '../features/transactions/transactions.slice';
import historyReducer from '../features/billing/billing.slice';

const store = configureStore({
  reducer: {
    modal: modalReducer,
    auth: authReducer,
    user: userReducer,
    company: companyReducer,
    posts: postsReducer,
    comment: commentReducer,
    reply: replyReducer,
    anniversaries: anniversariesReducer,
    birthdays: birthdaysReducer,
    hashtags: hashtagsReducers,
    chart: chartReducers,
    bitcoin: bitcoinReducers,
    clients: clientsReducer,
    transactions: transactionsReducer,
    history: historyReducer,
  },
});

export default store;
