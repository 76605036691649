import React from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../scss/pages/Profile.module.scss';
import ProfileDetails from '../components/ProfileDetails/ProfileDetails';
import PaymentMethods from '../components/PaymentMethods/PaymentMethods';
import CompanyDetails from '../components/CompanyDetails/CompanyDetails';

function Profile() {
  const role = useSelector((state) => state.user.role);

  return (
    <div className={styles.page}>
      <h2>Personal settings</h2>
      <div className={styles.navTabs}>
        <NavLink
          className={({ isActive }) => cn(styles.navlink, { [styles.navlink_active]: isActive })}
          to="details"
        >
          My details
        </NavLink>
        {role === 'Admin' && (
          <NavLink
            className={({ isActive }) => cn(styles.navlink, { [styles.navlink_active]: isActive })}
            to="company-details"
          >
            Company details
          </NavLink>
        )}
        {role !== 'SuperAdmin' && (
          <NavLink
            className={({ isActive }) => cn(styles.navlink, { [styles.navlink_active]: isActive })}
            to="payment-methods"
          >
            Payment methods
          </NavLink>
        )}
      </div>
      <Routes>
        <Route path="details" element={<ProfileDetails />} />
        <Route
          path="company-details"
          element={role === 'Admin' ? <CompanyDetails /> : <Navigate to="/" replace />}
        />
        <Route path="payment-methods" element={<PaymentMethods />} />
        <Route path="*" element={<Navigate to="details" replace />} />
      </Routes>
    </div>
  );
}

export default Profile;
