import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { openComment } from '../../features/posts/posts-slice';

import differentTime from '../../helpers/differentTime.helpers';

import Like from './Like/Like';
import PostReacted from './PostReacted/PostReacted';
import CommentList from './CommentList/CommentList';
import AvatarUser from '../AvatarUser/AvatarUser';

import CommentInfo from './CommentInfo/CommentInfo';
import ButtonAddon from '../ButtonAddon/ButtonAddon';

import { ReactComponent as Bitcoin } from '../../assets/images/bitcoin.svg';
import { ReactComponent as CommentIcon } from '../../assets/images/comment.svg';
import hashtagIcon from '../../assets/images/hashtagIcon.svg';
import dot from '../../assets/images/dot.svg';

import styles from './PostList.module.scss';

// eslint-disable-next-line react/display-name
const PostList = forwardRef(
  (
    {
      hashtags,
      id,
      recognizedUser,
      createdAt,
      createdBy,
      addedOnByCurrentUser,
      file,
      isAnonymous,
      text,
      btcAmount,
      likedByCurrentUser,
      firstUsersReacted,
      count,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const post = useSelector((state) => state.posts.list);
    const isOpen = post?.find((item) => item.id === id).isOpen;

    const openHandler = () => {
      dispatch(openComment(id));
    };

    return (
      <div ref={ref} className={styles.posts}>
        <div className={styles.posts__headers}>
          <div className={styles.posts__headers__left}>
            {createdBy && isAnonymous === false ? (
              <>
                <AvatarUser avatar={createdBy?.avatar} />
                <a href="#">
                  {createdBy.firstname} {createdBy.lastname}
                </a>
              </>
            ) : (
              'Anonymous '
            )}
            <span>gave</span>
            <a href="#">
              {recognizedUser && ` ${recognizedUser.firstname} ${recognizedUser.lastname}`}
            </a>
            <span>a Kudos! 🎉</span>
          </div>
          <div className={styles.posts__headers__right}>
            <span>{createdAt && differentTime(createdAt)}</span>
            <img src={dot} alt="dot" />
          </div>
        </div>
        <div>
          <button className={styles.posts__cudos} type="button">
            {`+$${btcAmount}`} <span>in</span>
            <Bitcoin />
          </button>
        </div>
        <p className={styles.posts__content}>{text}</p>
        {file && (
          <LazyLoadImage
            className={styles.posts__image}
            src={file}
            width={600}
            height={400}
            alt="gif image"
          />
        )}
        <div className={styles.posts__hashtags}>
          {hashtags.length > 0 &&
            hashtags.map((item) => {
              return (
                <button type="button" key={item.id}>
                  <img src={hashtagIcon} alt="hashtag icon" />
                  <span>{item.value}</span>
                </button>
              );
            })}
        </div>
        <div
          className={styles.posts__reacted}
          style={{
            justifyContent: count.comments > 0 && firstUsersReacted.length === 0 ? 'end' : '',
          }}
        >
          {firstUsersReacted && firstUsersReacted.length > 0 ? (
            <PostReacted firstUsersReacted={firstUsersReacted} />
          ) : null}
          {count && count.comments > 0 ? <CommentInfo type="post" length={count.comments} /> : null}
        </div>
        <div className={styles.posts__footer}>
          <div className={styles.posts__footer__left}>
            <Like likedByCurrentUser={likedByCurrentUser} id={id} type="posts" />
            <span className={styles.comment} onClick={() => openHandler()}>
              <CommentIcon style={{ stroke: isOpen ? '#0057D5' : 'transparent' }} />
              <span style={{ color: isOpen ? '#0057D5' : '#667085' }}>Comment</span>
            </span>
          </div>
          <div className={styles.posts__footer__right}>
            <ButtonAddon
              addedOnByCurrentUser={addedOnByCurrentUser}
              id={id}
              type="posts"
              tooltip={false}
            />
          </div>
        </div>
        {isOpen === true && <CommentList length={count.comments} id={id} />}
      </div>
    );
  }
);

export default PostList;
