import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import useClipboard from 'react-use-clipboard';
import { useDispatch } from 'react-redux';
import styles from './UsersList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { ReactComponent as CaseIcon } from '../../assets/icons/case-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { ReactComponent as Kebab } from '../../assets/icons/kebab-icon.svg';
import { ReactComponent as Checkmark } from '../../assets/icons/step-checkmark.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus-icon.svg';
import { toggleCompanyUserSelected } from '../../features/company/company.slice';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { showModal } from '../../features/modal/modal-slice';
import { getCompanyUser } from '../../api/companies';

// eslint-disable-next-line react/display-name
const UsersListItem = forwardRef(
  ({ user, setFireData, setFireModal, setUserInfoModal, setUserInfo }, ref) => {
    const [isCopied, setCopied] = useClipboard(user.addresses[0], { successDuration: 2000 });
    const [showKebab, setShowKebab] = useState(false);

    const menuRef = useRef();
    useOnClickOutside(menuRef, () => setShowKebab(false));

    useEffect(() => {
      if (!isCopied) ReactTooltip.rebuild();
    }, [isCopied]);

    const dispatch = useDispatch();

    const toggleCheckbox = () => {
      dispatch(toggleCompanyUserSelected(user.id));
    };

    return (
      <li ref={ref} className={cn(styles.row, { [styles.rowSelected]: user.selected })}>
        <div>
          <Checkbox onChange={() => toggleCheckbox()} checked={user.selected} />
        </div>
        <div className={styles.col}>
          <div
            className={styles.userImage}
            style={{ background: user.avatar && `url(${user.avatar}) no-repeat center / cover` }}
          ></div>
          {user.firstname ? user.fullName : 'New User'}
        </div>

        <div className={cn(styles.col, styles.light)}>{user.email}</div>

        <div className={styles.col}>{user.role}</div>

        <div
          className={cn(styles.col, styles.status, {
            [styles.status_enrolled]: user.isEnrolled,
            [styles.status_fired]: user.isFired,
          })}
        >
          <span>
            {user.isEnrolled && !user.isFired
              ? 'Enrolled'
              : user.isFired
              ? 'Fired'
              : 'Not Enrolled'}
          </span>
        </div>

        <div className={cn(styles.col, styles.walletNumberWrapper)}>
          {user.role === 'Admin' ? <CaseIcon /> : <UserIcon />}
          <span className={styles.walletNumber}>
            {user.addresses[0] &&
              `${user.addresses[0].substring(0, 4)}...${user.addresses[0].substring(
                user.addresses[0].length - 4
              )}`}
          </span>

          {isCopied
            ? user.addresses[0] && <Checkmark />
            : user.addresses[0] && (
                <CopyIcon
                  data-tip="Wallet address copied"
                  data-event="mouseup"
                  data-for="success"
                  data-delay-hide="2000"
                  data-event-off="mouseleave"
                  onClick={() => {
                    setCopied();
                  }}
                />
              )}
        </div>
        <ReactTooltip className="customTooltip" id="success" effect="solid" place="bottom" />

        <div
          ref={menuRef}
          className={cn(styles.col, styles.kebabMenu)}
          onClick={() => setShowKebab(!showKebab)}
        >
          <div className={cn(styles.userMenu, { [styles.userMenu_open]: showKebab })}>
            <div
              className={styles.userMenu_item}
              onClick={() => {
                setUserInfo(null);
                setUserInfoModal();
                dispatch(showModal(true));
                getCompanyUser(user.id).then((res) => setUserInfo(res));
              }}
            >
              <UserIcon />
              View profile
            </div>
            <div
              onClick={() => {
                toggleCheckbox();
                setFireModal();
                dispatch(showModal(true));
                setFireData({
                  users: [user.id],
                  reason: '',
                });
              }}
              className={cn(styles.userMenu_item, styles.userMenu_item__fire)}
            >
              <Minus />
              Fire employee
            </div>
          </div>
          <Kebab />
        </div>
      </li>
    );
  }
);

export default UsersListItem;
