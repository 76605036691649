import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getRequest from '../../../api/get';

import { setComment } from '../../../features/comment/comment.slice';

import CreateComment from '../CreateComment/CreateComment';
import Comment from '../Comment/Comment';

// import styles from './CommentList.module.scss';

const CommentList = ({ length, id }) => {
  const comments = useSelector((state) => state.comment.list);
  const dispatch = useDispatch();
  useEffect(() => {
    if (length && length > 0) {
      getRequest(`kudos-wall/comments?page=1&postId=${id}`).then((res) => {
        try {
          if (res && res.list) {
            dispatch(setComment(res.list));
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      });
    } else {
      dispatch(setComment([]));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <CreateComment id={id} withPatent={false} isReply={false} />
      {comments &&
        comments.length > 0 &&
        comments.map((item) => {
          return (
            <Comment
              key={item.id}
              id={item.id}
              commentId={item.id}
              length={length}
              createdAt={item.createdAt}
              user={item.user}
              postId={item.postId}
              text={item.text}
              count={item._count}
              likedByCurrentUser={item.likedByCurrentUser}
            />
          );
        })}
    </div>
  );
};

export default CommentList;
