import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './StepOne.module.scss';
import logo from '../../../../assets/images/logo.svg';
import Input from '../../../../UI/Input/Input';
import Checkbox from '../../../../UI/Checkbox/Checkbox';
import Button from '../../../../UI/Button/Button';
import googleLogo from '../../../../assets/icons/google-logo.svg';
import validationSchema from './StepOne.schema';
import StepProgress from '../../../StepProgress/StepProgress';
import { registerUserStepOne } from '../../../../api/users';
import { login, loginUserFromRedirect, registerWithGoogle } from '../../../../api/login';
import Modal from '../../../Modal/Modal';
import Spinner from '../../../../UI/Spinner/Spinner';
import { showModal } from '../../../../features/modal/modal-slice';

function StepOne({ setStep, currentStep }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [queryEmail, setQueryEmail] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: queryEmail || '',
      firstname: '',
      lastname: '',
      username: queryEmail?.split('@')[0] || '',
      recoveringEmail: '',
      acceptTerms: false,
    },
    validationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-vars
      const { acceptTerms, ...other } = values;
      login('auth/login', values).then(() => {
        registerUserStepOne({
          ...other,
          email: other.email.toLowerCase(),
          recoveringEmail: other.recoveringEmail.toLowerCase(),
        }).then(() => {
          setStep(currentStep + 1);
        });
      });
    },
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setQueryEmail(query.get('email'));

    if (query.get('provider') === 'google') {
      dispatch(showModal());
      loginUserFromRedirect().then(({ fromGoogle }) => {
        const userInfo = fromGoogle.oauth.userInfo;
        formik.setValues({
          email: userInfo.email,
          firstname: userInfo.givenName,
          lastname: userInfo.familyName,
          username: userInfo.email.split('@')[0],
          recoveringEmail: userInfo.email,
          acceptTerms: true,
        });
        registerUserStepOne({
          email: userInfo.email.toLowerCase(),
          firstname: userInfo.givenName,
          lastname: userInfo.familyName,
          username: userInfo.email.split('@')[0],
          recoveringEmail: userInfo.email,
          acceptTerms: true,
        }).then(() => {
          setStep(currentStep + 1);
        });
      });
    }
  }, []);

  const toggleCheckbox = (e) => {
    formik.setFieldValue(`${e.target.name}`, !e.target.name);
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        <img src={logo} alt="" />
        Create an account
      </h2>
      <StepProgress currentStep={currentStep} steps={3} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <Input
            name="email"
            label="Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.email && formik.errors.email}
            placeholder="Enter your email"
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="firstname"
            label="First name"
            onChange={formik.handleChange}
            value={formik.values.firstname}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.firstname && formik.errors.firstname}
            placeholder="Enter your first name"
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="lastname"
            label="Last name"
            onChange={formik.handleChange}
            value={formik.values.lastname}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.lastname && formik.errors.lastname}
            placeholder="Enter your last name"
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="username"
            label="Username"
            onChange={formik.handleChange}
            value={formik.values.username}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.username && formik.errors.username}
            placeholder="Enter your username"
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="recoveringEmail"
            label="Recovering email"
            onChange={formik.handleChange}
            value={formik.values.recoveringEmail}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.recoveringEmail && formik.errors.recoveringEmail}
            placeholder="Enter your recovering email"
          />
        </div>

        <div className={styles.formItem}>
          <Checkbox
            name="acceptTerms"
            onChange={formik.handleChange}
            onClick={toggleCheckbox}
            isInvalid={formik.touched.acceptTerms && formik.errors.acceptTerms}
            checked={formik.values.acceptTerms}
          >
            Accept the{' '}
            <a className={styles.termsLink} href="https://www.cryptoperk.co/user-agreement">
              Terms & Conditions
            </a>
          </Checkbox>
        </div>
        <Button type="submit">Continue</Button>
      </form>

      <div className={styles.googleButton}>
        <Button theme="bordered" onClick={() => registerWithGoogle()}>
          <img src={googleLogo} alt="google logo" />
          Sign up with Google
        </Button>
      </div>
      <p className={styles.haveAccount}>
        Already have an account?{' '}
        <Button theme="text" link="/auth/login">
          Log in
        </Button>
      </p>
      <Modal>
        <Spinner />
      </Modal>
    </div>
  );
}

StepOne.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default StepOne;
