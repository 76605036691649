import React, { useState } from 'react';

import Like from '../Like/Like';
import LikeInfo from '../LikeInfo/LikeInfo';
import CommentInfo from '../CommentInfo/CommentInfo';
import ReplyList from '../ReplyList/ReplyList';
import CreateComment from '../CreateComment/CreateComment';
import AvatarUser from '../../AvatarUser/AvatarUser';

import differentTime from '../../../helpers/differentTime.helpers';

import comment from '../../../assets/images/comment.svg';

import styles from './Comment.module.scss';

const Comment = ({
  id,
  commentId,
  length,
  createdAt,
  user,
  postId,
  text,
  count,
  likedByCurrentUser,
}) => {
  const {
    avatar,
    //  id,
    firstname,
    lastname,
  } = user;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.comment__wrapper}>
      <div className={styles.comment__content}>
        <AvatarUser avatar={avatar} />
        <div className={styles.comment__info}>
          <span className={styles.comment__info__name}>{`${firstname} ${lastname}`}</span>
          <p className={styles.comment__info__text}>{text}</p>
          {createdAt && (
            <span className={styles.comment__info__time}>{differentTime(createdAt)}</span>
          )}
        </div>
      </div>
      <div className={styles.comment__reacted}>
        <div className={styles.comment__reacted__left}>
          <Like likedByCurrentUser={likedByCurrentUser} id={commentId} type="comments" />
          <span className={styles.comment__reply} onClick={() => setIsOpen(!isOpen)}>
            <img src={comment} />
            <span>Reply</span>
          </span>
        </div>
        <div className={styles.comment__reacted__rigth}>
          <LikeInfo length={count.usersLiked} />
          <CommentInfo length={count.replies} type="reply" />
        </div>
      </div>
      {isOpen && (
        <CreateComment commentId={id} withPatent id={postId} userId={user?.id} isReply={false} />
      )}
      <ReplyList length={count.replies} id={commentId} />
    </div>
  );
};

export default Comment;
