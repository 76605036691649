import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ClientsList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import {
  clearClients,
  setAllClientsSelected,
  setClients,
} from '../../features/clients/clients.slice';
import { getUsers } from '../../api/users';
import ClientsListItem from './ClientsListItem';

function ClientsList({ filter, allChecked, setAllChecked, setUserInfo, setUserInfoModal }) {
  const users = useSelector((state) => state.clients.users.list);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearClients());
  }, [dispatch]);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    getUsers,
    setClients,
    users.length,
    `${filter.input && 'search='}${filter.input}${filter.role && '&role='}${filter.role}${
      filter.company && '&company='
    }${filter.company}` || '',
    clearClients
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const selectAllUsers = () => {
    dispatch(setAllClientsSelected(!allChecked));
    setAllChecked(!allChecked);
  };

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox checked={allChecked} onChange={selectAllUsers} />
        </div>
        <div className={styles.col}>Name</div>
        <div className={styles.col}>Position</div>
        <div className={styles.col}>Credit Card</div>
        <div className={styles.col}>Company</div>
        <div className={styles.col}>Company Size</div>
        <div className={styles.col}>Wallet Address</div>
        <div className={styles.col}>Country</div>
        <div className={styles.col}></div>
      </li>
      {users.map((user, index) => {
        if (users.length === index + 1) {
          return (
            <ClientsListItem
              ref={lastElementRef}
              setUserInfoModal={setUserInfoModal}
              setUserInfo={setUserInfo}
              user={user}
              key={index}
            />
          );
        }
        return (
          <ClientsListItem
            user={user}
            setUserInfoModal={setUserInfoModal}
            setUserInfo={setUserInfo}
            key={index}
          />
        );
      })}
    </ul>
  );
}

export default ClientsList;
