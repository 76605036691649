import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const replySlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setReply(state, action) {
      state.list = action.payload;
    },
    clearPosts(state) {
      state.list = [];
    },

    updatePosts(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },

    updateReplyItems(state, action) {
      state.list = action.payload;
    },
  },
});

export const { setReply, clearPosts, updatePosts, updateReplyItems } = replySlice.actions;
export default replySlice.reducer;
