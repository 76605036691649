import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './StepThree.module.scss';
import logo from '../../../../assets/images/logo.svg';
import Input from '../../../../UI/Input/Input';
import Button from '../../../../UI/Button/Button';
import { stepThreeSchema as validationSchema } from './StepThree.schema';
import StepProgress from '../../../StepProgress/StepProgress';
import Select from '../../../../UI/Select/Select';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';
import DatePicker from '../../../../UI/DatePicker/DatePicker';
import { getUser, registerUserStepThree } from '../../../../api/users';
import { getIndustriesFilter } from '../../../../api/companies';
import { toSelectOptions } from '../../../../helpers/select.helpers';

function StepThree({ setStep, currentStep }) {
  const [industries, setIndustries] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateOfBirth: null,
      startOfWork: null,
      industryId: userData?.company?.industry.id || '',
      size: userData?.company?.size || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (userData?.role !== 'Employee') {
        registerUserStepThree({
          dateOfBirth: values.dateOfBirth,
          startOfWork: values.startOfWork,
          companyDto: {
            industryId: values.industryId,
            size: Number(values.size),
          },
        }).then(() => navigate('/'));
      } else {
        registerUserStepThree({
          dateOfBirth: values.dateOfBirth,
          startOfWork: values.startOfWork,
        }).then(() => navigate('/'));
      }
    },
  });

  useEffect(() => {
    getIndustriesFilter().then((res) => {
      setIndustries(toSelectOptions(res));
    });
    getUser().then((res) => {
      setUserData(res);
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.skipLink}>
        <Button theme="text" link="/auth/login">
          Skip that step and sign up <ArrowRight />
        </Button>
      </div>

      <div className={styles.header}>
        <img src={logo} alt="" />
        <h2>Create an account</h2>
        <p>Enter info about yourself and your company</p>
      </div>
      <StepProgress currentStep={currentStep} steps={3} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <DatePicker
            name="dateOfBirth"
            label="Date of Birth"
            startDate={formik.values.dateOfBirth}
            onChange={formik.setFieldValue}
          />
        </div>
        <div className={styles.formItem}>
          <DatePicker
            name="startOfWork"
            label="Work Anniversary"
            startDate={formik.values.startOfWork}
            onChange={formik.setFieldValue}
          />
        </div>
        <div className={styles.formItem}>
          <Select
            name="industry"
            isDisabled={userData?.role === 'Employee'}
            value={industries.find((element) => element.value === formik.values.industryId)}
            onChange={(option) => {
              formik.setFieldValue('industryId', option.value);
            }}
            label="Industry"
            options={industries}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="size"
            label="Company Size"
            disabled={userData?.role === 'Employee'}
            onChange={formik.handleChange}
            value={formik.values.size}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.size && formik.errors.size}
            placeholder="Enter company size (amount of employees)"
          />
        </div>
        <div className={styles.buttons}>
          <Button theme="bordered" onClick={() => setStep(currentStep - 1)}>
            Back
          </Button>
          <Button type="submit">Continue</Button>
        </div>
      </form>

      <p className={styles.haveAccount}>
        Already have an account?{' '}
        <Button theme="text" link="/">
          Log in
        </Button>
      </p>
    </div>
  );
}

StepThree.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default StepThree;
