import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Main from './pages/Main';
import Auth from './pages/Auth';
import { magic } from './api/constants';
import { setLoggedIn, setLoggedOut } from './features/auth/auth-slice';

function App() {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    magic.user
      .isLoggedIn()
      .then((res) => (res ? dispatch(setLoggedIn()) : dispatch(setLoggedOut())));
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/*" element={loggedIn ? <Main /> : <Navigate to="/auth/login" replace />} />
      <Route path="/auth/*" element={<Auth />} />
    </Routes>
  );
}

export default App;
