import { API_BASE } from './constants';

const getRequest = async (url) => {
  const token = localStorage.getItem('user');
  const response = await fetch(`${API_BASE}${url}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const { status } = response;
  if (status === 400) {
    return 'We can`t remove this';
  }

  if (status === 200) {
    return 'We remove this';
  }
  return status;
};

export default getRequest;
