import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { debounce } from 'lodash';
import useClipboard from 'react-use-clipboard';
import ReactTooltip from 'react-tooltip';
import styles from '../scss/pages/Clients.module.scss';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import { ReactComponent as Download } from '../assets/icons/download-dark.svg';
import { hideModal, showModal } from '../features/modal/modal-slice';
import { ReactComponent as Plus } from '../assets/icons/plus-icon.svg';
import { ReactComponent as CompanyBag } from '../assets/icons/companyBag.svg';
import { ReactComponent as Wallet } from '../assets/icons/wallet.svg';
import CreatableSelect from '../UI/Select/CreatableSelect';
import CsvUploader from '../components/CsvUploader/CsvUploader';
import { inviteCompanies, postExportClientsCsv } from '../api/users';
import Modal from '../components/Modal/Modal';
import ClientsList from '../components/ClientsList/ClientsList';
import Spinner from '../UI/Spinner/Spinner';
import CompaniesList from '../components/ClientsList/CompaniesList';
import { ReactComponent as Checkmark } from '../assets/icons/step-checkmark.svg';
import { ReactComponent as CopyIcon } from '../assets/icons/copy-icon.svg';
import { ReactComponent as FilterIcon } from '../assets/icons/filter-icon.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';
import { getCompaniesNames, getIndustriesFilter, postExportCompaniesCSV } from '../api/companies';
import { getClickableLink } from '../helpers/common.helpers';
import useOnClickOutside from '../hooks/useOnClickOutside';

function Clients() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const { list: users } = useSelector((state) => state.clients.users);
  const { list: companies } = useSelector((state) => state.clients.companies);

  const filterInitial = {
    input: '',
    role: '',
    company: '',
    employees: '',
    industry: '',
  };

  const [filter, setFilter] = useState(filterInitial);
  const [csv, setCsv] = useState(null);
  const [exportCsvCompanies, setExportCsvCompanies] = useState({ ids: [] });
  const [exportCsvUsers, setExportCsvUsers] = useState({ ids: [] });
  const [modal, setModal] = useState('invite');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('clients');
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    companies: [],
    industries: [],
    roles: ['Admin', 'Employee'],
    employees: [100, 500, 1000],
  });

  const [isCopied, setCopied] = useClipboard(userInfo?.addresses[0], { successDuration: 2000 });

  const navigate = useNavigate();

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilter(false));

  const createOption = (label) => ({
    label,
    value: label,
  });

  const openUserInfoModal = () => {
    setModal('user');
  };
  const openCompanyInfoModal = () => {
    setModal('company');
  };

  const handleKeyDown = (event) => {
    if (!emailInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setEmails((prev) => [...prev, createOption(emailInputValue)]);
        setEmailInputValue('');
        event.preventDefault();
        break;
      default:
    }
  };

  const sendInvites = () => {
    if (user.role === 'SuperAdmin') {
      inviteCompanies({
        emails: emails.map((email) => email.value),
        csv,
      }).then(() => {
        dispatch(hideModal(true));
        navigate(0);
      });
    }
  };

  const handleExportCsv = () => {
    if (activeTab === 'clients') {
      postExportClientsCsv(exportCsvUsers).then((res) => {
        const blob = new Blob([res]);
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.setAttribute('href', href);
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);

        link.click();
      });
    } else if (activeTab === 'companies') {
      postExportCompaniesCSV(exportCsvCompanies).then((res) => {
        const blob = new Blob([res]);
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.setAttribute('href', href);
        link.setAttribute('download', 'Companies.csv');
        document.body.appendChild(link);

        link.click();
      });
    }
  };

  useEffect(() => {
    setExportCsvUsers({ ids: users.filter((user) => user.selected).map((user) => user.id) });
  }, [users]);

  useEffect(() => {
    setExportCsvCompanies({
      ids: companies.filter((company) => company.selected).map((company) => company.id),
    });
  }, [companies]);

  useEffect(() => {
    getIndustriesFilter().then((res) => {
      setFilterOptions((prev) => {
        return { ...prev, industries: res };
      });
    });
    getCompaniesNames().then((res) => {
      setFilterOptions((prev) => {
        return {
          ...prev,
          companies: res.filter((company) => {
            return !!company.name;
          }),
        };
      });
    });
  }, []);

  const debouncedFilter = useCallback(
    debounce(
      (filterValue) =>
        setFilter((prev) => {
          return { ...prev, input: filterValue };
        }),
      300
    ),
    []
  );

  const clearAll = () => {
    setFilter((prev) => {
      return { ...filterInitial, input: prev.input };
    });
  };

  return (
    <div className={styles.page}>
      <h2>Clients</h2>

      <div className={styles.controls}>
        <div className={styles.filters}>
          <div className={styles.searchInput}>
            <Input
              onChange={(e) => debouncedFilter(e.target.value)}
              placeholder="Search for user name"
            />
          </div>

          <div
            ref={filterRef}
            className={cn(styles.filterWrapper, { [styles.filterWrapper_active]: showFilter })}
          >
            <Button onClick={() => setShowFilter(!showFilter)} theme="text-dark">
              <FilterIcon /> Filter <ArrowDown />
            </Button>

            {showFilter && activeTab === 'clients' && (
              <div className={styles.filter}>
                <div className={styles.clearButton}>
                  <Button onClick={clearAll} theme="bordered">
                    Clear all
                  </Button>
                </div>
                <h3>Filters</h3>
                <div className={styles.columnsWrapper}>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}>Position</div>
                    <div className={styles.filterItems}>
                      {filterOptions.roles.map((role, index) => (
                        <div
                          onClick={() => {
                            if (filter.role.includes(role)) {
                              setFilter((prev) => {
                                return { ...prev, role: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, role };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.role.includes(role),
                          })}
                          key={index}
                        >
                          {role}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}>Company</div>
                    <div className={styles.filterItems}>
                      {filterOptions.companies.map((company) => (
                        <div
                          onClick={() => {
                            if (filter.company.includes(company.id)) {
                              setFilter((prev) => {
                                return { ...prev, company: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, company: company.id };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.company.includes(company.id),
                          })}
                          key={company.id}
                        >
                          {company.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showFilter && activeTab === 'companies' && (
              <div className={styles.filter}>
                <div className={styles.clearButton}>
                  <Button onClick={clearAll} theme="bordered">
                    Clear all
                  </Button>
                </div>
                <h3>Filters</h3>
                <div className={styles.columnsWrapper}>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}># Employees</div>
                    <div className={styles.filterItems}>
                      {filterOptions.employees.map((number, index) => (
                        <div
                          onClick={() => {
                            if (filter.employees === number) {
                              setFilter((prev) => {
                                return { ...prev, employees: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, employees: number };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.employees === number,
                          })}
                          key={index}
                        >
                          More {number}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}>Industry</div>
                    <div className={styles.filterItems}>
                      {filterOptions.industries.map((industry, index) => (
                        <div
                          onClick={() => {
                            if (filter.industry === industry.value) {
                              setFilter((prev) => {
                                return { ...prev, industry: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, industry: industry.value };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.industry === industry.value,
                          })}
                          key={index}
                        >
                          {industry.value}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={handleExportCsv} theme="bordered">
            <Download /> Export CSV
          </Button>
          <Button
            onClick={() => {
              setModal('invite');
              dispatch(showModal());
            }}
          >
            <Plus /> Invite New Users
          </Button>
        </div>
      </div>
      <div className={styles.tabs}>
        <div
          className={cn(styles.tab, { [styles.tab_active]: activeTab === 'clients' })}
          onClick={() => {
            setActiveTab('clients');
            setFilter((prev) => {
              return { ...prev, employees: '', industry: '' };
            });
          }}
        >
          Clients
        </div>
        <div
          className={cn(styles.tab, { [styles.tab_active]: activeTab === 'companies' })}
          onClick={() => {
            setActiveTab('companies');
            setFilter((prev) => {
              return { ...prev, role: '', company: '' };
            });
          }}
        >
          Companies
        </div>
      </div>
      <div className={styles.content}>
        {activeTab === 'clients' && (
          <ClientsList
            filter={filter}
            allChecked={allChecked}
            setAllChecked={setAllChecked}
            setUserInfoModal={openUserInfoModal}
            setUserInfo={setUserInfo}
          />
        )}
        {activeTab === 'companies' && (
          <CompaniesList
            filter={filter}
            allChecked={allChecked}
            setAllChecked={setAllChecked}
            setCompanyInfo={setCompanyInfo}
            setCompanyInfoModal={openCompanyInfoModal}
          />
        )}
      </div>
      <Modal aside={modal === 'user' || modal === 'company'}>
        {modal === 'invite' && (
          <>
            <div className={styles.modalHeaderIcon}>
              <div className={styles.iconWrapper}>
                <CompanyBag />
              </div>
            </div>
            <h3 className={styles.modalHeader}>Invite new companies</h3>
            <p className={styles.modalDescription}>
              Invite admins to CryptoPerk by entering their email addresses or uploading .CSV file
            </p>
            <div className={styles.multiInput}>
              <CreatableSelect
                multi
                value={emails}
                inputValue={emailInputValue}
                placeholder="Type email here and press enter"
                onChange={(newValue) => setEmails(newValue)}
                onInputChange={(newValue) => setEmailInputValue(newValue)}
                onKeyDown={handleKeyDown}
                menuIsOpen={false}
                isClearable
                components={{ DropdownIndicator: null }}
              />
            </div>
            <p className={styles.modalDescription}>or by uploading a CSV file</p>
            <div className={styles.csvUploader}>
              <CsvUploader setCsv={setCsv} />
            </div>
            <div className={styles.modalButtons}>
              <Button theme="bordered" onClick={() => dispatch(hideModal())}>
                Cancel
              </Button>
              <Button onClick={sendInvites}>Send invites</Button>
            </div>
          </>
        )}
        {modal === 'user' &&
          (userInfo ? (
            <>
              <div className={styles.infoBlock}>
                <div className={styles.header}>
                  General info{' '}
                  <div
                    className={cn(styles.status, {
                      [styles.status_enrolled]: userInfo.isEnrolled,
                    })}
                  >
                    <span>{userInfo.isEnrolled ? 'Enrolled' : 'Not Enrolled'}</span>
                  </div>
                </div>
                <div className={styles.general}>
                  <div
                    className={styles.avatar}
                    style={{
                      background:
                        userInfo.avatar && `url(${userInfo.avatar}) no-repeat center / cover`,
                    }}
                  ></div>
                  <div className={styles.generalInfo}>
                    <div className={styles.name}>
                      {userInfo?.firstname} {userInfo?.lastname}
                    </div>
                    <div className={styles.role}>{userInfo.role}</div>
                  </div>
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.header}>Contact Info</div>
                <div className={styles.block}>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Email address</div>
                    <div className={styles.value}>{userInfo?.email}</div>
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Personal Wallet</div>
                    <div className={cn(styles.value, styles.wallet)}>
                      <Wallet />
                      <span className={styles.walletNumber}>
                        {userInfo.addresses[0] &&
                          `${userInfo.addresses[0].substring(
                            0,
                            4
                          )}...${userInfo.addresses[0].substring(
                            userInfo.addresses[0].length - 4
                          )}`}
                      </span>

                      {isCopied
                        ? userInfo.addresses[0] && <Checkmark />
                        : userInfo.addresses[0] && (
                            <CopyIcon
                              data-tip="Wallet address copied"
                              data-event="mouseup"
                              data-for="success"
                              data-delay-hide="2000"
                              data-event-off="mouseleave"
                              onClick={() => {
                                setCopied();
                              }}
                            />
                          )}
                    </div>
                    <ReactTooltip
                      className="customTooltip"
                      id="success"
                      effect="solid"
                      place="bottom"
                    />
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Credit Card</div>
                    <div className={styles.value}>{userInfo?.cardExpiringDate}</div>
                  </div>
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.header}>Company Details</div>
                <div className={styles.block}>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Company name</div>
                    <div className={styles.value}>{userInfo?.company?.name}</div>
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Company logo</div>
                    <div
                      className={cn(styles.value, styles.companyLogo)}
                      style={{
                        background:
                          userInfo.company?.logo &&
                          `url(${userInfo.company?.logo}) no-repeat center/contain`,
                      }}
                    ></div>
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Company website</div>
                    <div className={styles.value}>
                      {userInfo.company?.website && (
                        <a
                          href={getClickableLink(userInfo.company.website)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {userInfo.company.website}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Company size</div>
                    <div className={styles.value}>{userInfo.company?.size}</div>
                  </div>
                  <div className={styles.blockItem}>
                    <div className={styles.title}>Country</div>
                    <div className={styles.value}>{userInfo.company?.country}</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.modalSpinner}>
              <Spinner />
            </div>
          ))}
        {modal === 'company' &&
          (companyInfo ? (
            <div className={styles.infoBlock}>
              <div className={styles.header}>Company Details</div>
              <div className={styles.block}>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Company name</div>
                  <div className={styles.value}>{companyInfo?.name}</div>
                </div>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Company logo</div>
                  <div
                    className={cn(styles.value, styles.companyLogo)}
                    style={{
                      background:
                        companyInfo?.logo && `url(${companyInfo?.logo}) no-repeat center/contain`,
                    }}
                  ></div>
                </div>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Company website</div>
                  <div className={styles.value}>
                    <a
                      href={getClickableLink(companyInfo?.website)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {companyInfo?.website}
                    </a>
                  </div>
                </div>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Industry</div>
                  <div className={styles.value}>{companyInfo.industry.value}</div>
                </div>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Billing Admin</div>
                  <div className={cn(styles.value, styles.billingAdmin)}>
                    <div
                      className={styles.billingAdminImage}
                      style={{
                        background:
                          companyInfo?.billingAdmin?.avatar &&
                          `url(${companyInfo?.billingAdmin?.avatar}) no-repeat center/cover`,
                      }}
                      role="image"
                    />
                    <div className={styles.name}>
                      {companyInfo?.billingAdmin?.firstname} {companyInfo?.billingAdmin?.lastname}
                      <span>{companyInfo?.billingAdmin?.email}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.blockItem}>
                  <div className={styles.title}>Country</div>
                  <div className={styles.value}>{companyInfo?.country}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.modalSpinner}>
              <Spinner />
            </div>
          ))}
      </Modal>
    </div>
  );
}

export default Clients;
