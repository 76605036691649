import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import styles from './TransactionsKudosTable.module.scss';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import useInfiniteScroll from '../../../hooks/useInfiniteScrollAnalytics';
import {
  clearTransactionsKudos,
  setTransactionsKudos,
  setAllTransactionsKudosSelected,
  setFilterSortBy,
} from '../../../features/transactions/transactions.slice';
import TransactionsKudosTableItem from './TransactionsKudosTableItem';
import { postKudos } from '../../../api/transactions';

import splitArrow from '../../../assets/images/splitArrow.svg';

const TransactionsTable = ({ filter, allChecked, setAllChecked, type, data, filterData }) => {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    return () => dispatch(clearTransactionsKudos());
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  useEffect(() => {
    setPageNumber(1);
  }, [filterData]);

  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    postKudos,
    setTransactionsKudos,
    data.length,
    filterData,
    type,
    clearTransactionsKudos
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const selectAllUsers = () => {
    dispatch(setAllTransactionsKudosSelected(!allChecked));
    setAllChecked(!allChecked);
  };

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox checked={allChecked} onChange={selectAllUsers} />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('DateTime'));
            setPageNumber(1);
          }}
        >
          Date & Time
          <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div className={styles.col}>Sender</div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Recipient'));
            setPageNumber(1);
          }}
        >
          Recipient
          <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Amount'));
            setPageNumber(1);
          }}
        >
          Amount <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Status'));
            setPageNumber(1);
          }}
        >
          Status <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div className={styles.col}></div>
      </li>
      {data.map((user, index) => {
        if (data.length === index + 1) {
          return <TransactionsKudosTableItem user={user} key={index} ref={lastElementRef} />;
        }
        return <TransactionsKudosTableItem user={user} key={index} />;
      })}
    </ul>
  );
};

export default TransactionsTable;
