import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  filterHashtag: [],
};

const hashtagsSlice = createSlice({
  name: 'hashtags',
  initialState,
  reducers: {
    setHashtags(state, action) {
      state.list = action.payload;
    },
    setFilterHashtags(state, action) {
      if (state.filterHashtag && state.filterHashtag.length > 0) {
        const { payload } = action;
        const findItem = state.filterHashtag.find((item) => item === payload);
        if (findItem) {
          const removeItem = state.filterHashtag.filter((item) => item !== payload);
          state.filterHashtag = removeItem;
        } else {
          // if (state.filterHashtag.length >= 3) {
          //   return;
          // }
          state.filterHashtag = [...state.filterHashtag, payload];
        }
      } else {
        state.filterHashtag = [action.payload];
      }
    },
    clearHashtagsFilter(state) {
      state.filterHashtag = [];
    },
    clearHashtags(state) {
      state.list = [];
    },
  },
});

export const { setHashtags, setFilterHashtags, clearHashtags, clearHashtagsFilter } =
  hashtagsSlice.actions;
export default hashtagsSlice.reducer;
