import React from 'react';
import { format } from 'date-fns';

import AvatarUser from '../../AvatarUser/AvatarUser';
import ButtonAddon from '../../ButtonAddon/ButtonAddon';

import differentTime from '../../../helpers/differentTime.helpers';

import styles from './Anniversaries.module.scss';

const Anniversaries = ({ id, avatar, lastname, firstname, startOfWork, addedOnByCurrentUser }) => {
  const time = format(new Date(startOfWork), 'MMMM dd');
  const anniversariesYear = differentTime(startOfWork, true);

  return (
    <div className={styles.anniversaries__wrapper}>
      <div className={styles.anniversaries__content}>
        <AvatarUser avatar={avatar} backgroundColor="grey" />
        <div className={styles.anniversaries__info}>
          <a href="#" className={styles.anniversaries__detail}>{`${firstname} ${lastname}`}</a>
          <span className={styles.anniversaries__time}>
            {time} - {anniversariesYear}
          </span>
        </div>
      </div>
      <div>
        <ButtonAddon
          addedOnByCurrentUser={addedOnByCurrentUser}
          id={id}
          type="WorkAnniversary"
          // eslint-disable-next-line
          tooltip={true}
        />
      </div>
    </div>
  );
};

export default Anniversaries;
