import { createSlice } from '@reduxjs/toolkit';

const user = localStorage.getItem('user');

const initialState = user
  ? {
      loggedIn: true,
    }
  : {
      loggedIn: false,
    };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
    },
  },
});

export const { setLoggedIn, setLoggedOut } = authSlice.actions;
export default authSlice.reducer;
