import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../scss/pages/Main.module.scss';
import AsideNav from '../components/AsideNav/AsideNav';
import Home from './Home';
import Profile from './Profile';
import Analytics from './Analytics';
import { getUser } from '../api/users';
import { setUserInfo } from '../features/user/user.slice';
import Users from './Users';
import { getCompanyInfo } from '../api/companies';
import { setCompanyInfo } from '../features/company/company.slice';
import Wallets from './Wallets';
import RewardSettings from './RewardSettings';
import Billing from './Billing';
import Clients from './Clients';
import Requests from './Requests';
import Transactions from './Transactions';
import BillingPlans from '../components/BillingPlans/BillingPlans';

function Main() {
  const role = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  useEffect(() => {
    getUser().then((user) => {
      dispatch(setUserInfo(user));
      if (user.role === 'Admin') {
        getCompanyInfo(user.company.id).then((info) => {
          dispatch(setCompanyInfo(info));
        });
      } else if (user.role === 'Employee') {
        getCompanyInfo(user.company.id).then((info) => {
          dispatch(setCompanyInfo(info));
        });
      }
    });
  }, [dispatch]);

  return (
    <div className={styles.main}>
      <AsideNav />
      <div className={styles.page}>
        <Routes>
          <Route
            path="/"
            element={role === 'SuperAdmin' ? <Navigate to="/profile" replace /> : <Home />}
          />
          <Route path="/profile/*" element={<Profile />} />
          <Route
            path="/analytics"
            element={
              role === 'Admin' || role === 'Employee' ? <Analytics /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/rewards"
            element={role === 'Admin' ? <RewardSettings /> : <Navigate to="/" replace />}
          />
          <Route path="/billing/*">
            <Route index element={role === 'Admin' ? <Billing /> : <Navigate to="/" replace />} />
            <Route path="plans" element={<BillingPlans />} />
          </Route>
          <Route
            path="/users"
            element={role === 'Admin' ? <Users /> : <Navigate to="/" replace />}
          />
          <Route
            path="/wallets"
            element={role === 'Employee' ? <Wallets /> : <Navigate to="/" replace />}
          />
          <Route
            path="/clients"
            element={role === 'SuperAdmin' ? <Clients /> : <Navigate to="/profile" replace />}
          />
          <Route
            path="/requests"
            element={role === 'SuperAdmin' ? <Requests /> : <Navigate to="/profile" replace />}
          />
          <Route
            path="/transactions"
            element={role === 'SuperAdmin' ? <Transactions /> : <Navigate to="/profile" replace />}
          />
        </Routes>
      </div>

      <a
        className={styles.feedbackButton}
        href="mailto:hello@cryptoperk.co?subject=I have some CryptoPerk feedback!"
      >
        Have feedback?
      </a>
    </div>
  );
}

export default Main;
