import React from 'react';
import styles from './Radio.module.scss';
import { ReactComponent as Error } from '../../assets/icons/error-icon.svg';

function Radio({ isInvalid, children, onClick, ...rest }) {
  return (
    <>
      <label className={styles.radio}>
        <input type="radio" onClick={onClick} {...rest} />
        <span className={styles.radioBtn} />
        {children && children}
      </label>
      {isInvalid && (
        <div className={styles.error}>
          <Error />
          {isInvalid}
        </div>
      )}
    </>
  );
}

export default Radio;
