import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './AsideNav.module.scss';

function AsideNavItem({ collapsed, link, title, icon }) {
  return (
    <div className={styles.linkWrapper}>
      <NavLink
        to={link}
        className={({ isActive }) =>
          cn(
            styles.navlink,
            { [styles.navlink_active]: isActive },
            { [styles.navlink_collapsed]: collapsed }
          )
        }
      >
        <div className={cn(styles.iconWrapper, { [styles.iconWrapper_collapsed]: collapsed })}>
          {icon}
        </div>
        {!collapsed && title}
      </NavLink>
    </div>
  );
}

AsideNavItem.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default AsideNavItem;
