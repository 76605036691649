import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBitcoin } from '../../features/bitcoin/bitcoin.slice';

import { ReactComponent as LineDown } from '../../assets/images/lineUp.svg';
import exchange from '../../assets/images/exchange.svg';
import styles from './PortfolioBalance.module.scss';

const PortfolioBalance = () => {
  const dispatch = useDispatch();
  const coins = useSelector((state) => state.bitcoin.data);
  const url = 'https://api.coingecko.com/api/v3/coins/bitcoin?vs_currency=usd';

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => dispatch(setBitcoin(data)))
      // eslint-disable-next-line
      .catch((error) => console.log('Error:', error));
    // eslint-disable-next-line
  }, [url]);

  const balancePercentage =
    coins && coins.market_data
      ? (568.02 / coins.market_data.current_price.usd).toFixed(3)
      : '0,024';
  const btcRange = coins?.market_data?.price_change_percentage_7d_in_currency?.usd.toFixed(2) > 0;
  const num = coins?.market_data?.current_price?.usd;
  const commas = num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
  return (
    <div className={styles.balance__container}>
      <h1 className={styles.balance__title}>Your portfolio balance</h1>
      <p className={styles.balance__count}>$568.02</p>
      <p className={styles.balance__exchange}>
        <img src={exchange} alt="exchange" />
        <span>{balancePercentage && balancePercentage} BTC</span>
      </p>
      <div className={styles.balance__info}>
        <div className={styles.balance__bitcoin}>
          <span className={styles.balance__subtitle}>Price per Bitcoin</span>
          <span className={styles.balance__price}>${coins && commas}</span>
        </div>
        <div className={styles.balance__chart}>
          <span className={styles.balance__subtitle}>Last 7 Days</span>
          <p>
            <span
              className={btcRange ? styles.balance__percentage : styles.balance__percentage__down}
            >
              <LineDown />
              <span className={styles.balance__percentage__value}>
                {coins &&
                  coins?.market_data?.price_change_percentage_7d_in_currency?.usd.toFixed(2)}{' '}
                %
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioBalance;
