export const formatCompanyData = (data) => {
  return {
    ...data,
    hashtags: data.hashtags.map((hastag) => hastag.value),
    size: Number(data.size),
  };
};

export const formatCompanyUsers = (data) => {
  return data.map((user) => {
    return {
      ...user,
      fullName: `${user.firstname} ${user.lastname}`,
    };
  });
};
