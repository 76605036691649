import React from 'react';

import userHomeIcon from '../../../assets/images/userHomeIcon.svg';

import styles from './PostReacted.module.scss';

const PostReacted = ({ firstUsersReacted }) => {
  const allAvatars = () => {
    if (firstUsersReacted && firstUsersReacted.length > 0) {
      const sliceArray = firstUsersReacted.slice(0, 4);
      return sliceArray.map((item, index) => {
        const marginLeft = index > 0 ? '-5px' : '20px';
        return (
          <img
            className={styles.reacted__icon}
            style={{ marginLeft: marginLeft }}
            key={item.reactedAt}
            src={item.avatar === null ? userHomeIcon : item.avatar}
            alt="avatar"
          />
        );
      });
    }
    return null;
  };

  const name = () => {
    return `${firstUsersReacted[0].firstname} ${firstUsersReacted[0].lastname}`;
  };

  const showInfo = () => {
    const other = firstUsersReacted.length === 2 ? 'other' : 'others';
    return `and ${firstUsersReacted.length - 1} ${other}`;
  };

  return (
    <div className={styles.reacted__wrapper}>
      {allAvatars()}
      <span className={styles.reacted__content}>{name()}</span>
      <span className={styles.reacted__content}>{firstUsersReacted.length > 1 && showInfo()}</span>
    </div>
  );
};

export default PostReacted;
