import React from 'react';
import { ReactComponent as Home } from '../assets/icons/home.svg';
import { ReactComponent as Analytics } from '../assets/icons/analytics.svg';
import { ReactComponent as Users } from '../assets/icons/users.svg';
import { ReactComponent as ThumbUp } from '../assets/icons/thumbUp.svg';
import { ReactComponent as Billing } from '../assets/icons/menuBilling.svg';
import { ReactComponent as Wallet } from '../assets/icons/wallet.svg';
import { ReactComponent as Case } from '../assets/icons/menuCase.svg';
import { ReactComponent as Requests } from '../assets/icons/menuRequests.svg';

export const menuLinks = {
  Admin: [
    {
      title: 'Home',
      link: '/',
      icon: <Home />,
    },
    {
      title: 'Reward settings',
      link: '/rewards',
      icon: <ThumbUp />,
    },
    {
      title: 'Users',
      link: '/users',
      icon: <Users />,
    },
    {
      title: 'Analytics',
      link: '/analytics',
      icon: <Analytics />,
    },
    {
      title: 'Billing',
      link: '/billing',
      icon: <Billing />,
    },
  ],
  Employee: [
    {
      title: 'Home',
      link: '/',
      icon: <Home />,
    },
    {
      title: 'Analytics',
      link: '/analytics',
      icon: <Analytics />,
    },
    {
      title: 'Wallets',
      link: '/wallets',
      icon: <Wallet />,
    },
  ],
  SuperAdmin: [
    {
      title: 'Clients',
      link: '/clients',
      icon: <Case />,
    },
    {
      title: 'Requests',
      link: '/requests',
      icon: <Requests />,
    },
    {
      title: 'Transactions',
      link: '/transactions',
      icon: <Billing />,
    },
  ],
};
