import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import styles from './CompanyDetails.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Checkbox from '../../UI/Checkbox/Checkbox';
import ImageUploader from '../ImageUploader/ImageUploader';
import ColorPicker from '../../UI/ColorPicker/ColorPicker';
import CreatableSelect from '../../UI/Select/CreatableSelect';
import { getCompanyHashtags, postNewCompanyHashtag, putCompanyInfo } from '../../api/companies';
import { toSelectOptions } from '../../helpers/select.helpers';
import { formatCompanyData } from '../../helpers/company.helpers';
import useOnClickOutside from '../../hooks/useOnClickOutside';

function CompanyDetails() {
  const companyInfo = useSelector((state) => state.company.info);

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [color, setColor] = useState({ hex: companyInfo.brandColor || '#0066FB' });
  const [hashtags, setHashtags] = useState([]);

  const colorPickerModalRef = useRef();
  useOnClickOutside(colorPickerModalRef, () => setColorPickerOpen(false));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: companyInfo.name || '',
      logo: companyInfo.logo,
      brandImage: companyInfo.brandImage,
      hashtags:
        companyInfo.hashtags.map((hashtag) => {
          return { label: hashtag.value, value: hashtag.id };
        }) || [],
      website: companyInfo.website || '',
      size: companyInfo.size || '',
      brandColor: companyInfo.brandColor || '#0066FB',
      linkedin: companyInfo.linkedin || '',
      facebook: companyInfo.facebook || '',
      twitter: companyInfo.twitter || '',
    },
    onSubmit: (values) => {
      putCompanyInfo(formatCompanyData(values));
    },
  });

  useEffect(() => {
    getCompanyHashtags().then((hashtags) => {
      setHashtags(toSelectOptions(hashtags));
    });
  }, []);

  useEffect(() => {
    setColor({ hex: companyInfo.brandColor });
  }, [companyInfo]);

  const changeColor = (color) => {
    setColor(color);
    setShowSaveButton(true);
  };

  const createNewHashtag = (string) => {
    postNewCompanyHashtag(string).then((hashtag) => {
      setHashtags([...hashtags, { value: hashtag.id, label: hashtag.value }]);
      formik.setFieldValue('hashtags', [
        ...formik.values.hashtags,
        { value: hashtag.id, label: hashtag.value },
      ]);
    });
  };

  return (
    <div className={styles.wrapper}>
      {showSaveButton && (
        <div className={styles.saveButtons}>
          <Button theme="bordered">Cancel</Button>
          <Button
            onClick={() => {
              formik.submitForm();
              setShowSaveButton(false);
            }}
          >
            Save
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <h3>Сompany profile</h3>
          <p>Update your company photo and details here</p>
        </div>

        <form onSubmit={formik.handleSubmit} onChange={() => setShowSaveButton(true)}>
          <div className={styles.inputWrapper}>
            <h4>Company name</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                placeholder="Enter your company name"
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.logoUploaderWrapper}>
            <div className={styles.uploaderTitle}>
              <h4>Company logo</h4>
              <p>Update your company logo</p>
            </div>
            <div className={styles.dropzoneWrapper}>
              <ImageUploader
                name="logo"
                imageURL={formik.values.logo}
                setImageUrl={formik.setFieldValue}
              />
              <Checkbox>Do not include company logo on kudos wall</Checkbox>
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Company hashtags</h4>
            <div className={styles.fieldWrapper}>
              <CreatableSelect
                value={formik.values.hashtags}
                multi
                options={hashtags}
                handleCreate={createNewHashtag}
                onChange={(selected) => {
                  formik.setFieldValue('hashtags', [...selected]);
                  setShowSaveButton(true);
                }}
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Company website</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.website}
                name="website"
                placeholder="Enter here your company website link"
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Company size</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.size}
                name="size"
                placeholder="Enter here your number of employees"
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Company brand color</h4>
            <div className={styles.fieldWrapper}>
              <Input
                placeholder="Click here to change company brand color or brand image"
                onClick={setColorPickerOpen}
              />
              {colorPickerOpen && (
                <div ref={colorPickerModalRef} className={styles.dropdown}>
                  <span>Pick a color or upload image</span>
                  <ImageUploader
                    name="brandImage"
                    imageURL={formik.values.brandImage}
                    setImageUrl={formik.setFieldValue}
                  />
                  <ColorPicker
                    color={color}
                    onChange={changeColor}
                    onChangeComplete={(color) => formik.setFieldValue('brandColor', color.hex)}
                  />
                  <Input
                    placeholder="Type a valid hex color Ex: #0066FB"
                    value={color.hex}
                    onChange={(e) => setColor({ hex: e.target.value })}
                  />
                </div>
              )}
            </div>
            <div className={styles.offset} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyDetails;
