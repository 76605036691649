import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { postRequest } from '../api/post';
import { setCudosChart, setAddonChart } from '../features/chart/chart.slice';

import Chart from '../components/Chart/Chart';
import AnalyticsFilter from '../components/AnalyticsFilter/AnalyticsFilter';
import AnalyticsFilterByUser from '../components/AnalyticsFilterByUser/AnalyticsFilterByUser';
import AnalyticsInfo from '../components/AnalyticsInfo/AnalyticsInfo';
import Transactions from '../components/Transactions/Transactions';

import styles from '../scss/pages/Analytics.module.scss';

const Analytics = () => {
  const [kudoReceived, setKudoReceived] = useState();
  const [kudoGiven, setKudoGiven] = useState();
  const [addonReceived, setAddonReceived] = useState();
  const [addonGiven, setAddonGiven] = useState();
  const dispatch = useDispatch();
  const kudosData = useSelector((state) => state.chart.kudosList);
  const addonData = useSelector((state) => state.chart.addonList);
  const role = useSelector((state) => state.user.role);
  const filterData = useSelector((state) => state.transactions.filter);

  useEffect(() => {
    if (role && role !== 'SuperAdmin') {
      let type = 'user';
      if (role === 'Admin') {
        type = 'admin';
      } else {
        type = 'user';
      }
      postRequest(`kudos/statistic/${type}/kudos`, {}).then((res) => {
        try {
          if (res && res.chart && res.transactions) {
            setKudoReceived(res.received);
            setKudoGiven(res.given);
            const chartData = res.chart.map((item) => {
              return {
                value: item.count,
                name: format(new Date(item.date), 'MM/dd'),
              };
            });
            dispatch(setCudosChart(chartData));
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      });

      postRequest(`kudos/statistic/${type}/addon`, {}).then((res) => {
        try {
          if (res && res.chart && res.transactions) {
            setAddonReceived(res.received);
            setAddonGiven(res.given);
            const chartData = res.chart.map((item) => {
              return {
                value: item.count,
                name: format(new Date(item.date), 'MM/dd'),
              };
            });
            dispatch(setAddonChart(chartData));
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      });
    }
  }, []);

  return (
    <div>
      <h1 className={styles.analytics__title}>Analytics</h1>
      <div className={styles.analytics__filter}>
        <AnalyticsFilter />
        {role === 'Admin' && <AnalyticsFilterByUser />}
      </div>
      <div className={styles.analytics__content}>
        <div className={styles.analytics__kudos}>
          <AnalyticsInfo value={kudoReceived} title="Kudos Received" imageType="cudoReceived" />
          <AnalyticsInfo value={kudoGiven} title="Kudos Given" imageType="cudoGiven" />
        </div>
        <div className={styles.analytics__addon}>
          <AnalyticsInfo value={addonReceived} title="Perks Received" imageType="addonReceived" />
          <AnalyticsInfo value={addonGiven} title="Perks Given (Added-On)" imageType="addonGiven" />
        </div>
      </div>
      <div className={styles.analytics__chart}>
        <Chart
          filterData={filterData}
          data={kudosData}
          text="Kudos Sent Over Time"
          color="#FFE932"
          role={role}
          currentTypeChart="kudos"
        />
        <Chart
          filterData={filterData}
          data={addonData}
          text="Perks Sent Over Time"
          color="#1ABF5C"
          role={role}
          currentTypeChart="addons"
        />
      </div>
      <div>
        <Transactions />
      </div>
    </div>
  );
};

export default Analytics;
