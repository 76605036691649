import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import styles from '../scss/pages/Users.module.scss';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import UsersList from '../components/UsersList/UsersList';
import { hideModal, showModal } from '../features/modal/modal-slice';
import Modal from '../components/Modal/Modal';
import CreatableSelect from '../UI/Select/CreatableSelect';
import { inviteEmployees, postExportUsersCsv } from '../api/users';
import { fireUsers } from '../api/companies';
import { ReactComponent as Cross } from '../assets/icons/cancel-icon.svg';
import { ReactComponent as Minus } from '../assets/icons/minus-icon.svg';
import { ReactComponent as Plus } from '../assets/icons/plus-icon.svg';
import { ReactComponent as Download } from '../assets/icons/download-dark.svg';
import { ReactComponent as AddUser } from '../assets/icons/user-plus.svg';
import { ReactComponent as Error } from '../assets/icons/error-icon.svg';
import CsvUploader from '../components/CsvUploader/CsvUploader';
import { fireSelectedUsers, setAllUsersSelected } from '../features/company/company.slice';
import Spinner from '../UI/Spinner/Spinner';
import { ReactComponent as FilterIcon } from '../assets/icons/filter-icon.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';
import useOnClickOutside from '../hooks/useOnClickOutside';

function Users() {
  const user = useSelector((state) => state.user);
  const {
    list: users,
    numberOfSelected: selectedUsers,
    total: totalUsers,
  } = useSelector((state) => state.company.users);

  const filterInitial = {
    input: '',
    role: '',
    status: '',
  };

  const filterOptions = {
    roles: ['Admin', 'Employee'],
    statuses: ['enrolled', 'notEnrolled', 'fired'],
  };

  const [modal, setModal] = useState('invite');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [csv, setCsv] = useState(null);
  const [fireData, setFireData] = useState({
    users: users.map((user) => user.selected && user.id),
    reason: '',
  });
  const [filter, setFilter] = useState(filterInitial);
  const [exportCsvUsers, setExportCsvUsers] = useState({ ids: [] });
  const [userInfo, setUserInfo] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilter(false));

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!emailInputValue) return;
    switch (event.key) {
      case 'Enter':
      case ' ':
      case 'Tab':
        setEmails((prev) => [...prev, createOption(emailInputValue)]);
        setEmailInputValue('');
        event.preventDefault();
        break;
      default:
    }
  };

  const sendInvites = () => {
    if (user.role === 'Admin') {
      inviteEmployees({
        emails: emails.map((email) => email.value),
        csv,
      }).then(() => {
        dispatch(hideModal(true));
        navigate(0);
      });
    }
  };

  const openFireModal = () => {
    setModal('fire');
  };

  const openUserInfoModal = () => {
    setModal('user');
  };

  const handleFireUsers = () => {
    fireUsers(fireData).then(() => {
      dispatch(fireSelectedUsers());
      dispatch(setAllUsersSelected(false));
      dispatch(hideModal(true));
    });
  };

  const handleExportCsv = () => {
    postExportUsersCsv(exportCsvUsers).then((res) => {
      const blob = new Blob([res]);
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', href);
      link.setAttribute('download', 'company_users.csv');
      document.body.appendChild(link);

      link.click();
    });
  };

  useEffect(() => {
    setFireData({
      users: users.filter((user) => user.selected).map((user) => user.id),
      reason: '',
    });
    setExportCsvUsers({ ids: users.filter((user) => user.selected).map((user) => user.id) });
  }, [users]);

  const debouncedFilter = useCallback(
    debounce(
      (filterValue) =>
        setFilter((prev) => {
          return { ...prev, input: filterValue };
        }),
      300
    ),
    []
  );

  const clearAll = () => {
    setFilter((prev) => {
      return { ...filterInitial, input: prev.input };
    });
  };

  return (
    <div className={styles.page}>
      <h2>Users ({totalUsers})</h2>
      <div className={styles.controls}>
        <div className={styles.filters}>
          <div className={styles.searchInput}>
            <Input
              onChange={(e) => debouncedFilter(e.target.value)}
              placeholder="Search for user name"
            />
          </div>

          <div
            ref={filterRef}
            className={cn(styles.filterWrapper, { [styles.filterWrapper_active]: showFilter })}
          >
            <Button onClick={() => setShowFilter(!showFilter)} theme="text-dark">
              <FilterIcon /> Filter <ArrowDown />
            </Button>

            {showFilter && (
              <div className={styles.filter}>
                <div className={styles.clearButton}>
                  <Button onClick={clearAll} theme="bordered">
                    Clear all
                  </Button>
                </div>
                <h3>Filters</h3>
                <div className={styles.columnsWrapper}>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}>Position</div>
                    <div className={styles.filterItems}>
                      {filterOptions.roles.map((role, index) => (
                        <div
                          onClick={() => {
                            if (filter.role.includes(role)) {
                              setFilter((prev) => {
                                return { ...prev, role: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, role };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.role.includes(role),
                          })}
                          key={index}
                        >
                          {role}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.filterTitle}>Status</div>
                    <div className={styles.filterItems}>
                      {filterOptions.statuses.map((status, index) => (
                        <div
                          onClick={() => {
                            if (filter.status.includes(status)) {
                              setFilter((prev) => {
                                return { ...prev, status: '' };
                              });
                            } else {
                              setFilter((prev) => {
                                return { ...prev, status };
                              });
                            }
                          }}
                          className={cn(styles.filterItem, {
                            [styles.filterItem_active]: filter.status.includes(status),
                          })}
                          key={index}
                        >
                          {status === 'enrolled' && 'Enrolled'}
                          {status === 'notEnrolled' && 'Not Enrolled'}
                          {status === 'fired' && 'Fired'}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttons}>
          {selectedUsers ? (
            <>
              <Button onClick={handleExportCsv} theme="bordered">
                <Download /> Export CSV
              </Button>
              <Button
                theme="red"
                onClick={() => {
                  setModal('fire');
                  dispatch(showModal());
                }}
              >
                <Minus /> Fire Users ({fireData.users.length})
              </Button>
              <Button
                onClick={() => {
                  dispatch(setAllUsersSelected(false));
                  setAllChecked(false);
                }}
                theme="text-grey"
              >
                <Cross /> Dismiss
              </Button>
            </>
          ) : (
            <>
              <Button theme="bordered">
                <Download /> Export CSV
              </Button>
              <Button
                onClick={() => {
                  setModal('invite');
                  dispatch(showModal());
                }}
              >
                <Plus /> Invite New Users
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <UsersList
          setFireData={setFireData}
          setFireModal={openFireModal}
          setUserInfoModal={openUserInfoModal}
          setUserInfo={setUserInfo}
          filter={filter}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
        />
      </div>
      <Modal aside={modal === 'user'}>
        {modal === 'invite' && (
          <>
            <div className={styles.modalHeaderIcon}>
              <div className={styles.iconWrapper}>
                <AddUser />
              </div>
            </div>
            <h3 className={styles.modalHeader}>Add new users</h3>
            <p className={styles.modalDescription}>
              Invite admins and employees to your team by entering their email addresses
            </p>
            <div className={styles.multiInput}>
              <CreatableSelect
                multi
                value={emails}
                inputValue={emailInputValue}
                placeholder="Type email here and press enter"
                onChange={(newValue) => setEmails(newValue)}
                onInputChange={(newValue) => setEmailInputValue(newValue)}
                onKeyDown={handleKeyDown}
                menuIsOpen={false}
                isClearable
                components={{ DropdownIndicator: null }}
              />
            </div>
            <p className={styles.modalDescription}>or by uploading a CSV file</p>
            <div className={styles.csvUploader}>
              <CsvUploader setCsv={setCsv} />
            </div>
            <div className={styles.modalButtons}>
              <Button theme="bordered" onClick={() => dispatch(hideModal())}>
                Cancel
              </Button>
              <Button onClick={sendInvites}>Send invites</Button>
            </div>
          </>
        )}

        {modal === 'fire' && (
          <>
            <div className={styles.fireModalHeaderIcon}>
              <div className={styles.iconWrapper}>
                <Error />
              </div>
            </div>
            <h3 className={styles.fireModalHeader}>Fire employee</h3>
            <p className={styles.fireModalDescription}>
              Are you sure you want to fire selected employees? After firing, the employee will have
              an access only to a wallet section.
            </p>
            <div className={styles.fireModalInput}>
              <Input
                onChange={(e) => {
                  setFireData((prevState) => {
                    return { users: [...prevState.users], reason: e.target.value };
                  });
                }}
                placeholder="reason"
              />
            </div>

            <div className={styles.modalButtons}>
              <Button theme="bordered" onClick={() => dispatch(hideModal())}>
                Cancel
              </Button>
              <Button theme="red" onClick={handleFireUsers}>
                <Minus /> Fire ({fireData.users.length})
              </Button>
            </div>
          </>
        )}

        {modal === 'user' &&
          (userInfo ? (
            <>
              <div className={styles.infoBlock}>
                <div className={styles.header}>
                  General info{' '}
                  <div
                    className={cn(styles.status, {
                      [styles.status_enrolled]: userInfo.isEnrolled,
                      [styles.status_fired]: userInfo.isFired,
                    })}
                  >
                    <span>
                      {userInfo.isEnrolled && !userInfo.isFired
                        ? 'Enrolled'
                        : userInfo.isFired
                        ? 'Fired'
                        : 'Not Enrolled'}
                    </span>
                  </div>
                </div>
                <div className={styles.general}>
                  <div
                    className={styles.avatar}
                    style={{
                      background: userInfo.avatar && `url(${user.avatar}) no-repeat center / cover`,
                    }}
                  ></div>
                  <div className={styles.generalInfo}>
                    <div className={styles.name}>
                      {userInfo.firstname} {userInfo.lastname}
                    </div>
                    <div className={styles.role}>{userInfo.role}</div>
                  </div>
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.header}>Contact Info</div>
                <div className={styles.contacts}>
                  <div className={styles.contact}>
                    <div className={styles.title}>Email address</div>
                    <div className={styles.item}>{userInfo.email}</div>
                  </div>
                  <div className={styles.contact}>
                    <div className={styles.title}>Phone number</div>
                    <div className={styles.item}>{userInfo.phone}</div>
                  </div>
                  <div className={styles.contact}>
                    <div className={styles.title}>Birthday</div>
                    <div className={styles.item}>
                      {format(new Date(userInfo.dateOfBirth), 'MMM dd')}
                    </div>
                  </div>
                  <div className={styles.contact}>
                    <div className={styles.title}>Work anniversary</div>
                    <div className={styles.item}>
                      {format(new Date(userInfo.startOfWork), 'MMM dd')}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.modalSpinner}>
              <Spinner />
            </div>
          ))}
      </Modal>
    </div>
  );
}

export default Users;
