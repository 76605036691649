import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useInfiniteScroll(
  pageNumber,
  requestFunction,
  updateFunction,
  currentLength,
  query = '',
  clearFunction = undefined
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFunction());
    // eslint-disable-next-line
  }, [query, dispatch]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    requestFunction(query, pageNumber).then((res) => {
      dispatch(updateFunction(res.list));
      setHasMore(currentLength < res.total);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [query, pageNumber, dispatch]);

  return { loading, error, hasMore };
}
