import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { setBitcoin } from '../features/bitcoin/bitcoin.slice';

import bitcoinWallet from '../assets/images/bitcoinWallet.svg';
import copyIcon from '../assets/images/copy.svg';
import infoIcon from '../assets/images/info.svg';
import exchange from '../assets/images/exchange.svg';
import withdraw from '../assets/images/withdraw.svg';
import transaction from '../assets/images/transaction.svg';

import styles from '../scss/pages/Wallet.module.scss';

const Wallets = () => {
  useEffect(() => {
    return () => {
      ReactTooltip.rebuild();
    };
  }, []);

  const dispatch = useDispatch();
  const coins = useSelector((state) => state.bitcoin.data);
  const url = 'https://api.coingecko.com/api/v3/coins/bitcoin?vs_currency=usd';

  const balancePercentage =
    coins && coins.market_data ? (1000 / coins.market_data.current_price.usd).toFixed(3) : '0,0342';

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => dispatch(setBitcoin(data)))
      // eslint-disable-next-line
      .catch((error) => console.log('Error:', error));
    // eslint-disable-next-line
  }, [url]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <h1>Personal Wallet</h1>
          <div
            data-for="success"
            data-tip="The wallet will be available during the month!"
            data-event-off="mouseleave"
            className={styles.addon__wrapper}
          >
            <img src={infoIcon} alt="bitcoin icon" />
            <ReactTooltip className="customTooltip" id="success" effect="solid" place="bottom" />
          </div>
        </div>
        <div className={styles.header__wallet}>
          <img src={copyIcon} alt="bitcoin icon" />
          2jgT...4361
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.main__info}>
          <img className={styles.main__info__bitcoin} src={bitcoinWallet} alt="bitcoin" />
          <span className={styles.main__info__total}>Total Bitcoin</span>
          <span className={styles.main__info__balance}>$1,000</span>
          <img className={styles.main__info__exchange} src={exchange} alt="exchange" />
          <span className={styles.main__info__value}>{balancePercentage}</span>
        </div>
        <div className={styles.main__withdraw}>
          <img src={withdraw} alt="withdraw" />
          <span>Withdraw</span>
        </div>
      </div>
      <div className={styles.table}>
        <h1>Transactions History</h1>
        <div className={styles.table__empty}>
          <p>You have no transaction history for now, but every operation will be saved here.</p>
          <button type="button">
            <img src={transaction} alt="transaction" />
            Make a Transaction
          </button>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
