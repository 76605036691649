import React, { useState, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import { useDispatch } from 'react-redux';

import getRequest from '../../api/get';
import { createPost } from '../../api/createPost';
import { setPosts } from '../../features/posts/posts-slice';

import AvatarUser from '../AvatarUser/AvatarUser';
import greyBitcoin from '../../assets/images/greyBitcoin.svg';
import fileIcon from '../../assets/images/fileIcon.svg';
import editIcon from '../../assets/images/editIcon.svg';
import hashtagIcon from '../../assets/images/hashtagIcon.svg';
import styles from './Post.module.scss';

const Post = () => {
  const [data, setData] = useState();
  const [showError, SetShowError] = useState(null);
  const [value, setValue] = useState(null);
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [errorImg, setErrorImg] = useState(false);
  const [hashtagsList, setHashtagsList] = useState([]);
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [addCash, setAddCash] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (errorImg) {
      setTimeout(() => {
        setErrorImg(false);
        SetShowError(null);
      }, 2000);
    }
  }, [errorImg, showError]);

  useEffect(() => {
    if (SetShowError) {
      setTimeout(() => {
        SetShowError(null);
      }, 2000);
    }
  }, [showError]);

  // eslint-disable-next-line
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div className={styles.post__select__value}>
          <AvatarUser avatar={props.data.avatar} />
          <span>{children}</span>
        </div>
      </components.SingleValue>
    );
  };

  // eslint-disable-next-line
  const Option = (props) => {
    const { children, data } = props;
    return (
      <components.Option {...props}>
        <div className={styles.post__select__option}>
          <AvatarUser avatar={data.avatar} />
          <span>{children}</span>
        </div>
      </components.Option>
    );
  };

  const promiseOptions = () => {
    getRequest('kudos-wall/usersList').then((res) => {
      try {
        if (res && res[0]) {
          res.forEach((item) => {
            item.value = item.firstname + ' ' + item.lastname;
            item.label = item.firstname + ' ' + item.lastname;
          });
          setData(res);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    });
  };

  const handleChangeSelect = (e) => {
    setValue(e.id);
  };

  useEffect(() => {
    promiseOptions();
  }, []);

  const getHashtagList = () => {
    getRequest('kudos-wall/hashtags').then((res) => {
      try {
        if (res && res[0]) {
          setHashtagsList(res);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    });
  };

  useEffect(() => {
    if (value && hashtagsList && hashtagsList.length === 0) {
      getHashtagList();
    }
  }, [value, hashtagsList]);

  const placeholderSelect = () => (
    <div className={styles.post__select__placeholder}>
      <AvatarUser />
      Start typing a team member’s name...
    </div>
  );

  const uploadFile = (e) => {
    if (
      e.currentTarget.files &&
      e.currentTarget.files[0] &&
      e.currentTarget.files[0].type === 'image/gif'
    ) {
      setFile(e.currentTarget.files[0]);
    } else {
      setErrorImg(true);
    }
  };

  const handleCreatePost = () => {
    const data = {
      text: text,
      recognizedUserId: value,
      isAnonymous,
      sendKudos: addCash,
    };

    if (selectedHashtags && selectedHashtags.length) {
      data.hashtags = JSON.stringify(selectedHashtags);
    }
    if (file) {
      data.file = file;
    }
    createPost('kudos-wall/posts', data).then((res) => {
      try {
        if (res) {
          setValue(null);
          setFile();
          setSelectedHashtags([]);
          setIsAnonymous(false);
          setAddCash(false);
          setText('');
          getRequest('kudos-wall/posts?page=1&mostRecent=true').then((res) => {
            try {
              if (res) {
                dispatch(setPosts(res.list));
              }
            } catch (error) {
              // eslint-disable-next-line
              console.error(error);
            }
          });
        }
        SetShowError(res[0]);
      } catch (error) {
        SetShowError(error);
      }
    });
  };

  const handlerHashtags = (id) => {
    const checkId = selectedHashtags.find((item) => item === id);
    if (checkId) {
      const filterHashtags = selectedHashtags.filter((item) => item !== id);
      setSelectedHashtags(filterHashtags);
    } else {
      if (selectedHashtags && selectedHashtags.length) {
        if (selectedHashtags.length <= 2) {
          const updateHashtags = [...selectedHashtags, id];
          setSelectedHashtags(updateHashtags);
        }
      } else {
        setSelectedHashtags([id]);
      }
    }
  };

  const cancelPost = () => {
    setValue(null);
    setFile();
    setSelectedHashtags([]);
    setIsAnonymous(false);
    setAddCash(false);
    setText('');
  };
  return (
    <div className={styles.post}>
      <div className={styles.post__container}>
        <p className={styles.post__title}>Recognize a team member!</p>
        <p className={styles.post__subtitle}>Who do you want to recogize?</p>
        <ReactSelect
          placeholder={placeholderSelect()}
          components={{ SingleValue, Option }}
          options={data}
          onChange={(e) => handleChangeSelect(e)}
          value={(data && data.find((item) => item.id === value)) || ''}
        />
        {value && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className={styles.post__textarea__wrapper}>
              <img className={styles.post__textarea__image} src={editIcon} alt="" />
              <textarea
                className={styles.post__textarea}
                placeholder="Describe why you’re giving them recognition..."
                rows="3"
                onChange={(e) => setText(e.target.value)}
                value={text}
                maxLength={1000}
              ></textarea>
            </label>
            <label className={styles.post__attach}>
              <input onChange={(e) => uploadFile(e)} style={{ display: 'none' }} type="file" />
              {file ? (
                <span>{file.name}</span>
              ) : (
                <>
                  <img src={fileIcon} />
                  <span>Attach a GIF</span>
                </>
              )}
            </label>
            {errorImg && <p className={styles.post__error}>You can added only gif image</p>}
            <p className={styles.post__hashtags__subtitle}>Recogize for (Select up to three)</p>
            <div className={styles.post__hashtags}>
              {hashtagsList.length > 0 &&
                hashtagsList.map((item) => {
                  const checkSelectedHashtag =
                    selectedHashtags && selectedHashtags.includes(item.id);
                  return (
                    <button
                      type="button"
                      key={item.id}
                      onClick={() => handlerHashtags(item.id)}
                      className={`${
                        checkSelectedHashtag ? styles.post__hashtags__active : undefined
                      }`}
                    >
                      <img src={hashtagIcon} alt="hashtag icon" />
                      <span>{item.value}</span>
                    </button>
                  );
                })}
            </div>
            <div className={styles.post__footer}>
              <div className={styles.post__footer__left}>
                <label className={styles.post__cash} htmlFor="cash">
                  <input id="cash" type="checkbox" onChange={(e) => setAddCash(e.target.checked)} />
                  <span>Add $25 worth of</span>
                  <img src={greyBitcoin} alt="bitcoin icon" />
                </label>
                <label className={styles.post__user} htmlFor="user">
                  <input
                    onChange={(e) => setIsAnonymous(e.target.checked)}
                    id="user"
                    type="checkbox"
                  />
                  <span>Make anonymous</span>
                </label>
              </div>
              <div className={styles.post__footer__right}>
                <button
                  className={styles.post__footer__cancel}
                  type="button"
                  onClick={() => cancelPost()}
                >
                  Cancel
                </button>
                <button
                  className={styles.post__footer__recognize}
                  onClick={() => handleCreatePost()}
                  type="button"
                >
                  Recognize
                </button>
              </div>
            </div>
            {showError && (
              <p className={styles.post__error} style={{ color: 'red', fontSize: '12px' }}>
                {showError}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
