import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { hideModal } from '../../features/modal/modal-slice';

import styles from './Modal.module.scss';

const Modal = ({ children, aside = false }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modal.isOpen);

  return (
    isOpen && (
      <div className={styles.modal} onClick={() => dispatch(hideModal())}>
        <div
          className={cn(styles.modal__container, { [styles.modal__container_aside]: aside })}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
