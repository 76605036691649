import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { format } from 'date-fns';

import { postRequest } from '../../api/post';
import { setCudosChart, setAddonChart } from '../../features/chart/chart.slice';

import styles from './Chart.module.scss';

const Chart = ({ data, text, color, filterData, role, currentTypeChart }) => {
  const dispatch = useDispatch();
  const [change, setTypeChange] = useState(true);

  const typeChart = useSelector((state) => state.transactions.type);

  const changeTypeChart = (value) => {
    setTypeChange(value);
  };

  useEffect(() => {
    if (currentTypeChart === typeChart) {
      let type = 'user';
      if (role === 'Admin') {
        type = 'admin';
      } else {
        type = 'user';
      }

      let filterChart = {};
      const { from, to, page = 1, sortBy, orderDesc, userIds } = filterData;
      if (filterData && filterData.from !== '') {
        const filterFrom = JSON.parse(from);
        const filterTo = JSON.parse(to);
        filterChart = {
          page,
          orderDesc,
          userIds,
          sortBy,
          range: {
            from: filterFrom,
            to: filterTo,
          },
        };
      } else {
        filterChart = {
          page,
          orderDesc,
          userIds,
          sortBy,
          range: {},
        };
      }
      if (currentTypeChart === 'kudos') {
        postRequest(`kudos/statistic/${type}/kudos`, filterChart).then((res) => {
          try {
            if (res && res.chart && res.transactions) {
              const chartData = res.chart.map((item) => {
                return {
                  value: item.count,
                  name: format(new Date(item.date), 'MM/dd'),
                };
              });
              dispatch(setCudosChart(chartData));
            }
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        });
        postRequest(`kudos/statistic/${type}/addon`, {}).then((res) => {
          try {
            if (res && res.chart && res.transactions) {
              const chartData = res.chart.map((item) => {
                return {
                  value: item.count,
                  name: format(new Date(item.date), 'MM/dd'),
                };
              });
              dispatch(setAddonChart(chartData));
            }
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        });
      } else {
        postRequest(`kudos/statistic/${type}/addon`, filterChart).then((res) => {
          try {
            if (res && res.chart && res.transactions) {
              const chartData = res.chart.map((item) => {
                return {
                  value: item.count,
                  name: format(new Date(item.date), 'MM/dd'),
                };
              });
              dispatch(setAddonChart(chartData));
            }
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        });
        postRequest(`kudos/statistic/${type}/kudos`, {}).then((res) => {
          try {
            if (res && res.chart && res.transactions) {
              const chartData = res.chart.map((item) => {
                return {
                  value: item.count,
                  name: format(new Date(item.date), 'MM/dd'),
                };
              });
              dispatch(setCudosChart(chartData));
            }
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        });
      }
    }
  }, [filterData, currentTypeChart]);

  return (
    <div className={styles.chart}>
      <span className={styles.chart__title}>{text}</span>
      <div className={styles.chart__button}>
        <span
          className={styles.chart__button__line}
          style={{
            color: change === true && '#0066FB',
            backgroundColor: change === true && '#E6F0FF',
          }}
          onClick={() => changeTypeChart(true)}
        >
          Line Chart
        </span>
        <span
          style={{
            color: change === false && '#0066FB',
            backgroundColor: change === false && '#E6F0FF',
          }}
          className={styles.chart__button__bar}
          onClick={() => changeTypeChart(false)}
        >
          Bar Chart
        </span>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        {change === true ? (
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 10,
              left: 0,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: `8` }} />
          </LineChart>
        ) : (
          <BarChart
            data={data}
            width={500}
            height={300}
            margin={{
              top: 10,
              right: 10,
              left: 0,
              bottom: 10,
            }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="value" />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="value" fill={color} barSize={20} />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
