import { postFormData, postRequest } from './post';
import getRequest from './get';
import deleteRequest from './delete';
import { putRequest } from './put';

const url = 'users';

// GET requests
export const getUsers = async (query = '', page = 1) => {
  return getRequest(`${url}?${query}&page=${page}`);
};

export const getUser = async () => {
  return getRequest(`${url}/profile`);
};

export const getUserInfo = async (id) => {
  return getRequest(`${url}/${id}`);
};

// POST requests
export const postExportUsersCsv = async (data) => {
  return postRequest(`${url}/admin/csv`, data);
};

export const postExportClientsCsv = async (data) => {
  return postRequest(`${url}/csv`, data);
};

export const sendUserAvatar = async (avatar) => {
  const formData = new FormData();
  formData.append('file', avatar);
  return postFormData(`${url}/avatar`, formData);
};

export const inviteEmployees = async (data) => {
  const formData = new FormData();
  formData.append('emails', JSON.stringify(data.emails));
  if (data.csv) {
    formData.append('file', data.csv);
  }
  return postFormData(`${url}/employee/invite`, formData);
};

export const inviteCompanies = async (data) => {
  const formData = new FormData();
  formData.append('emails', JSON.stringify(data.emails));
  if (data.csv) {
    formData.append('file', data.csv);
  }
  return postFormData(`${url}/admin/invite`, formData);
};
// Will return new mnemonic phrase and connect new wallet to your account if no data sent.
// If you want to add existing wallet - send mnemonic of existing wallet.
export const walletPhrase = async (data = {}) => {
  return postRequest(`${url}/wallet`, data);
};

// PUT requests
export const editUser = async (data) => {
  return putRequest(`${url}/profile`, data);
};

export const registerUserStepOne = async (data) => {
  return putRequest(`${url}/register`, data);
};

export const registerUserStepThree = async (data) => {
  return putRequest(`${url}/completeRegistration`, data);
};

// DELETE requests
export const deleteUserAvatar = async () => {
  return deleteRequest(`${url}/avatar`);
};
