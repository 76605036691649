import {
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

const differentTime = (createdAt, isYear) => {
  const time = new Date(createdAt);
  if (isYear === true) {
    const yearTime = Math.abs(differenceInYears(time, new Date()));
    if (yearTime === 0) {
      return '1 year';
    }
    return yearTime === 1 ? yearTime + ' year' : yearTime + ' years';
  }
  const filtedTime = Math.abs(differenceInHours(time, new Date()));
  if (Math.abs(differenceInHours(time, new Date())) > 24) {
    const filtedDay = Math.abs(differenceInDays(time, new Date()));
    if (Math.abs(differenceInMonths(time, new Date())) > 0) {
      const filterMounth = Math.abs(differenceInMonths(time, new Date()));
      return filterMounth === 1 ? filterMounth + ' month' : filterMounth + ' months';
    }
    return filtedDay === 1 ? filtedDay + ' day' : filtedDay + ' days';
  }
  return filtedTime === 0
    ? 'new'
    : filtedTime + filtedTime === 1
    ? filtedTime + ' hr'
    : filtedTime + ' hrs';
};

export default differentTime;
