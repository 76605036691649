import { API_BASE } from './constants';

export const patchRequest = async (url, data = {}) => {
  const token = localStorage.getItem('user');
  const response = await fetch(`${API_BASE}${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  const text = await response.text();

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
};
