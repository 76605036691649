import React from 'react';
import cn from 'classnames';
import styles from './StepChooser.module.scss';

function StepChooser({ chosenValue, setChosenValue, stepOptions }) {
  return (
    <div className={styles.wrapper}>
      {stepOptions.map((option, index) => (
        <div
          className={cn(styles.option, { [styles.option_active]: option < chosenValue })}
          key={index}
        >
          <div className={styles.optionLabel}>${option}</div>
          <div
            className={cn(styles.optionButton, {
              [styles.optionButton_active]: chosenValue === option,
            })}
            onClick={() => setChosenValue(option)}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default StepChooser;
