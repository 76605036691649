import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {
    brandColor: '',
    country: null,
    facebook: '',
    hashtags: [],
    id: '',
    linkedin: '',
    logo: '',
    brandImage: '',
    name: '',
    size: '',
    twitter: '',
    website: '',
  },
  users: {
    list: [],
    numberOfSelected: 0,
    total: null,
  },
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyInfo(state, action) {
      state.info = action.payload;
    },
    updateCompanyLogo(state, action) {
      state.info.logo = action.payload;
    },
    updateCompanyBrandImage(state, action) {
      state.info.brandImage = action.payload;
    },
    setCompanyUsers(state, action) {
      const usersIds = state.users.list.map((item) => item.id);
      const filterItem = action.payload.filter((item) => !usersIds.includes(item.id));
      if (filterItem && filterItem.length > 0) {
        state.users.list = [
          ...state.users.list,
          ...filterItem.map((user) => {
            return { ...user, fullName: `${user.firstname} ${user.lastname}`, selected: false };
          }),
        ];
      }
    },
    setCompanyUsersTotal(state, action) {
      state.users.total = action.payload;
    },
    clearCompanyUsers(state) {
      state.users.list = [];
      state.users.numberOfSelected = 0;
    },
    fireSelectedUsers(state) {
      state.users.list = state.users.list.map((user) =>
        user.selected ? { ...user, isFired: true } : user
      );
    },
    toggleCompanyUserSelected(state, action) {
      const isUserSelected = state.users.list.find((user) => user.id === action.payload).selected;
      if (!isUserSelected) {
        state.users.numberOfSelected += 1;
      } else {
        state.users.numberOfSelected -= 1;
      }
      state.users.list.find((user) => user.id === action.payload).selected = !isUserSelected;
    },
    setAllUsersSelected(state, action) {
      state.users.list = state.users.list.map((user) => {
        return { ...user, selected: action.payload };
      });
      if (action.payload) {
        state.users.numberOfSelected = state.users.list.length;
      } else {
        state.users.numberOfSelected = 0;
      }
    },
  },
});

export const {
  setCompanyInfo,
  updateCompanyLogo,
  updateCompanyBrandImage,
  setCompanyUsers,
  setCompanyUsersTotal,
  toggleCompanyUserSelected,
  setAllUsersSelected,
  clearCompanyUsers,
  fireSelectedUsers,
} = companySlice.actions;
export default companySlice.reducer;
