import * as Yup from 'yup';

export const stepThreeSchema = Yup.object().shape({
  // email: Yup.string().email('Must be a valid email').required('This field is required'),
  // password: Yup.string().min(8, 'Must be at least 8 characters').required('This field is required'),
  // confirm_password: Yup.string()
  //   .min(8, 'Must be at least 8 characters')
  //   .required('This field is required')
  //   .oneOf([Yup.ref('password'), null], "The passwords don't match"),
});
