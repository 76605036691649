import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { postRequest } from '../../api/post';
import getRequest from '../../api/get';

import { updatePostItem } from '../../features/posts/posts-slice';

import bitcoin from '../../assets/images/bitcoinAddon.svg';
import styles from './ButtonAddon.module.scss';

const ButtonAddon = ({ addedOnByCurrentUser, id, type, tooltip }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.list);
  useEffect(() => {
    return () => {
      ReactTooltip.rebuild();
    };
  }, []);

  const handlerAddon = () => {
    if (type === 'posts') {
      const data = {
        addOnType: 'KudosPost',
        postId: id,
      };
      postRequest(`kudos-wall/addon`, data).then((res) => {
        try {
          if (res) {
            getRequest(`kudos-wall/posts/${id}`).then((res) => {
              try {
                if (res) {
                  const data = {
                    type: res,
                  };

                  posts?.find((item, index) => {
                    if (item.id === id) {
                      data.index = index;
                      return true;
                    }
                    return false;
                  });
                  dispatch(updatePostItem(data));
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      });
    } else {
      const data = {
        addOnType: type,
        recipientId: id,
      };
      postRequest(`kudos-wall/addon`, data).then((res) => {
        try {
          if (res) {
            // getRequest(`kudos-wall/posts/${id}`).then((res) => {
            //   try {
            //     if (res) {
            //       dispatch(updatePostItem(data));
            //     }
            //   } catch (error) {
            //     // eslint-disable-next-line
            //     console.log(error);
            //   }
            // });
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      });
    }
  };

  return addedOnByCurrentUser === false ? (
    tooltip === true ? (
      <div
        data-for="success"
        data-tip="you can add addOn 30 days before the current event and the day of the event"
        data-event-off="mouseleave"
        className={styles.addon__wrapper}
        onClick={() => handlerAddon()}
      >
        <img src={bitcoin} alt="bitcoin icon" />
        <span>Add on</span>
        <ReactTooltip className="customTooltip" id="success" effect="solid" place="bottom" />
      </div>
    ) : (
      <div className={styles.addon__wrapper} onClick={() => handlerAddon()}>
        <img src={bitcoin} alt="bitcoin icon" />
        <span>Add on</span>
      </div>
    )
  ) : (
    <div className={styles.addon__wrapper__disable}>Added on</div>
  );
};

export default ButtonAddon;
