import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  history: {
    list: [],
    numberOfSelected: 0,
    nexPage: '',
    total: null,
  },
};

const historySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setPaymentHistory(state, action) {
      state.history.list = [
        ...state.history.list,
        ...action.payload.map((historyItem) => {
          return { ...historyItem, selected: false };
        }),
      ];
    },
    setHistoryTotal(state, action) {
      state.history.total = action.payload;
    },
    setHistoryNextPage(state, action) {
      state.history.nextPage = action.payload;
    },
    clearHistory(state) {
      state.history.list = [];
      state.history.numberOfSelected = 0;
    },
    toggleHistoryItemSelected(state, action) {
      const isUserSelected = state.users.list.find((user) => user.id === action.payload).selected;
      if (!isUserSelected) {
        state.users.numberOfSelected += 1;
      } else {
        state.users.numberOfSelected -= 1;
      }
      state.users.list.find((user) => user.id === action.payload).selected = !isUserSelected;
    },
    setAllHistorySelected(state, action) {
      state.users.list = state.users.list.map((user) => {
        return { ...user, selected: action.payload };
      });
      if (action.payload) {
        state.users.numberOfSelected = state.users.list.length;
      } else {
        state.users.numberOfSelected = 0;
      }
    },
  },
});

export const {
  setPaymentHistory,
  setHistoryTotal,
  setHistoryNextPage,
  toggleHistoryItemSelected,
  setAllHistorySelected,
  clearHistory,
} = historySlice.actions;
export default historySlice.reducer;
