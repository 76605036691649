import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect, { components } from 'react-select';

import getRequest from '../api/get';
import { getPosts } from '../api/getPosts';
import { filterDataPost } from '../api/data';
import { updatePosts, clearPosts } from '../features/posts/posts-slice';

import Post from '../components/Post/Post';
import PostList from '../components/PostList/PostList';
import AnniversariesList from '../components/AnniversariesList/AnniversariesList';
import BirthdaysList from '../components/BirthdaysList/BirthdaysList';
import HashtagsList from '../components/HashtagsList/HashtagsList';
import HashtagsFilter from '../components/HashtagsFilter/HashtagsFilter';

import useInfiniteScroll from '../hooks/useInfiniteScroll';

import PortfolioBalance from '../components/PortfolioBalance/PortfolioBalance';
import bitcoin from '../assets/images/bitcoin.svg';
import filterLetter from '../assets/images/filterLetter.svg';

import styles from '../scss/pages/Home.module.scss';

const Home = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const info = useSelector((state) => state.company.info);
  const posts = useSelector((state) => state.posts.list);
  const filterHashtags = useSelector((state) => state.hashtags.filterHashtag);
  const logo = info && info.logo ? info.logo : '';
  const backgroundImage = `${info && info.brandColor ? info.brandColor : '#CA33FF'} url(${
    info && info.brandImage
  }) no-repeat center / cover`;
  const [score, setScore] = useState(0);
  const [value, setValue] = useState('mostRecent=true');

  useEffect(() => {
    setPageNumber(1);
  }, [filterHashtags]);

  const filterHashtagString =
    filterHashtags.length > 0
      ? `${value}&hashtags=[${filterHashtags.map((item) => `"${item}"`)}]`
      : value;

  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    getPosts,
    updatePosts,
    posts.length,
    `${filterHashtagString}`,
    clearPosts
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    getRequest('kudos-wall/allowance').then((res) => {
      try {
        if (res) {
          setScore(res);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    });
  }, [score]);

  const handleChangeSelect = (e) => {
    if (pageNumber !== 1) {
      dispatch(clearPosts());
    }
    setValue(e.value);
    setPageNumber(1);
  };

  // eslint-disable-next-line
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div className={styles.post__select__value}>
          <img src={filterLetter} alt="filter icon" />
          <span>{children}</span>
        </div>
      </components.SingleValue>
    );
  };

  // eslint-disable-next-line
  const Option = (props) => {
    const { children } = props;
    return (
      <components.Option {...props}>
        <div>
          <span>{children}</span>
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <div className={styles.create__post} style={{ background: backgroundImage }}>
        {logo && <img className={styles.logo} src={logo} alt="logo company" />}
        <h1 className={styles.title}>
          Kudos Wall{' '}
          <span className={styles.title__text}>
            (You have <img className={styles.title__img} src={bitcoin} /> ${score && score} to give
            away)
          </span>
        </h1>
        <div className={styles.container}>
          <Post />
          <PortfolioBalance />
        </div>
        <div className={styles.home__wrapper}>
          <div className={styles.home__line}></div>
          <ReactSelect
            className={styles.post__select__option}
            components={{ SingleValue, Option }}
            options={filterDataPost}
            onChange={(e) => handleChangeSelect(e)}
            value={(filterDataPost && filterDataPost.find((item) => item.value === value)) || ''}
          />
        </div>
      </div>
      {filterHashtags.length > 0 ? <HashtagsFilter filterHashtags={filterHashtags} /> : null}

      <div className={styles.main__content}>
        <div className={styles.posts__wrapper}>
          {posts && posts.length ? (
            posts.map((item) => {
              return (
                <PostList
                  ref={lastElementRef}
                  key={item.id}
                  hashtags={item.hashtags}
                  id={item.id}
                  recognizedUser={item.recognizedUser}
                  createdAt={item.createdAt}
                  createdBy={item.createdBy}
                  addedOnByCurrentUser={item.addedOnByCurrentUser}
                  file={item.file}
                  isAnonymous={item.isAnonymous}
                  text={item.text}
                  btcAmount={item.btcAmount}
                  likedByCurrentUser={item.likedByCurrentUser}
                  firstUsersReacted={item.firstUsersReacted}
                  count={item._count}
                />
              );
            })
          ) : (
            <p className={styles.posts__empty}>There are no posts yet. Be the first one to post!</p>
          )}
        </div>
        <div className={styles.anniversaries}>
          <div className={styles.anniversaries__wrapper}>
            <div className={styles.anniversaries__line}></div>
            <h2 className={styles.anniversaries__title}>What’s Happening</h2>
          </div>
          <AnniversariesList />
          <BirthdaysList />
          <HashtagsList
            hashtagsLength={filterHashtags.length}
            hashtagsArraySelect={filterHashtags}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
