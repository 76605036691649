import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getRequest from '../../api/get';

import Birthdays from './Birthdays/Birthdays';
import { setBirthdays } from '../../features/birthdays/birthdays.slice';

import birthdaysIcon from '../../assets/images/birthdays.svg';

import styles from './BirthdaysList.module.scss';

const BirthdaysList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.birthdays.list);
  const companyId = useSelector((state) => state.company.info.id);
  useEffect(() => {
    if (companyId) {
      getRequest(`kudos-wall/birthdays?getClosest4=true&companyId=${companyId}`).then((res) => {
        try {
          if (res && res.list) {
            dispatch(setBirthdays(res.list));
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      });
    }
    // eslint-disable-next-line
  }, [companyId]);

  return (
    <div className={styles.birthdays__wrapper}>
      <div className={styles.birthdays__title}>
        <img src={birthdaysIcon} alt="Upcoming birthdays icon" />
        Upcoming birthdays
      </div>
      {data.length > 0 &&
        data.map((item) => (
          <Birthdays
            key={item.id}
            id={item.id}
            avatar={item.avatar}
            lastname={item.lastname}
            firstname={item.firstname}
            dateOfBirth={item.dateOfBirth}
            addedOnByCurrentUser={item.addedOnByCurrentUser}
          />
        ))}
    </div>
  );
};

export default BirthdaysList;
