import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const birthdaysSlice = createSlice({
  name: 'anniversaries',
  initialState,
  reducers: {
    setBirthdays(state, action) {
      state.list = action.payload;
    },
    clearBirthdays(state) {
      state.list = [];
    },

    updateBirthdays(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },

    updateBirthdaysItem(state, action) {
      const { type, index } = action.payload;
      state.list[index] = type;
    },
  },
});

export const { setBirthdays, clearBirthdays, updateBirthdays, updateBirthdaysItem } =
  birthdaysSlice.actions;
export default birthdaysSlice.reducer;
