import { postFormData } from './post';

export const createPost = async (url, data) => {
  const formData = new FormData();
  // eslint-disable-next-line
  for (const name in data) {
    if (data) {
      formData.append(name, data[name]);
    }
  }
  return postFormData(`${url}`, formData);
};
