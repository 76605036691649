import React from 'react';
import userHomeIcon from '../../assets/images/userHomeIcon.svg';

import styles from './AvatarUser.module.scss';

const AvatarUser = ({ avatar, backgroundColor, size }) => {
  return (
    <img
      className={styles.avatar}
      style={{ backgroundColor: backgroundColor, width: size, height: size }}
      src={avatar || userHomeIcon}
      alt="user icon"
    />
  );
};

export default AvatarUser;
