import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postRequest } from '../../../api/post';
import getRequest from '../../../api/get';

import { ReactComponent as LikeIcon } from '../../../assets/images/like.svg';
import { ReactComponent as LikedIcon } from '../../../assets/images/liked.svg';

import { updateReplyItems } from '../../../features/reply/reply.slice';
import { updatePostItem } from '../../../features/posts/posts-slice';
import { updateCommentItem } from '../../../features/comment/comment.slice';

import styles from './Like.module.scss';

const Like = ({ parentCommentId, likedByCurrentUser, id, type, isReply }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.list);
  const comments = useSelector((state) => state.comment.list);

  const handlerLike = () => {
    postRequest(`kudos-wall/${type}/like/${id}`).then((res) => {
      try {
        if (res) {
          if (isReply) {
            getRequest(`kudos-wall/comments/replies?commentId=${parentCommentId}&page=1`).then(
              (res) => {
                try {
                  if (res && res.list) {
                    dispatch(updateReplyItems(res.list));
                  }
                } catch (error) {
                  // eslint-disable-next-line
                  console.log(error);
                }
              }
            );
          } else {
            getRequest(`kudos-wall/${type}/${id}`).then((res) => {
              try {
                if (res) {
                  const data = {
                    type: res,
                  };
                  if (type === 'comments') {
                    comments?.find((item, index) => {
                      if (item.id === id) {
                        data.index = index;
                        return true;
                      }
                      return false;
                    });
                    dispatch(updateCommentItem(data));
                  } else {
                    posts?.find((item, index) => {
                      if (item.id === id && item.isOpen === true) {
                        const filterRes = res;
                        filterRes.isOpen = true;
                        data.type = filterRes;
                        data.index = index;
                        return true;
                      }
                      if (item.id === id) {
                        data.index = index;
                        return true;
                      }
                      return false;
                    });
                    dispatch(updatePostItem(data));
                  }
                }
              } catch (error) {
                // eslint-disable-next-line
                console.log(error);
              }
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    });
  };

  return (
    <span className={styles.posts__footer__like} onClick={() => handlerLike()}>
      {likedByCurrentUser ? (
        <>
          <LikedIcon />
          <span style={{ color: '#0066FB' }}>Like</span>
        </>
      ) : (
        <>
          <LikeIcon />
          <span>Like</span>
        </>
      )}
    </span>
  );
};

export default Like;
