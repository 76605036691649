import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kudosList: [],
  addonList: [],
};

const chartSlice = createSlice({
  name: 'anniversaries',
  initialState,
  reducers: {
    setCudosChart(state, action) {
      state.kudosList = action.payload;
    },
    setAddonChart(state, action) {
      state.addonList = action.payload;
    },

    updateChart(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },
  },
});

export const { setCudosChart, setAddonChart, updateChart } = chartSlice.actions;
export default chartSlice.reducer;
