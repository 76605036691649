import React from 'react';
import cn from 'classnames';
import styles from './Input.module.scss';
import { ReactComponent as EyeIconOn } from '../../assets/icons/eye-on.svg';
import { ReactComponent as EyeIconOff } from '../../assets/icons/eye-off.svg';
import { ReactComponent as Error } from '../../assets/icons/error-icon.svg';

function Input({
  placeholder = 'placeholder',
  label,
  isInvalid,
  disabled,
  type = 'text',
  ...rest
}) {
  const [inputType, setInputType] = React.useState(type);

  const changeInputType = () => {
    // eslint-disable-next-line no-unused-expressions
    inputType === 'password' ? setInputType('text') : setInputType('password');
  };

  return (
    <>
      <div className={styles.inputWrapper}>
        {label && (
          <label className={cn(styles.label, { [styles.invalidLabel]: isInvalid })}>{label}</label>
        )}

        <input
          disabled={disabled}
          className={cn(styles.input, { [styles.invalidInput]: isInvalid })}
          placeholder={placeholder}
          type={inputType}
          {...rest}
        />
        {type === 'password' &&
          (inputType === 'password' ? (
            <EyeIconOn className={styles.eyeIcon} onClick={() => changeInputType()} />
          ) : (
            <EyeIconOff className={styles.eyeIcon} onClick={() => changeInputType()} />
          ))}
      </div>

      {isInvalid && (
        <div className={styles.error}>
          <Error />
          {isInvalid}
        </div>
      )}
    </>
  );
}

export default Input;
