import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { setFilterUsers } from '../../../features/transactions/transactions.slice';

import AvatarUser from '../../AvatarUser/AvatarUser';

import styles from './UserItem.module.scss';

// eslint-disable-next-line react/display-name
const UserItem = forwardRef(({ id, avatar, role, fullName, active }, ref) => {
  const dispatch = useDispatch();

  const handlerSelect = (id) => {
    dispatch(setFilterUsers(id));
  };

  return (
    <div
      ref={ref}
      key={id}
      style={{
        borderRadius: active ? '8px' : '',
        backgroundColor: active ? '#bfd9fe' : '',
        margin: active ? '10px' : '',
      }}
      className={styles.user__item}
      onClick={() => handlerSelect(id)}
    >
      <AvatarUser avatar={avatar} backgroundColor="grey" />
      <span className={styles.user__name}>{fullName}</span>
      <span className={styles.user__role}>{role}</span>
    </div>
  );
});

export default UserItem;
