import React from 'react';
import styles from './PaymentMethods.module.scss';
import Button from '../../UI/Button/Button';
import { ReactComponent as Plus } from '../../assets/icons/plus-icon.svg';
import Input from '../../UI/Input/Input';

function PaymentMethods() {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <div className={styles.wrapper}>
      {showForm ? (
        <>
          <div className={styles.titleWrapper}>
            <h4>Credit cards list</h4>
            <p>Enter all data to save your credit card</p>
          </div>
          <form className={styles.formWrapper}>
            <div className={styles.inputWrapper}>
              <h4>Cardholder name</h4>
              <div className={styles.fieldsWrapper}>
                <div className={styles.fieldWrapper}>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.firstname}
                    name="firstname"
                    placeholder="First name"
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.lastname}
                    name="lastname"
                    placeholder="Last name"
                  />
                </div>
              </div>
            </div>

            <div className={styles.inputWrapper}>
              <h4>Card number</h4>
              <div className={styles.fieldWrapper}>
                <Input
                  // onChange={formik.handleChange}
                  // value={formik.values.card_number}
                  name="card_number"
                  placeholder="Card number"
                />
              </div>
            </div>

            <div className={styles.inputWrapper}>
              <h4>Cardholder name</h4>
              <div className={styles.fieldsWrapper}>
                <div className={styles.fieldWrapper}>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.expire_date}
                    name="expire_date"
                    placeholder="MM/YY"
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <Input
                    // onChange={formik.handleChange}
                    // value={formik.values.cvv}
                    name="cvv"
                    placeholder="CVV"
                  />
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className={styles.addCardMessage}>
          <p>
            {`You haven't added any payment method. Add any credit card for paying
              and withdrawing money`}
          </p>
          <Button onClick={() => setShowForm(!showForm)}>
            <Plus />
            Add a Credit Card
          </Button>
        </div>
      )}
    </div>
  );
}

export default PaymentMethods;
