import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Button.module.scss';

function Button({
  children,
  /*
  theme can be:
  - default
  - bordered
  - text
  - text-dark
  - text-grey
  - red
   */
  theme = 'default',
  link,
  ...rest
}) {
  const Tag = link ? Link : 'button';
  return (
    <Tag to={link} className={cn(styles.button, { [styles[theme]]: theme })} {...rest}>
      {children}
    </Tag>
  );
}

export default Button;
