import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getRequest from '../../../api/get';

import { setReply } from '../../../features/reply/reply.slice';

import Reply from '../Reply/Reply';

// import styles from './CommentList.module.scss';

const ReplyList = ({ length, id }) => {
  const reply = useSelector((state) => state.reply.list);
  const dispatch = useDispatch();
  useEffect(() => {
    if (length && length > 0) {
      getRequest(`kudos-wall/comments/replies?page=1&commentId=${id}`).then((res) => {
        try {
          if (res && res.list) {
            dispatch(setReply(res.list));
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {length > 0 &&
        reply.map((item) => {
          return (
            <Reply
              key={item.id}
              commentId={id}
              currentComment={item.id}
              length={length}
              createdAt={item.createdAt}
              user={item.user}
              postId={item.postId}
              text={item.text}
              count={item._count}
              likedByCurrentUser={item.likedByCurrentUser}
            />
          );
        })}
    </div>
  );
};

export default ReplyList;
