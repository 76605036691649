import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import styles from '../scss/pages/Billing.module.scss';
import Button from '../UI/Button/Button';
import PaymentHistoryList from '../components/PaymentHistoryList/PaymentHistoryList';
import { ReactComponent as Arrow } from '../assets/icons/arrow-right.svg';
import {
  cancelSubscription,
  getCurrentPlan,
  getPaymentInfo,
  updatePaymentMethod,
} from '../api/billing';
import Modal from '../components/Modal/Modal';
import { hideModal, showModal } from '../features/modal/modal-slice';
import { ReactComponent as Visa } from '../assets/icons/visa.svg';
import { ReactComponent as Mastercard } from '../assets/icons/mastercard-2.svg';
import { ReactComponent as Error } from '../assets/icons/error-icon.svg';
import { ReactComponent as Minus } from '../assets/icons/minus-icon.svg';
import { getCompanyUsers } from '../api/companies';
import Input from '../UI/Input/Input';

function Billing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState('update');
  const [currentPlan, setCurrentPlan] = useState({
    name: null,
    price: null,
    interval: null,
  });
  const [paymentInfo, setPaymentInfo] = useState({ cards: [], defaultCard: null });
  const [enrolledUsers, setEnrolledUsers] = useState(0);

  useEffect(() => {
    getCompanyUsers('status=enrolled').then((res) => {
      setEnrolledUsers(res.total);
    });
    getPaymentInfo().then((res) => {
      setPaymentInfo({ ...res, defaultCard: res.cards.filter((card) => card.isDefault)[0] });
    });
    getCurrentPlan().then((res) => {
      setCurrentPlan(res);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      number: '',
      date: '',
      cvc: '',
    },
    onSubmit: (values) => {
      updatePaymentMethod({
        billingAddress: { ...paymentInfo.billingAddress },
        updateDefaultCardId: paymentInfo.defaultCard.id,
        addNewCard: {
          firstname: paymentInfo.billingAddress.firstname,
          lastname: paymentInfo.billingAddress.lastname,
          number: values.number.split(' ').join(''),
          month: Number(values.date.split('/')[0]),
          year: Number(values.date.split('/')[1]),
          cvc: values.cvc,
          isDefault: true,
        },
      });
    },
  });

  function formatExpirationDate(e) {
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d/]|^[/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );

    return e.target.value;
  }

  const formatCardNumber = (number) => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = number.replace(/[^\d]/g, '');

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(' ')
    );
  };

  const formatOnlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^\d]/g, '');

    return e.target.value;
  };

  const goToPlans = () => {
    navigate('/billing/plans');
  };

  return (
    <div className={styles.page}>
      <h2>Billing</h2>

      <div className={styles.block}>
        <div className={styles.block_section}>
          <span className={styles.subtitle}>Current subscription plan</span>
          <div className={styles.planInfo}>
            <div className={styles.planName}>
              {currentPlan?.name ? currentPlan.name : 'No Plan'}
              {/*<span className={styles.newFeatures}>new features!</span>*/}
            </div>
            <div className={styles.planPrice}>
              <div className={styles.currencySign}>$</div>
              {currentPlan?.price?.unit_amount || 0}.00 <span>per user</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button onClick={() => goToPlans()} theme="text">
              Check your plan <Arrow />
            </Button>
            <Button
              onClick={() => {
                setModal('cancel');
                dispatch(showModal());
              }}
              theme="text"
            >
              Cancel subscription
            </Button>
          </div>
        </div>

        <div className={styles.block_section}>
          <div className={styles.subtitle}>Payment information</div>
          <div className={styles.card}>
            <div className={styles.cardImage}>
              {paymentInfo.defaultCard?.brand === 'visa' ? <Visa /> : <Mastercard />}
            </div>
            <div className={styles.cardDetails}>
              <div className={styles.cardNumber}>
                {paymentInfo.defaultCard?.brand === 'visa' ? 'Visa' : 'Mastercard'} **** **** ****
                {paymentInfo.defaultCard?.last4}
              </div>
              <div className={styles.expireDate}>
                Expires {paymentInfo.defaultCard?.exp_month}/{paymentInfo.defaultCard?.exp_year}
              </div>
            </div>
          </div>
          <div className={styles.updatePaymentButton}>
            <Button
              onClick={() => {
                setModal('update');
                dispatch(showModal());
              }}
              theme="bordered"
            >
              Update payment method
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.flex}>
          <div className={styles.total}>
            <div className={styles.totalTime}>
              {currentPlan?.price?.interval === 'month' ? 'Monthly' : 'Annually'} subscription:
            </div>
            <span>
              ${currentPlan?.price?.unit_amount || 0}.00 x {enrolledUsers} users
            </span>
          </div>
          <span className={styles.totalPrice}>
            ${((currentPlan?.price && currentPlan.price.unit_amount) || 0) * enrolledUsers}
          </span>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <h3>Payment History</h3>

        <PaymentHistoryList />
      </div>

      <Modal aside={modal === 'update'}>
        {modal === 'cancel' && (
          <>
            <div className={styles.cancelModalHeaderIcon}>
              <div className={styles.iconWrapper}>
                <Error />
              </div>
            </div>
            <h3 className={styles.cancelModalHeader}>Cancel subscription</h3>
            <p className={styles.cancelModalDescription}>
              Are you sure you want to cancel your subscription?
            </p>

            <div className={styles.modalButtons}>
              <Button theme="bordered" onClick={() => dispatch(hideModal())}>
                Cancel
              </Button>
              <Button theme="red" onClick={cancelSubscription}>
                <Minus /> Unsubscribe
              </Button>
            </div>
          </>
        )}

        {modal === 'update' && (
          <>
            <h3 className={styles.updateModalHeader}>Add another card</h3>
            <form className={styles.paymentMethodForm} onSubmit={formik.handleSubmit}>
              <Input
                label="Card Number"
                name="number"
                placeholder="xxxx xxxx xxxx xxxx"
                value={formik.values.number}
                maxLength={19}
                onChange={(e) => formik.setFieldValue('number', formatCardNumber(e.target.value))}
              />
              <div className={styles.cardDetailsBottom}>
                <Input
                  label="Expire Date"
                  name="date"
                  placeholder="MM/YY"
                  maxLength={5}
                  onChange={(e) => {
                    formik.setFieldValue('date', formatExpirationDate(e));
                  }}
                />
                <Input
                  label="CVV Code"
                  name="cvc"
                  placeholder="CVV"
                  maxLength={3}
                  onChange={(e) => {
                    formik.setFieldValue('cvc', formatOnlyNumbers(e));
                  }}
                />
              </div>

              <Button type="submit">Update Payment Method</Button>
            </form>
          </>
        )}
      </Modal>

      <Outlet />
    </div>
  );
}

export default Billing;
