import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setFilterHashtags } from '../../../features/hashtags/hashtags.slice';

import hashtagIcon from '../../../assets/images/hashtagIcon.svg';

import styles from './Hashtags.module.scss';

const Hashtags = ({ id, value, hashtagsLength, hashtagsArraySelect }) => {
  const dispatch = useDispatch();
  const [select, setSelect] = useState(false);

  useEffect(() => {
    if (hashtagsLength === 0) {
      setSelect(false);
    }
    if (hashtagsLength > 0) {
      const findItem = hashtagsArraySelect.find((item) => item === id);
      if (!findItem) {
        setSelect(false);
      }
    }
  }, [hashtagsLength]);

  const handlerSelect = () => {
    // if (hashtagsLength && hashtagsLength > 3) {
    //   return;
    // }
    // const findItem = hashtagsArraySelect.find((item) => item === id);
    // if (hashtagsLength === 3 && findItem) {
    //   setSelect(!select);
    // } else if (hashtagsLength <= 2) {
    //   setSelect(!select);
    // }
    dispatch(setFilterHashtags(id));
    setSelect(!select);
  };
  return (
    <div
      className={styles.hashtags__wrapper}
      onClick={() => handlerSelect()}
      style={{
        border: select ? '1px solid #0066FB' : '1px solid #eaecf0',
        color: select ? '#0066FB' : '#344054',
      }}
      value={value}
    >
      <img src={hashtagIcon} alt="hashtag icon" />
      {value}
    </div>
  );
};

export default Hashtags;
