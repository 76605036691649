import React, { forwardRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import styles from './PaymentHistoryList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { toggleCompanyUserSelected } from '../../features/company/company.slice';
import { ReactComponent as Visa } from '../../assets/icons/visa.svg';
import { ReactComponent as Mastercard } from '../../assets/icons/mastercard-2.svg';

// eslint-disable-next-line react/display-name
const PaymentHistoryListItem = forwardRef(({ historyItem }, ref) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const formattedTime = format(new Date(historyItem?.createdAt), 'MMM dd, yyyy hh:mm aaa');

  const toggleCheckbox = () => {
    dispatch(toggleCompanyUserSelected(user?.id));
  };

  return (
    <li ref={ref} className={cn(styles.row, { [styles.rowSelected]: user?.selected })}>
      <div>
        <Checkbox onChange={() => toggleCheckbox()} />
      </div>

      <div className={cn(styles.col, styles.light)}>{formattedTime}</div>

      <div className={styles.col}>
        <div
          className={styles.userImage}
          style={{ background: user?.avatar && `url(${user?.avatar}) no-repeat center / cover` }}
        ></div>
        {user.firstname} {user.lastname}
      </div>

      <div className={cn(styles.col, styles.cardNumber)}>
        {historyItem.paymentMethod.brand === 'visa' ? <Visa /> : <Mastercard />}
        {historyItem.paymentMethod.last4}
      </div>

      <div className={styles.col}>
        <span>{historyItem.amount}</span>
      </div>

      <div className={styles.col}>{historyItem.status}</div>

      <div className={cn(styles.col, styles.invoiceLink)}>
        <a href={historyItem.invoicePdf}>Download invoice</a>
      </div>
    </li>
  );
});

export default PaymentHistoryListItem;
