import {
  endOfYesterday,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfISOWeek,
  endOfISOWeek,
  startOfYear,
  endOfYear,
  getYear,
} from 'date-fns';

const time = new Date();
const thisYear = getYear(time);

const dataTransactions = [
  {
    id: 1,
    label: 'Today',
    start: endOfDay(time),
    end: endOfDay(time),
  },
  {
    id: 2,
    label: 'Yesterday',
    start: endOfYesterday(time),
    end: endOfYesterday(time),
  },
  {
    id: 3,
    label: 'This Week',
    start: startOfISOWeek(time),
    end: endOfISOWeek(time),
  },
  {
    id: 4,
    label: 'This Month',
    start: startOfMonth(time),
    end: endOfMonth(time),
  },
  {
    id: 5,
    label: 'This Year',
    start: startOfYear(time),
    end: endOfYear(time),
  },
  {
    id: 6,
    label: 'Last Year',
    start: startOfYear(new Date(`${thisYear - 1}-01-01`)),
    end: endOfYear(new Date(`${thisYear - 1}-01-01`)),
  },
  {
    id: 7,
    label: 'Custom',
  },
  {
    id: 8,
    label: 'All Time',
  },
];

const posts = [
  {
    id: '6b44dca8-2c46-4255-a3e5-c924e0a10a3b',
    text: 'asdasd',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T17:29:31.106Z',
    updatedAt: '2022-11-28T17:29:31.106Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'a6efba5c-d876-4f38-806d-09bfb4fe01ee',
        value: 'test1',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'fa6e39e4-faf5-42e1-a497-ea4384c25de6',
    text: 'qweqe',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T16:28:20.862Z',
    updatedAt: '2022-11-28T16:28:20.862Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: '449ede53-b0eb-4b52-b310-ffd193dbd813',
        value: 'test4',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'b3f6df0a-9e10-4661-a9e8-a13b07a595a0',
    text: 'wqe',
    file: null,
    btcAmount: 25,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T16:24:23.577Z',
    updatedAt: '2022-11-28T16:24:23.577Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '679cd3b1-3c65-4ac5-ba50-082af8352542',
    text: 'qweq',
    file: null,
    btcAmount: 25,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T16:20:29.578Z',
    updatedAt: '2022-11-28T16:20:29.578Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '7ce01a72-f57b-4598-9b4d-cb9f142dc5f9',
    text: 'qqwqqqqqq',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:45:43.022Z',
    updatedAt: '2022-11-28T14:45:43.022Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'b3710e1c-2085-4558-ad72-3c401db40025',
        value: 'test7',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '052890b8-7a18-43de-876c-6b38ad44a7ab',
    text: 'qwdqd',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:45:35.280Z',
    updatedAt: '2022-11-28T14:45:35.280Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: '801bc2f4-b423-4f2d-888d-253f9b29b502',
        value: 'test6',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '63361a63-fcac-433a-993d-a112d7696f72',
    text: 'wqeq',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:45:28.571Z',
    updatedAt: '2022-11-28T14:45:28.571Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: '578654b8-d34e-4da7-a79d-d6a3eb5d56ff',
        value: 'reliability',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'd14f5491-dc0e-49a1-89f4-b31f38c53f1a',
    text: 'dads',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:44:42.488Z',
    updatedAt: '2022-11-28T14:44:42.488Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: '449ede53-b0eb-4b52-b310-ffd193dbd813',
        value: 'test4',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '99867a68-af77-44d4-af91-96f89a6314aa',
    text: 'jjj',
    file: null,
    btcAmount: 0,
    createdByUserId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    recognizedUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:43:20.464Z',
    updatedAt: '2022-11-28T14:43:20.464Z',
    createdBy: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
      email: 'myhaylo.yanenko+9@wtt-solutions.com',
      dateOfBirth: '2005-11-07T22:00:00.000Z',
      startOfWork: '2022-10-29T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    recognizedUser: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    hashtags: [
      {
        id: '449ede53-b0eb-4b52-b310-ffd193dbd813',
        value: 'test4',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '578712d5-978b-4348-8afe-22e7e9204d42',
    text: 'wefwe',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-28T14:42:41.649Z',
    updatedAt: '2022-11-28T14:42:41.649Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'e56916f2-678d-4f13-9176-706d6a4840a4',
        value: 'nn,hh',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'ab5a6460-36e4-479f-969a-cf1389430da8',
    text: 'www',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-23T08:46:21.295Z',
    updatedAt: '2022-11-23T08:46:21.295Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'd337947d-50d1-41e1-87df-8f29e69832ea',
        value: 'status',
      },
    ],
    _count: {
      usersLiked: 2,
      comments: 0,
      addOns: 0,
      allReactions: 2,
    },
    likedByCurrentUser: true,
    addedOnByCurrentUser: false,
    firstUsersReacted: [
      {
        type: 'like',
        userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
        firstname: 'wwwwww',
        lastname: 'wwwwww',
        avatar: null,
        reactedAt: '2022-11-23T16:50:38.266Z',
      },
      {
        type: 'like',
        userId: 'ab671823-d270-4009-89d2-e981c4c4039a',
        firstname: 'myhaylo',
        lastname: 'qwer',
        avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
        reactedAt: '2022-11-23T17:31:54.250Z',
      },
    ],
  },
  {
    id: '0e1ab261-b664-4861-a4b1-9370e3e4ab73',
    text: 'dddd',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-23T08:44:42.289Z',
    updatedAt: '2022-11-23T08:44:42.289Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'd337947d-50d1-41e1-87df-8f29e69832ea',
        value: 'status',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'a25a2980-02e4-4188-85f1-674ff8355da9',
    text: 'dddd',
    file: null,
    btcAmount: 0,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-23T08:44:28.761Z',
    updatedAt: '2022-11-23T08:44:28.761Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'd337947d-50d1-41e1-87df-8f29e69832ea',
        value: 'status',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: '3730ccfc-3ffd-42be-8647-7bbb9fd29782',
    text: 'regeg',
    file: null,
    btcAmount: 25,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-22T23:54:25.265Z',
    updatedAt: '2022-11-22T23:54:25.265Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: '578654b8-d34e-4da7-a79d-d6a3eb5d56ff',
        value: 'reliability',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
  {
    id: 'f83de23b-fa25-4fa1-9f04-63919424608b',
    text: 'hello everyone',
    file: 'https://crypto-img.s3.amazonaws.com/39e26641-f104-40b8-9000-3243b749b72f-7F5y.gif',
    btcAmount: 25,
    createdByUserId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    recognizedUserId: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
    isAnonymous: false,
    companyId: 'b0bdd002-ab8a-41c9-99b3-542549add168',
    createdAt: '2022-11-22T23:53:39.023Z',
    updatedAt: '2022-11-22T23:53:39.023Z',
    createdBy: {
      id: 'ab671823-d270-4009-89d2-e981c4c4039a',
      firstname: 'myhaylo',
      lastname: 'qwer',
      avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
      email: 'myhaylo.yanenko+1@wtt-solutions.com',
      dateOfBirth: '2022-10-04T21:00:00.000Z',
      startOfWork: '2022-09-27T21:00:00.000Z',
      role: 'Admin',
      recipientKudos: [],
    },
    recognizedUser: {
      id: 'fba01520-588b-498a-b2ea-042e8a7c6ee8',
      firstname: 'rest',
      lastname: 'api',
      avatar: null,
      email: 'myhaylo.yanenko+5@wtt-solutions.com',
      dateOfBirth: '2022-09-26T21:00:00.000Z',
      startOfWork: '2022-08-30T21:00:00.000Z',
      role: 'Employee',
      recipientKudos: [
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
        {
          sender: {
            id: 'ab671823-d270-4009-89d2-e981c4c4039a',
            avatar:
              'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
            firstname: 'myhaylo',
            lastname: 'qwer',
          },
          amount: 25,
        },
      ],
    },
    hashtags: [
      {
        id: 'a6efba5c-d876-4f38-806d-09bfb4fe01ee',
        value: 'test1',
      },
      {
        id: '449ede53-b0eb-4b52-b310-ffd193dbd813',
        value: 'test4',
      },
      {
        id: 'cb8b1538-5020-4be1-9602-b1d594c51f78',
        value: 'test3',
      },
    ],
    _count: {
      usersLiked: 0,
      comments: 0,
      addOns: 0,
      allReactions: 0,
    },
    likedByCurrentUser: false,
    addedOnByCurrentUser: false,
    firstUsersReacted: [],
  },
];

const userReaction = [
  {
    type: 'like',
    userId: 'ab671823-d270-4009-89d2-e981c4c4039a',
    firstname: 'myhaylo',
    lastname: 'qwer',
    avatar: 'https://crypto-img.s3.amazonaws.com/3efafab6-e783-40b0-997b-50dd74d415d4-7F5y.gif',
    reactedAt: '2022-11-23T17:31:54.250Z',
  },
  {
    type: 'like',
    userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    firstname: 'wwwwww',
    lastname: 'wwwwww',
    avatar: null,
    reactedAt: '2022-11-23T16:50:38.266Z',
  },
];

const comments = [
  {
    id: 'f5cd925c-d46e-495d-8909-d3c46319720d',
    text: 'adsasdasdadasdasdasdasdasd',
    postId: 'ab5a6460-36e4-479f-969a-cf1389430da8',
    userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    replyToCommentId: null,
    createdAt: '2022-11-29T23:29:10.901Z',
    updatedAt: '2022-11-29T23:29:10.901Z',
    user: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
    },
    _count: {
      replies: 0,
    },
  },
  {
    id: 'f69031bc-fb0c-4c7f-a6e3-2966e6d592ca',
    text: 'hello man',
    postId: 'ab5a6460-36e4-479f-969a-cf1389430da8',
    userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    replyToCommentId: null,
    createdAt: '2022-11-29T23:28:00.822Z',
    updatedAt: '2022-11-29T23:28:00.822Z',
    user: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
    },
    _count: {
      replies: 1,
    },
  },
  {
    id: 'fccc36ff-5265-459d-b787-67e00d0899b5',
    text: 'qweqweqweqwedqweq',
    postId: 'ab5a6460-36e4-479f-969a-cf1389430da8',
    userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    replyToCommentId: null,
    createdAt: '2022-11-29T23:29:07.191Z',
    updatedAt: '2022-11-29T23:29:07.191Z',
    user: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
    },
    _count: {
      replies: 0,
    },
  },
];

const replies = [
  {
    id: 'ec784efc-3014-4306-bcee-a07b5c3484ea',
    text: 'hello',
    postId: 'ab5a6460-36e4-479f-969a-cf1389430da8',
    userId: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
    replyToCommentId: 'f69031bc-fb0c-4c7f-a6e3-2966e6d592ca',
    createdAt: '2022-11-29T23:28:28.187Z',
    updatedAt: '2022-11-29T23:28:28.187Z',
    user: {
      id: '20a18f44-e2aa-4b57-be52-5ede8c5f6b99',
      firstname: 'wwwwww',
      lastname: 'wwwwww',
      avatar: null,
    },
    _count: {
      replies: 3,
    },
  },
];

const filterDataPost = [
  {
    id: 'f5cd925c-d46e-495d-8909-d3c46319720d',
    label: 'Most Recent',
    value: 'mostRecent=true',
  },
  {
    id: 'f5cd925c-d46e-495d-8909-d3c463197233',
    label: 'Top Post',
    value: 'mostAddOns=true',
  },
];

export { userReaction, comments, posts, replies, filterDataPost, dataTransactions };
