import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const anniversariesSlice = createSlice({
  name: 'anniversaries',
  initialState,
  reducers: {
    setAnniversaries(state, action) {
      state.list = action.payload;
    },
    clearAnniversaries(state) {
      state.list = [];
    },

    updateAnniversaries(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },

    updateAnniversariesItem(state, action) {
      const { type, index } = action.payload;
      state.list[index] = type;
    },
  },
});

export const {
  setAnniversaries,
  clearAnniversaries,
  updateAnniversaries,
  updateAnniversariesItem,
} = anniversariesSlice.actions;
export default anniversariesSlice.reducer;
