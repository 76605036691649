import { API_BASE } from './constants';

const getRequest = async (url) => {
  const token = localStorage.getItem('user');
  const response = await fetch(`${API_BASE}${url}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();

  return json;
};

export default getRequest;
