import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: 'kudos',
  filter: {
    sortBy: 'DateTime',
    orderDesc: true,
    from: '',
    to: '',
    userIds: [],
  },
  kudos: {
    list: [],
    numberOfSelected: 0,
  },
  addons: {
    list: [],
    numberOfSelected: 0,
  },
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsKudos(state, action) {
      const kudosIds = state.kudos.list.map((item) => item.id);
      const filterItem = action.payload.filter((item) => !kudosIds.includes(item.id));
      if (filterItem && filterItem.length > 0) {
        state.kudos.list = [
          ...state.kudos.list,
          ...filterItem.map((kudo) => {
            return { ...kudo, fullName: `${kudo.firstname} ${kudo.lastname}`, selected: false };
          }),
        ];
      }
    },

    setTransactionsAddons(state, action) {
      const addonsIds = state.addons.list.map((item) => item.id);
      const filterItem = action.payload.filter((item) => !addonsIds.includes(item.id));
      if (filterItem && filterItem.length > 0) {
        state.addons.list = [
          ...state.addons.list,
          ...filterItem.map((addon) => {
            return { ...addon, fullName: `${addon.firstname} ${addon.lastname}`, selected: false };
          }),
        ];
      }
    },
    clearTransactionsKudos(state) {
      state.kudos.list = [];
      state.kudos.numberOfSelected = 0;
    },

    clearTransactionsAddons(state) {
      state.addons.list = [];
      state.addons.numberOfSelected = 0;
    },

    toggleTransactionKudosSelected(state, action) {
      const isUserSelected = state.kudos.list.find((cudo) => cudo.id === action.payload).selected;
      if (!isUserSelected) {
        state.kudos.numberOfSelected += 1;
      } else {
        state.kudos.numberOfSelected -= 1;
      }
      state.kudos.list.find((cudo) => cudo.id === action.payload).selected = !isUserSelected;
    },

    toggleTransactionAddonsSelected(state, action) {
      const isUserSelected = state.addons.list.find(
        (addon) => addon.id === action.payload
      ).selected;
      if (!isUserSelected) {
        state.addons.numberOfSelected += 1;
      } else {
        state.addons.numberOfSelected -= 1;
      }
      state.addons.list.find((addon) => addon.id === action.payload).selected = !isUserSelected;
    },
    setAllTransactionsKudosSelected(state, action) {
      state.kudos.list = state.kudos.list.map((cudo) => {
        return { ...cudo, selected: action.payload };
      });
      if (action.payload) {
        state.kudos.numberOfSelected = state.kudos.list.length;
      } else {
        state.kudos.numberOfSelected = 0;
      }
    },
    setAllTransactionsAddonsSelected(state, action) {
      state.addons.list = state.addons.list.map((addon) => {
        return { ...addon, selected: action.payload };
      });
      if (action.payload) {
        state.addons.numberOfSelected = state.addons.list.length;
      } else {
        state.addons.numberOfSelected = 0;
      }
    },
    setTypeTable(state, action) {
      state.type = action.payload;
    },

    setFilterRange(state, action) {
      const { from, to } = action.payload;
      state.filter.from = from;
      state.filter.to = to;
    },

    clearFilterRange(state) {
      state.filter.from = '';
      state.filter.to = '';
    },

    setFilter(state, action) {
      state.filter = action.payload;
    },
    clearFilter(state) {
      state.filter.sortBy = 'DateTime';
      state.filter.orderDesc = true;
      state.filter.from = '';
      state.filter.to = '';
      state.filter.userIds = [];
    },
    setFilterUsers(state, action) {
      state.filter.userIds = [action.payload];
    },
    setFilterSortBy(state, action) {
      state.filter.orderDesc = !state.filter.orderDesc;
      state.filter.sortBy = action.payload;
    },
    setFilterSortByRecent(state, action) {
      const { sortBy, orderDesc } = action.payload;
      state.filter.orderDesc = orderDesc;
      state.filter.sortBy = sortBy;
    },
  },
});

export const {
  setTransactionsKudos,
  setTransactionsAddons,
  toggleTransactionKudosSelected,
  toggleTransactionAddonsSelected,
  setAllTransactionsKudosSelected,
  setAllTransactionsAddonsSelected,
  setTypeTable,
  setFilterUsers,
  setFilterSortBy,
  setFilterSortByRecent,
  setFilter,
  clearFilter,
  clearFilterRange,
  setFilterRange,
  clearTransactionsKudos,
  clearTransactionsAddons,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
