import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './BillingPlans.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as Users } from '../../assets/icons/billing-users.svg';
import { ReactComponent as Engagement } from '../../assets/icons/billing-engagement.svg';
import { ReactComponent as Analytics } from '../../assets/icons/billing-analytics.svg';
import { ReactComponent as Perks } from '../../assets/icons/billing-perks.svg';
import { ReactComponent as Integrations } from '../../assets/icons/billing-integrations.svg';
import { ReactComponent as Checkmark } from '../../assets/icons/billing-checkmark.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import Button from '../../UI/Button/Button';
import { checkoutSession, getCurrentPlan, getPlansInfo } from '../../api/billing';
import { getCompanyUsers } from '../../api/companies';

function BillingPlans() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [usersTotal, setUsersTotal] = useState([]);
  const [plans, setPlans] = useState([]);
  const [pickedPeriod, setPickedPeriod] = useState('month');
  const [currentPlan, setCurrentPlan] = useState({
    name: null,
    price: null,
    interval: null,
  });

  const goToCheckout = () => {
    checkoutSession({
      users: usersTotal,
      priceId: plans[0]?.prices.filter((price) => price.interval === pickedPeriod)[0].id,
    })
      .then((res) => {
        window.location.replace(res.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getPlansInfo().then((res) => {
      setPlans(res);
    });
    getCompanyUsers('status=enrolled').then((res) => {
      setUsersTotal(res.total);
    });
    getCurrentPlan().then((res) => {
      setIsLoading(false);
      setCurrentPlan(res);
    });
  }, []);

  return (
    <div className={styles.page}>
      <h2>
        <Arrow onClick={() => navigate('/billing')} />
        Billing Plans
      </h2>

      <div className={styles.content}>
        <div className={cn(styles.row, styles.firstRow)}>
          <div className={styles.featureHeader}>Features and Benefits</div>
          <div className={styles.presenceHeader}>
            <div className={styles.title}>Universal Plan</div>
            <div className={styles.periodTabs}>
              <div
                className={cn(styles.tab, { [styles.tab_active]: pickedPeriod === 'month' })}
                onClick={() => setPickedPeriod('month')}
              >
                Monthly
              </div>
              <div
                className={cn(styles.tab, { [styles.tab_active]: pickedPeriod === 'year' })}
                onClick={() => setPickedPeriod('year')}
              >
                Annually <span className={styles.discount}>(-20%)</span>
              </div>
            </div>
            <div className={styles.price}>
              <div className={styles.currencySign}>$</div>
              {plans[0]?.prices.filter((price) => price.interval === pickedPeriod)[0].unit_amount ||
                0}
              .00
              <span>per user</span>
            </div>
            <div className={cn(styles.buttonWrapper, styles.buttonWrapper_top)}>
              {!isLoading && currentPlan?.name ? (
                <div className={styles.purchasedPlan}>Your plan</div>
              ) : (
                <Button disabled={isLoading} onClick={goToCheckout}>
                  Purchase <ArrowUp />
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.group}>
          <Users />
          Users
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Number of Admins</div>
          <div className={styles.presence}>10</div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Number of Managers and Employees</div>
          <div className={styles.presence}>Unlimited</div>
        </div>

        <div className={styles.group}>
          <Engagement />
          Engagement Programs
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Kudos (Employee Recognition)</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>

        <div className={styles.group}>
          <Analytics />
          Analytics and Reporting
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Goal progress-tracking and analytics</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Survey visualizations and analytics</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>

        <div className={styles.group}>
          <Perks />
          Perks
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Bitcoin</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Litecoin</div>
          <div className={styles.presence}>Coming Soon</div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Dogecoin</div>
          <div className={styles.presence}>Coming Soon</div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>USD</div>
          <div className={styles.presence}>Coming Soon</div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Ethereum</div>
          <div className={styles.presence}>Coming Soon</div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>NFTs</div>
          <div className={styles.presence}>Coming Soon</div>
        </div>

        <div className={styles.group}>
          <Integrations />
          Integrations
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>HRIS</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Commication</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}>Single Sign-On (SSO)</div>
          <div className={styles.presence}>
            <div className={styles.svgWrapper}>
              <Checkmark />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.feature}></div>
          <div className={styles.presence}>
            <div className={cn(styles.buttonWrapper, styles.buttonWrapper_top)}>
              {!isLoading && currentPlan?.name ? (
                <div className={styles.purchasedPlan}>Your plan</div>
              ) : (
                <Button disabled={isLoading} onClick={goToCheckout}>
                  Purchase <ArrowUp />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingPlans;
