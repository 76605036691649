import React from 'react';

import comment from '../../../assets/images/comment.svg';
import replies from '../../../assets/images/replies.svg';

import styles from './CommentInfo.module.scss';

const CommentInfo = ({ length, type }) => {
  const getText = () => {
    if (type === 'post') {
      return `${length} ${length === 1 ? 'comment' : 'comments'}`;
    }
    return `${length} ${length === 1 ? 'reply' : 'replies'}`;
  };

  return (
    <div className={styles.comment__wrapper}>
      <img src={type === 'post' ? comment : replies} alt="comment icon" />
      <span className={styles.comment__content}>{getText()}</span>
    </div>
  );
};

export default CommentInfo;
