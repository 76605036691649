import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './LoginForm.module.scss';
import logo from '../../assets/images/logo.svg';
import Input from '../../UI/Input/Input';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import googleLogo from '../../assets/icons/google-logo.svg';
import { loginSchema as validationSchema } from './LoginForm.schema';
import { login, loginUserFromRedirect, loginWithGoogle } from '../../api/login';
import { setLoggedIn } from '../../features/auth/auth-slice';
import { getUser } from '../../api/users';

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      remember: false,
    },
    validationSchema,
    onSubmit: (values) => {
      login('auth/login', values).then(() => {
        getUser().then((res) => {
          if (!res.firstname) {
            navigate(`/auth/signup?email=${encodeURIComponent(res.email)}`);
          } else if (!res.addresses.length && res.firstname) {
            navigate('/auth/signup?step=2');
          } else if (!res.isEnrolled && res.addresses.length > 0) {
            navigate('/auth/signup?step=3');
          } else {
            localStorage.setItem('userRole', `${res.role}`);
            dispatch(setLoggedIn());
            navigate('/');
          }
        });
      });
    },
  });

  const toggleCheckbox = (e) => {
    formik.setFieldValue(`${e.target.name}`, !e.target.name);
  };

  useEffect(() => {
    loginUserFromRedirect().then(() => {
      getUser().then((res) => {
        localStorage.setItem('userRole', `${res.role}`);
        dispatch(setLoggedIn());
        navigate('/');
      });
    });
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} alt="" />
        <h2>Log in to your account</h2>
        <p>Welcome back! Please enter your details</p>
      </div>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <Input
            name="email"
            label="Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.email && formik.errors.email}
            placeholder="Enter your email"
          />
        </div>
        <div className={styles.formItem}>
          <Checkbox
            name="remember"
            onChange={formik.handleChange}
            onClick={toggleCheckbox}
            isInvalid={formik.touched.remember && formik.errors.remember}
            checked={formik.values.accept_terms}
          >
            Remember me for 30 days
          </Checkbox>
        </div>
        <Button type="submit">Continue</Button>
      </form>

      <div className={styles.googleButton}>
        <Button theme="bordered" onClick={() => loginWithGoogle()}>
          <img src={googleLogo} alt="google logo" />
          Sign up with Google
        </Button>
      </div>
      <p className={styles.haveAccount}>
        Don't have an account?{' '}
        <Button theme="text" link="/auth/signup">
          Sign up
        </Button>
      </p>
    </div>
  );
}

export default LoginForm;
