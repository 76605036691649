import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactSortable } from 'react-sortablejs';
import styles from './CreateWallet.module.scss';
import Button from '../../../UI/Button/Button';
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow-collapse-aside.svg';
import {
  shuffleArray,
  sortableArrayFromString,
  sortableArrayToString,
} from '../../../helpers/wallet.helpers';
import { walletPhrase } from '../../../api/users';
import Spinner from '../../../UI/Spinner/Spinner';

function CreateWallet({ setStep, currentStep, showQuiz, setShowQuiz }) {
  const placeholderWords = Array(12).fill(null);
  const [originalPhrase, setOriginalPhrase] = useState(null);

  const [quizWords, setQuizWords] = React.useState(null);
  const [answerWords, setAnswerWords] = useState([]);
  const [showWords, setShowWords] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    walletPhrase().then((res) => {
      setOriginalPhrase(res.mnemonic);
      setQuizWords(shuffleArray(sortableArrayFromString(res.mnemonic)));
      setLoading(false);
    });
  }, []);

  const comparePhrases = () => {
    if (originalPhrase === sortableArrayToString(answerWords)) {
      setStep(currentStep + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.downloadMessage}>
        {showQuiz
          ? 'Please select each phrase in order to make sure it is correct.'
          : 'Please carefully write down these words or'}
        {!showQuiz && (
          <>
            <br />
            <Button theme="text">Download them</Button>
          </>
        )}
      </p>
      <div className={styles.secretWordsWrapper}>
        {!showWords && (
          <div role="presentation" className={styles.blur} onClick={() => setShowWords(true)}>
            <div className={styles.lockWrapper}>
              <Lock />
            </div>
            <p className={styles.clickHere}>Click here to reveal secret words</p>
          </div>
        )}
        {showQuiz && (
          <ReactSortable
            tag="ul"
            group="quiz"
            animation={200}
            list={answerWords}
            setList={setAnswerWords}
            className={cn(styles.wordsList, styles.quizField, {
              [styles.wordsListHidden]: !showWords,
            })}
            chosenClass={styles.sortableChosen}
            ghostClass={styles.sortableGhost}
          >
            {answerWords.map((word) => (
              <li key={word.id}>{word.name}</li>
            ))}
          </ReactSortable>
        )}

        {showWords ? (
          showQuiz ? (
            <ReactSortable
              tag="ul"
              group="quiz"
              list={quizWords}
              setList={setQuizWords}
              className={cn(styles.wordsList, {
                [styles.wordsListHidden]: !showWords,
              })}
              chosenClass={styles.sortableChosen}
              ghostClass={styles.sortableGhost}
            >
              {quizWords.map((word) => (
                <li key={word.id}>{word.name}</li>
              ))}
            </ReactSortable>
          ) : (
            <ul
              className={cn(styles.wordsList, {
                [styles.wordsListHidden]: !showWords,
              })}
            >
              {loading ? (
                <Spinner />
              ) : (
                originalPhrase.split(' ').map((word, index) => <li key={index}>{word}</li>)
              )}
              {/*{originalPhrase.split(' ').map((word, index) => (*/}
              {/*  <li key={index}>{word}</li>*/}
              {/*))}*/}
            </ul>
          )
        ) : (
          <ul
            className={cn(styles.wordsList, {
              [styles.wordsListHidden]: !showWords,
            })}
          >
            {placeholderWords.map((word, index) => (
              <li key={index}>word{index + 1}</li>
            ))}
          </ul>
        )}
      </div>

      {showWords &&
        (showQuiz ? (
          <div className={styles.buttonsWrapper}>
            <Button theme="bordered" onClick={() => setStep(currentStep - 1)}>
              Back
            </Button>
            <Button onClick={() => comparePhrases()}>Continue</Button>
          </div>
        ) : (
          <>
            <p className={styles.info}>You will confirm this phrase on the next step</p>
            <div className={styles.buttonsWrapper}>
              <Button theme="bordered" onClick={() => setStep(currentStep - 1)}>
                Back
              </Button>
              <Button onClick={() => setShowQuiz(true)}>{`I've written it down`}</Button>
            </div>
          </>
        ))}
      {!showWords && (
        <div className={styles.backButton}>
          <Button onClick={() => setStep(currentStep - 1)} theme="text">
            <Arrow />
            Back
          </Button>
        </div>
      )}
    </div>
  );
}

export default CreateWallet;
