import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './PaymentHistoryList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import PaymentHistoryListItem from './PaymentHistoryListItem';
import { getPaymentHistory } from '../../api/billing';
import {
  clearHistory,
  setHistoryNextPage,
  setPaymentHistory,
} from '../../features/billing/billing.slice';
import usePaymentHistoryInfiniteScroll from '../../hooks/usePaymentHistoryInfiniteScroll';

function PaymentHistoryList() {
  const paymentHistory = useSelector((state) => state.history.history);

  const [pageNumber, setPageNumber] = useState('');

  const { loading, hasMore } = usePaymentHistoryInfiniteScroll(
    pageNumber,
    getPaymentHistory,
    setPaymentHistory,
    setHistoryNextPage,
    paymentHistory.list.length,
    '',
    clearHistory
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(paymentHistory?.nextPage);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox />
        </div>
        <div className={styles.col}>Date & Time</div>
        <div className={styles.col}>Sender</div>
        <div className={styles.col}>Payment Method</div>
        <div className={styles.col}>Amount</div>
        <div className={styles.col}>Status</div>
        <div className={styles.col}></div>
      </li>

      {paymentHistory.list.map((historyItem, index) => {
        if (paymentHistory.list.length === index + 1) {
          return (
            <PaymentHistoryListItem ref={lastElementRef} historyItem={historyItem} key={index} />
          );
        }
        return <PaymentHistoryListItem historyItem={historyItem} key={index} />;
      })}
    </ul>
  );
}

export default PaymentHistoryList;
