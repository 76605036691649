import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const bitcoinSlice = createSlice({
  name: 'bitcoin',
  initialState,
  reducers: {
    setBitcoin(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setBitcoin } = bitcoinSlice.actions;
export default bitcoinSlice.reducer;
