import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import useClipboard from 'react-use-clipboard';
import { useDispatch } from 'react-redux';
import styles from './ClientsList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { ReactComponent as CaseIcon } from '../../assets/icons/case-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { ReactComponent as Kebab } from '../../assets/icons/kebab-icon.svg';
import { ReactComponent as Checkmark } from '../../assets/icons/step-checkmark.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { showModal } from '../../features/modal/modal-slice';
import { getUserInfo } from '../../api/users';
import { toggleClientSelected } from '../../features/clients/clients.slice';

// eslint-disable-next-line react/display-name
const ClientsListItem = forwardRef(({ user, setUserInfo, setUserInfoModal }, ref) => {
  const [isCopied, setCopied] = useClipboard(user.addresses[0], { successDuration: 2000 });
  const [showKebab, setShowKebab] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowKebab(false));

  useEffect(() => {
    if (!isCopied) ReactTooltip.rebuild();
  }, [isCopied]);

  const dispatch = useDispatch();

  const toggleCheckbox = () => {
    dispatch(toggleClientSelected(user.id));
  };

  return (
    <li ref={ref} className={cn(styles.row, { [styles.rowSelected]: user.selected })}>
      <div>
        <Checkbox onChange={() => toggleCheckbox()} checked={user.selected} />
      </div>
      <div className={styles.col}>
        <div
          className={styles.userImage}
          style={{ background: user.avatar && `url(${user.avatar}) no-repeat center / cover` }}
        ></div>
        <div className={styles.name}>
          {user.firstname ? user.fullName : 'New User'}
          <span>{user.email}</span>
        </div>
      </div>

      <div className={cn(styles.col, styles.light)}>{user.role}</div>

      <div className={styles.col}>{user?.cardExpiringDate}</div>

      <div className={styles.col}>
        <div
          className={styles.userImage}
          style={{
            background: user.company?.logo && `url(${user.company?.logo}) no-repeat center / cover`,
          }}
        ></div>
        <div className={styles.name}>{user?.company?.name}</div>
      </div>

      <div className={styles.col}>{user?.company?.size}</div>

      <div className={cn(styles.col, styles.walletNumberWrapper)}>
        {user.role === 'Admin' ? <CaseIcon /> : <UserIcon />}
        <span className={styles.walletNumber}>
          {user.addresses[0] &&
            `${user.addresses[0].substring(0, 4)}...${user.addresses[0].substring(
              user.addresses[0].length - 4
            )}`}
        </span>

        {isCopied
          ? user.addresses[0] && <Checkmark />
          : user.addresses[0] && (
              <CopyIcon
                data-tip="Wallet address copied"
                data-event="mouseup"
                data-for="success"
                data-delay-hide="2000"
                data-event-off="mouseleave"
                onClick={() => {
                  setCopied();
                }}
              />
            )}
      </div>
      <ReactTooltip className="customTooltip" id="success" effect="solid" place="bottom" />

      <div className={styles.col}>{user?.country}</div>

      <div
        ref={menuRef}
        className={cn(styles.col, styles.kebabMenu)}
        onClick={() => setShowKebab(!showKebab)}
      >
        <div className={cn(styles.userMenu, { [styles.userMenu_open]: showKebab })}>
          <div
            className={styles.userMenu_item}
            onClick={() => {
              setUserInfo(null);
              setUserInfoModal();
              dispatch(showModal(true));
              getUserInfo(user.id).then((res) => setUserInfo(res));
            }}
          >
            <UserIcon />
            View profile
          </div>
        </div>
        <Kebab />
      </div>
    </li>
  );
});

export default ClientsListItem;
