export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getClickableLink = (link) => {
  const isString = typeof link === 'string';
  let result = null;

  if (isString) {
    result = link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
  }

  return result;
};
