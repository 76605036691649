import React, { forwardRef, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import Checkbox from '../../../UI/Checkbox/Checkbox';
import AvatarUser from '../../AvatarUser/AvatarUser';

import { ReactComponent as Kebab } from '../../../assets/icons/kebab-icon.svg';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { toggleTransactionAddonsSelected } from '../../../features/transactions/transactions.slice';

import styles from './TransactionsAddonsTable.module.scss';

// eslint-disable-next-line react/display-name
const TransactionsAddonsItemTable = forwardRef(({ user, setUserInfo }, ref) => {
  const coins = useSelector((state) => state.bitcoin.data);
  const bitcoinValue = coins?.market_data?.current_price?.usd;
  const result = user && user.amount ? (user.amount / bitcoinValue).toFixed(4) : 0;
  const [showKebab, setShowKebab] = useState(false);
  const dateTime = format(new Date(user?.createdAt), 'MMMM dd, yyyy hh:mm aaa');

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowKebab(false));

  const dispatch = useDispatch();

  const toggleCheckbox = () => {
    dispatch(toggleTransactionAddonsSelected(user.id));
  };

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -100, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <li ref={ref} className={cn(styles.row, { [styles.rowSelected]: user.selected })}>
        <div>
          <Checkbox onChange={() => toggleCheckbox()} checked={user.selected} />
        </div>
        <div>{dateTime}</div>
        <div className={styles.col}>
          <AvatarUser size={24} avatar={user?.sender?.avatar} backgroundColor="grey" />
          <div className={styles.name}>
            {user?.sender?.firstname} {user?.sender?.lastname}
          </div>
        </div>

        <div className={styles.col}>
          <AvatarUser size={24} avatar={user?.recipient?.avatar} backgroundColor="grey" />
          <div className={styles.name}>
            {user?.recipient?.firstname} {user?.recipient?.lastname}
          </div>
        </div>

        <div className={styles.amount}>
          <span>${user?.amount}</span>
          <br />
          <span className={styles.amount__bitcoin}>{result} BTC</span>
        </div>

        <div className={styles.col}>
          <span className={styles.col__status}>{user?.status}</span>
        </div>

        <div className={cn(styles.col, styles.kebabMenu)} onClick={() => setShowKebab(!showKebab)}>
          <Kebab />
        </div>
      </li>
    </motion.div>
  );
});

export default TransactionsAddonsItemTable;
