import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action) {
      state.list = action.payload;
    },
    clearPosts(state) {
      state.list = [];
    },

    updatePosts(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },

    updatePostItem(state, action) {
      const { type, index } = action.payload;
      state.list[index] = type;
    },

    openComment(state, action) {
      state.list.filter((item) => {
        if (item.id === action.payload) {
          item.isOpen = !item.isOpen;
          return true;
        }
        item.isOpen = false;
        return false;
      });
    },

    setPostItem(state, action) {
      const { post, index } = action.payload;
      state.list[index] = post;
    },
  },
});

export const { setPosts, setPostItem, clearPosts, updatePosts, openComment, updatePostItem } =
  postsSlice.actions;
export default postsSlice.reducer;
