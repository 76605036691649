import React, { useState, forwardRef, useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';

// import { postRequest } from '../../api/post';
import { setFilterRange, clearFilterRange } from '../../features/transactions/transactions.slice';
import { dataTransactions } from '../../api/data';

import clockIcon from '../../assets/icons/clockIcon.svg';
import clockWhite from '../../assets/icons/clockWhite.svg';
import arrowDown from '../../assets/icons/arrowDown.svg';
import arrowUp from '../../assets/icons/arrowUp.svg';

import './AnalyticsFilter.scss';

const AnalyticsFilter = ({ type }) => {
  const dispatch = useDispatch();
  const typeTable = useSelector((state) => state.transactions.type);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState('All Time');
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setValue('All Time');
    dispatch(clearFilterRange());
  }, [typeTable]);

  // eslint-disable-next-line
  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ display: 'flex', padding: '0px', color: '#fff', marginBottom: '40px' }}>
        <CalendarContainer className={className}>
          <div className="qwerty" style={{ position: 'relative' }}>
            {children}
          </div>
        </CalendarContainer>
      </div>
    );
  };

  const handlerCancel = () => {
    setValue('All Time');
    setOpen(false);
    setStartDate(new Date());
    setEndDate(new Date());
    dispatch(clearFilterRange());
  };

  const handlerApply = () => {
    setValue('Custom');
    const data = {
      from: JSON.stringify(startDate),
      to: JSON.stringify(endDate),
    };
    setOpen(false);
    return dispatch(setFilterRange(data));
  };

  // eslint-disable-next-line
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 40px',
      }}
    >
      <button type="button" className="datapicker-input" onClick={onClick} ref={ref}>
        {value}
      </button>
      <div className="filter__button">
        <button className="filter__cancel" type="button" onClick={() => handlerCancel()}>
          Cancel
        </button>
        <button className="filter__apply" type="button" onClick={() => handlerApply()}>
          Apply
        </button>
      </div>
    </div>
  ));

  const handlerSelect = (value, from, to) => {
    if (value === 'Custom') {
      return setValue(value);
    }
    if (value === 'All Time') {
      setOpen(false);
      setValue(value);
      return dispatch(clearFilterRange());
    }
    setValue(value);
    const data = {
      from: JSON.stringify(from),
      to: JSON.stringify(to),
    };
    setOpen(false);
    return dispatch(setFilterRange(data));
  };

  return (
    <div className="filter" style={{ marginLeft: type === 'table' ? 0 : '55px' }}>
      {isOpen ? (
        <div className="filter__select" style={{ padding: 0 }} onClick={() => setOpen(!isOpen)}>
          <div className="filter__select__open">
            <img src={clockWhite} alt="icon" className="filter__arrow" />
            <span>{value}</span>
          </div>
          <span className="filter__arrow__open">
            <img src={arrowUp} alt="arrow down" />
          </span>
        </div>
      ) : (
        <div className="filter__select" onClick={() => setOpen(!isOpen)}>
          <img src={clockIcon} alt="icon" className="filter__arrow" />
          <span>{value}</span>
          <img className="filter__arrow__close" src={arrowDown} alt="arrow down" />
        </div>
      )}
      {isOpen ? (
        <div className="modal__filter">
          <div>
            {dataTransactions.map((item) => {
              const active = value === item.label;
              return (
                <div
                  key={item.id}
                  className={`${active ? 'modal__item active' : 'modal__item'}`}
                  onClick={() => handlerSelect(item.label, item.start, item.end)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          {value === 'Custom' && (
            <div className="filter__calendar">
              <DatePicker
                selected={startDate}
                open={true}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                monthsShown={2}
                calendarContainer={MyContainer}
                customInput={<ExampleCustomInput />}
                dateFormat="MMM dd, y"
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AnalyticsFilter;
