import React from 'react';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';

function Select({ label, ...rest }) {
  const selectStyles = {
    container: (styles) => ({
      ...styles,
      fontSize: '12px',
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '4px',
      border: isFocused ? '1px solid var(--color-primary-5)' : '1px solid var(--color-grey-2)',
      boxShadow: 'none',

      ':hover': {
        border: isFocused ? '1px solid var(--color-primary-5)' : '1px solid var(--color-grey-3)',
        boxShadow: 'none',
      },
    }),
    placeholder: (styles) => ({ ...styles, fontSize: '12px', color: 'var(--color-grey-4)' }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontSize: '12px',
      backgroundColor: isSelected
        ? 'var(--color-primary-05)'
        : isFocused
        ? 'var(--color-grey-05)'
        : undefined,
      color: 'var(--color-grey-7)',
      borderRadius: '4px',
    }),
    menu: (styles) => ({
      ...styles,
      padding: '8px',
      boxShadow: 'none',
      border: '1px solid var(--color-primary-5)',
      borderRadius: '8px',
    }),
  };

  return (
    <div>
      <label className={styles.label}>{label}</label>
      <ReactSelect
        styles={selectStyles}
        components={{ IndicatorSeparator: () => null }}
        {...rest}
      />
    </div>
  );
}

export default Select;
