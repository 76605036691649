import getRequest from './get';
import { postFormData, postRequest } from './post';
import { putRequest } from './put';
import { patchRequest } from './patch';
import deleteRequest from './delete';

const url = 'companies';

// GET requests
export const getCompanies = async (query = '', page = 1) => {
  return getRequest(`${url}?${query}&page=${page}`);
};

export const getCompaniesNames = async () => {
  return getRequest(`${url}/all`);
};

export const getIndustriesFilter = async () => {
  return getRequest(`${url}/industries`);
};

export const getCompanyHashtags = async () => {
  return getRequest(`${url}/hashtags`);
};

export const getCompanyInfo = async (id) => {
  return getRequest(`${url}/${id}`);
};

export const getCompanyUsers = async (query = '', page = 1) => {
  return getRequest(`${url}/users?${query}&page=${page}`);
};

export const getCompanyUser = async (userId) => {
  return getRequest(`${url}/users/${userId}`);
};

export const getCompanyReward = async () => {
  return getRequest(`${url}/reward`);
};

// POST requests
export const postNewCompanyHashtag = async (data) => {
  return postRequest(`${url}/hashtags?value=${data}`);
};

export const postCompanyLogo = async (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return postFormData(`${url}/logo`, formData);
};

export const postCompanyBrandImage = async (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return postFormData(`${url}/brandImage`, formData);
};

export const postExportCompaniesCSV = async (ids) => {
  return postRequest(`${url}/csv`, ids);
};

// PUT requests
export const putCompanyInfo = async (data) => {
  return putRequest(url, data);
};

export const fireUsers = async (data) => {
  return putRequest(`${url}/fire`, data);
};

// PATCH requests
export const editCompanyReward = async (data) => {
  return patchRequest(`${url}/reward`, data);
};

// DELETE requests
export const deleteCompanyBrandImage = async () => {
  return deleteRequest(`${url}/brandImage`);
};

export const deleteCompanyLogo = async () => {
  return deleteRequest(`${url}/logo`);
};
