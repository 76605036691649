import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { getCompanyUsers } from '../../api/companies';
import {
  clearCompanyUsers,
  setCompanyUsers,
  setCompanyUsersTotal,
} from '../../features/company/company.slice';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

import UserItem from './UserItem/UserItem';

import usersIcon from '../../assets/icons/users.svg';
import usersWhite from '../../assets/icons/usersWhite.svg';
import searchIcons from '../../assets/icons/searchIcons.svg';
import arrowDown from '../../assets/icons/arrowDown.svg';
import arrowUp from '../../assets/icons/arrowUp.svg';

import styles from './AnalyticsFilterByUser.module.scss';

const AnalyticsFilterByUser = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const users = useSelector((state) => state.company.users.list);
  const userIds = useSelector((state) => state.transactions.filter.userIds);
  const findUser =
    userIds && userIds.length > 0 ? users.find((item) => userIds.includes(item.id)) : 'All users';
  const filterData = typeof findUser === 'object' ? findUser.fullName : 'All users';
  const [pageNumber, setPageNumber] = useState(1);

  const debouncedFilter = useCallback(
    debounce(
      (filterValue) =>
        setSearch(() => {
          return filterValue;
        }),
      300
    ),
    []
  );

  useEffect(() => {
    getCompanyUsers().then((res) => {
      dispatch(setCompanyUsersTotal(res.total));
    });
    return () => dispatch(clearCompanyUsers());
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [userIds]);

  useEffect(() => {
    setPageNumber(1);
  }, [search]);
  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    getCompanyUsers,
    setCompanyUsers,
    users.length,
    search !== '' ? `search=${search}` : '',
    clearCompanyUsers
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div className="filter">
      {isOpen ? (
        <div
          className={styles.filter__select}
          style={{ padding: 0 }}
          onClick={() => setOpen(!isOpen)}
        >
          <div className={styles.filter__select__open}>
            <img style={{ marginLeft: '10px', marginRight: '10px' }} src={usersWhite} alt="icon" />
            <span style={{ marginRight: '10px' }}>{filterData}</span>
          </div>
          <span className={styles.filter__arrow__open}>
            <img src={arrowUp} alt="arrow down" />
          </span>
        </div>
      ) : (
        <div className={styles.filter__select} onClick={() => setOpen(!isOpen)}>
          <img
            style={{ marginLeft: '10px', marginRight: '10px' }}
            src={usersIcon}
            alt="icon"
            className={styles.filter__icons}
          />
          <span style={{ marginRight: '10px' }}>{filterData}</span>
          <img className={styles.filter__arrow__close} src={arrowDown} alt="arrow down" />
        </div>
      )}
      {isOpen ? (
        <div className={styles.modal__user__filter}>
          <label className={styles.filter__input__wrapper} htmlFor="users">
            <img className={styles.filter__input__icon} src={searchIcons} alt="search" />
            <input
              value={search}
              className={styles.filter__input}
              type="text"
              placeholder="Search for name"
              onChange={(e) => debouncedFilter(e.target.value)}
            />
          </label>
          {users.map((item) => {
            const active = filterData === item.fullName;
            return (
              <UserItem
                key={item.id}
                ref={lastElementRef}
                id={item.id}
                fullName={item.fullName}
                role={item.role}
                avatar={item.avatar}
                active={active}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default AnalyticsFilterByUser;
