import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.scss';
import { ReactComponent as Error } from '../../assets/icons/error-icon.svg';

function Checkbox({ isInvalid, children, onClick, isSwitch = false, ...rest }) {
  return (
    <>
      <label className={cn(styles.checkbox, { [styles.switch]: isSwitch })}>
        <input type="checkbox" onClick={onClick} {...rest} />
        <span className={styles.checkBtn} />
        {children && children}
      </label>
      {isInvalid && (
        <div className={styles.error}>
          <Error />
          {isInvalid}
        </div>
      )}
    </>
  );
}

export default Checkbox;
