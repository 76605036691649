import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './ClientsList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import {
  clearCompanies,
  setAllCompaniesSelected,
  setCompanies,
} from '../../features/clients/clients.slice';
import { getCompanies } from '../../api/companies';
import CompaniesListItem from './CompaniesListItem';

function CompaniesList({ filter, allChecked, setAllChecked, setCompanyInfo, setCompanyInfoModal }) {
  const companies = useSelector((state) => state.clients.companies.list);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearCompanies());
  }, [dispatch]);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    getCompanies,
    setCompanies,
    companies.length,
    `${filter.input && 'search='}${filter.input}${
      filter.industry && '&industry='
    }${encodeURIComponent(filter.industry)}${filter.employees && '&employees='}${
      filter.employees
    }` || '',
    clearCompanies
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const selectAllCompanies = () => {
    dispatch(setAllCompaniesSelected(!allChecked));
    setAllChecked(!allChecked);
  };

  return (
    <ul className={cn(styles.list, styles.companiesList)}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox checked={allChecked} onChange={selectAllCompanies} />
        </div>
        <div className={styles.col}>Name</div>
        <div className={styles.col}>#Employees</div>
        <div className={styles.col}>Billing Admin</div>
        <div className={styles.col}>Credit Card</div>
        <div className={styles.col}># Enrolled Employees</div>
        <div className={styles.col}>Billing vs Transactions</div>
        <div className={styles.col}>Website</div>
        <div className={styles.col}></div>
      </li>
      {companies.map((company, index) => {
        if (companies.length === index + 1) {
          return (
            <CompaniesListItem
              ref={lastElementRef}
              setCompanyInfoModal={setCompanyInfoModal}
              setCompanyInfo={setCompanyInfo}
              company={company}
              key={index}
            />
          );
        }
        return (
          <CompaniesListItem
            company={company}
            setCompanyInfoModal={setCompanyInfoModal}
            setCompanyInfo={setCompanyInfo}
            key={index}
          />
        );
      })}
    </ul>
  );
}

export default CompaniesList;
