import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getRequest from '../../api/get';

import Hashtags from './Hashtags/Hashtags';
import { setHashtags } from '../../features/hashtags/hashtags.slice';

import hashtagIcon from '../../assets/images/hashtagIcon.svg';

import styles from './HashtagsList.module.scss';

const HashtagsList = ({ hashtagsLength, hashtagsArraySelect }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.hashtags.list);
  useEffect(() => {
    getRequest('kudos-wall/hashtags').then((res) => {
      try {
        if (res) {
          dispatch(setHashtags(res));
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.hashtags__wrapper}>
      <div className={styles.hashtags__title}>
        <img src={hashtagIcon} alt="hashtags icon" />
        Hashtags
      </div>
      <div className={styles.hashtags__list}>
        {data.length > 0 &&
          data.map((item) => (
            <Hashtags
              key={item.id}
              id={item.id}
              value={item.value}
              hashtagsLength={hashtagsLength}
              hashtagsArraySelect={hashtagsArraySelect}
            />
          ))}
      </div>
    </div>
  );
};

export default HashtagsList;
