import { API_BASE } from './constants';

const deleteRequest = async (url, data = {}) => {
  const token = localStorage.getItem('user');
  const response = await fetch(`${API_BASE}${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  const json = await response.json();
  json.ok = response.ok;

  return json;
};

export default deleteRequest;
