import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setTypeTable, clearFilter } from '../../features/transactions/transactions.slice';

import AnalyticsFilter from '../AnalyticsFilter/AnalyticsFilter';
import AnalitycsRecent from '../AnalitycsRecent/AnalitycsRecent';
import TransactionsKudosTable from './TransactionsKudosTable/TransactionsKudosTable';
import TransactionsAddonsTable from './TransactionsAddonsTable/TransactionsAddonsTable';

import styles from './Transactions.module.scss';

const Transactions = () => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const kudos = useSelector((state) => state.transactions.kudos.list);
  const addon = useSelector((state) => state.transactions.addons.list);
  const typeTable = useSelector((state) => state.transactions.type);

  const filterData = useSelector((state) => state.transactions.filter);

  const [filterInput] = useState('');
  const [allKudosChecked, setAllKudosChecked] = useState(false);
  const [allAddonsChecked, setAllAddonsChecked] = useState(false);
  const [type, setTypeUser] = useState('user');

  useEffect(() => {
    if (role === 'Admin') {
      setTypeUser('admin');
    } else {
      setTypeUser('user');
    }
  }, [type, role]);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.filter__wrapper}>
          <AnalyticsFilter type="table" />
          <AnalitycsRecent />
        </div>
        <div className={styles.title__wrapper}>
          <div
            className={styles.title}
            onClick={() => {
              dispatch(setTypeTable('kudos'));
              dispatch(clearFilter());
            }}
            style={{
              borderBottom: typeTable === 'kudos' ? '3px solid rgba(46, 144, 250, 1)' : null,
              color: typeTable === 'kudos' ? 'rgba(46, 144, 250, 1)' : null,
            }}
          >
            Kudos History
          </div>
          <div
            className={styles.title}
            onClick={() => {
              dispatch(setTypeTable('addons'));
              dispatch(clearFilter());
            }}
            style={{
              borderBottom: typeTable === 'addons' ? '3px solid rgba(46, 144, 250, 1)' : null,
              color: typeTable === 'addons' ? 'rgba(46, 144, 250, 1)' : null,
            }}
          >
            Perks History
          </div>
        </div>
        {typeTable === 'kudos' ? (
          <TransactionsKudosTable
            data={kudos}
            type={type}
            filter={filterInput}
            filterData={filterData}
            allChecked={allKudosChecked}
            setAllChecked={setAllKudosChecked}
            //   setUserInfoModal={openUserInfoModal}
          />
        ) : (
          <TransactionsAddonsTable
            addonData={addon}
            type={type}
            filter={filterInput}
            filterData={filterData}
            allChecked={allAddonsChecked}
            setAllChecked={setAllAddonsChecked}
            //   setUserInfoModal={openUserInfoModal}
          />
        )}
      </div>
    </div>
  );
};

export default Transactions;
