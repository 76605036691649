import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './AddWallet.module.scss';
import Select from '../../../UI/Select/Select';
import { walletPhrasesLengths } from '../../../constants/select';
import { ReactComponent as Exclamation } from '../../../assets/icons/exclamation-mark.svg';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import { walletPhrase } from '../../../api/users';

function AddWallet({ setStep, currentStep }) {
  const [wordsNumber, setWordsNumber] = useState(12);
  const [pastedWords, setPastedWords] = useState(null);
  const [formikInitial, setFormikInitial] = useState({});

  useEffect(() => {
    if (pastedWords) {
      setFormikInitial(Object.fromEntries(pastedWords.map((word, index) => [index + 1, word])));
    } else {
      setFormikInitial(
        Object.fromEntries([...Array(wordsNumber)].map((item, index) => [index + 1, '']))
      );
    }
  }, [wordsNumber, pastedWords]);

  const createValidationSchema = (obj) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      result[key] = Yup.string().required('required');
    });
    return result;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitial,
    validationSchema: Yup.object().shape({
      ...createValidationSchema(formikInitial),
    }),
    onSubmit: (values) => {
      walletPhrase({ mnemonic: Object.values(values).join(' ') }).then(() =>
        setStep(currentStep + 1)
      );
    },
  });

  const pasteFunction = (event) => {
    event.preventDefault();
    const wordsArray = event.clipboardData.getData('text/plain').split(' ');
    setPastedWords(wordsArray);
    setWordsNumber(wordsArray.length);
  };

  return (
    <div className={styles.phraseForm}>
      <Select
        onChange={(item) => setWordsNumber(item.value)}
        label="Select Recovery Phrase format"
        isSearchable={false}
        value={
          walletPhrasesLengths[
            walletPhrasesLengths.indexOf(
              walletPhrasesLengths.find((obj) => obj.value === wordsNumber)
            )
          ]
        }
        options={walletPhrasesLengths}
      />
      <p>
        <Exclamation /> You can paste your entire secret recovery phrase into any field
      </p>

      <form onSubmit={formik.handleSubmit} className={styles.form}>
        {Object.keys(formikInitial).map((item, index) => (
          <div className={styles.formItem} key={index}>
            <Input
              type="password"
              name={item}
              value={formik.values[item] || ''}
              placeholder={item}
              isInvalid={formik.touched[item] && formik.errors[item]}
              onPaste={pasteFunction}
              onChange={formik.handleChange}
            />
          </div>
        ))}
        <div className={styles.buttons}>
          <Button theme="bordered" onClick={() => setStep(currentStep - 1)}>
            Back
          </Button>
          <Button type="submit">Continue</Button>
        </div>
      </form>
    </div>
  );
}

export default AddWallet;
