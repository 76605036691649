import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setFilterHashtags, clearHashtagsFilter } from '../../features/hashtags/hashtags.slice';

import hashtagIcon from '../../assets/images/hashtagIcon.svg';
import closeIcon from '../../assets/images/closeIcon.svg';

import styles from './HashtagsFilter.module.scss';

const HashtagsFilter = ({ filterHashtags }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.hashtags.list);
  const itemValue =
    data && data.length > 0 ? data.filter((item) => filterHashtags.includes(item.id)) : '';

  const handlerSelect = (id) => {
    dispatch(setFilterHashtags(id));
  };

  const handleClearHashtagsFilter = () => {
    dispatch(clearHashtagsFilter());
  };

  return (
    <div className={styles.hashtags__wrapper}>
      <div className={styles.hashtags__item__wrapper}>
        {itemValue && itemValue.length > 0
          ? itemValue.map((item) => {
              return (
                <div
                  key={item.id}
                  className={styles.hashtags__item}
                  onClick={() => handlerSelect(item.id)}
                >
                  <img src={hashtagIcon} alt="hashtag icon" />
                  {item.value}
                  <img className={styles.hashtags__icon} src={closeIcon} alt="close icon" />
                </div>
              );
            })
          : null}
      </div>

      <div className={styles.hashtags__clear} onClick={() => handleClearHashtagsFilter()}>
        <img src={closeIcon} alt="close icon" />
        <span className={styles.hashtags__clear__value}>Clear all</span>
      </div>
    </div>
  );
};

export default HashtagsFilter;
