import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const commetnSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setComment(state, action) {
      state.list = action.payload;
    },
    clearComment(state) {
      state.list = [];
    },

    updateComment(state, action) {
      if (state.list && state.list.length > 0) {
        const { payload } = action;
        state.list = [...state.list, ...payload];
      } else {
        state.list = action.payload;
      }
    },

    updateCommentItem(state, action) {
      const { type, index } = action.payload;
      state.list[index] = type;
    },

    setReply(state, action) {
      const { list, commentId } = action.payload;
      state.list.find((item) => {
        if (item.id === commentId) {
          item.reply = list;
          return true;
        }
        return false;
      });
    },
  },
});

export const { setComment, setReply, clearComment, updateComment, updateCommentItem } =
  commetnSlice.actions;
export default commetnSlice.reducer;
