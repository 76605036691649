import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import ReactTooltip from 'react-tooltip';
import styles from '../scss/pages/RewardSettings.module.scss';
import Input from '../UI/Input/Input';
import StepChooser from '../components/StepChooser/StepChooser';
import Radio from '../UI/Radio/Radio';
import Checkbox from '../UI/Checkbox/Checkbox';
import { editCompanyReward, getCompanyReward } from '../api/companies';
import { ReactComponent as Info } from '../assets/icons/exclamation-mark.svg';

function RewardSettings() {
  const allowanceOptions = [50, 100, 150, 200];
  const [chosenValue, setChosenValue] = useState(0);
  const [formikInitial, setFormikInitial] = useState({
    allowanceRegularity: 'Weekly',
    allowancePerUser: 0,
    allowanceMaximum: true,
    kudosSending: 0,
    kudosAddOn: 0,
    birthdayAddOn: 0,
    workAnniversaryAddOn: 0,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitial,
    onSubmit: (values) => {
      editCompanyReward(values);
    },
  });

  useEffect(() => {
    getCompanyReward().then((res) => {
      setFormikInitial(res);
      setChosenValue(res.allowancePerUser);
    });
  }, []);

  const changeChosenValue = (value) => {
    formik.setFieldValue('allowancePerUser', value);
    setChosenValue(value);
    formik.handleSubmit();
  };

  const changeRegularity = (regularity) => {
    formik.setFieldValue('allowanceRegularity', regularity);
    formik.handleSubmit();
  };

  const debouncedSubmit = useCallback(
    debounce(() => formik.handleSubmit(), 300),
    []
  );

  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, Number(value));
    debouncedSubmit();
  };

  const toggleCheckbox = (name, value) => {
    formik.setFieldValue(name, value);
    formik.handleSubmit();
  };

  return (
    <div className={styles.page}>
      <h2>Reward Settings</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className={styles.settingsBlock}>
          <h3>Allowance</h3>
          <div className={styles.radioGroup}>
            <Radio
              name="Weekly"
              checked={formik.values.allowanceRegularity === 'Weekly'}
              onChange={(e) => changeRegularity(e.target.name)}
            >
              Weekly
            </Radio>
            <Radio
              name="Monthly"
              checked={formik.values.allowanceRegularity === 'Monthly'}
              onChange={(e) => changeRegularity(e.target.name)}
            >
              Monthly
            </Radio>
          </div>
          <div className={styles.chooser}>
            <StepChooser
              chosenValue={chosenValue}
              setChosenValue={changeChosenValue}
              stepOptions={allowanceOptions}
            />
            or
            <div className={styles.input}>
              <Input
                name="allowancePerUser"
                value={formik.values.allowancePerUser}
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                  setChosenValue(e.target.value);
                }}
                label="Set a custom amount"
                placeholder="Enter amount of allowance"
              />
            </div>
          </div>
          <Checkbox
            isSwitch
            name="allowanceMaximum"
            onChange={formik.handleChange}
            checked={formik.values.allowanceMaximum}
            onClick={(e) => toggleCheckbox(e.target.name, formik.values[e.target.name])}
          >
            Make {formik.values.allowancePerUser} a {formik.values.allowanceRegularity} Allowance
            Maximum
            <div className={styles.infoMark}>
              <Info
                data-for="maximum-allowance"
                data-tip="By disabling, employees will receive additional funds to their allowance regardless of current balance."
              />

              <ReactTooltip
                id="maximum-allowance"
                effect="solid"
                place="bottom"
                className="limitedWidthTooltip"
              />
            </div>
          </Checkbox>
        </div>
        <div className={styles.settingsBlock}>
          <h3>Kudos</h3>

          <div className={styles.inputs}>
            <div className={styles.input}>
              <Input
                name="kudosSending"
                value={formik.values.kudosSending}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                label="Sending a Kudos"
                placeholder="25$"
              />
            </div>
            <div className={styles.input}>
              <Input
                name="kudosAddOn"
                value={formik.values.kudosAddOn}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                label="Kudos Add On"
                placeholder="1$"
              />
            </div>
          </div>
        </div>
        <div className={styles.settingsBlock}>
          <h3>Add On</h3>

          <div className={styles.inputs}>
            <div className={styles.input}>
              <Input
                name="birthdayAddOn"
                value={formik.values.birthdayAddOn}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                label="Birthday Add On"
                placeholder="1$"
              />
            </div>
            <div className={styles.input}>
              <Input
                name="workAnniversaryAddOn"
                value={formik.values.workAnniversaryAddOn}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                label="Work Anniversary Add On"
                placeholder="1$"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RewardSettings;
