import { API_BASE, magic } from './constants';

export const login = async (url, data) => {
  const didToken = await magic.auth.loginWithMagicLink({ email: data.email.toLowerCase() });

  const response = await fetch(`${API_BASE}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${didToken}`,
    },
    body: JSON.stringify({ email: data.email.toLowerCase() }),
  });

  const json = await response.json();
  json.ok = response.ok;

  if (response.ok) {
    const token = await magic.user.getIdToken({ lifespan: 605000 });
    localStorage.setItem('user', `${token}`);
  }

  return json;
};

const redirectBase = window.location.href.includes('localhost')
  ? 'http://localhost:3000'
  : 'https://app.cryptoperk.co/';

export const loginWithGoogle = async () => {
  const didToken = await magic.oauth.loginWithRedirect({
    provider: 'google',
    redirectURI: `${redirectBase}/auth/login`,
  });
  return didToken;
};

export const registerWithGoogle = async () => {
  const didToken = await magic.oauth.loginWithRedirect({
    provider: 'google',
    redirectURI: `${redirectBase}/auth/signup`,
  });
  return didToken;
};

export const loginUserFromRedirect = async () => {
  const fromGoogle = await magic.oauth.getRedirectResult();

  const response = await fetch(`${API_BASE}auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${fromGoogle.magic.idToken}`,
    },
    body: JSON.stringify({ email: fromGoogle.oauth.userInfo.email }),
  });

  const json = await response.json();
  json.ok = response.ok;

  if (response.ok) {
    const token = await magic.user.getIdToken({ lifespan: 605000 });
    localStorage.setItem('user', `${token}`);
  }

  return { fromGoogle, json };
};
