import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useInfiniteScrollAnalytics(
  pageNumber,
  requestFunction,
  updateFunction,
  currentLength,
  data,
  type,
  clearFunction = undefined
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFunction());
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    requestFunction(data, type, pageNumber).then((res) => {
      dispatch(updateFunction(res.list));
      setHasMore(currentLength < res.total);
      setLoading(false);
    });
  }, [data, pageNumber]);

  return { loading, error, hasMore };
}
