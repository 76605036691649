import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import styles from './ImageUploader.module.scss';
import { ReactComponent as Upload } from '../../assets/icons/upload-cloud.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash-2.svg';
import {
  deleteCompanyBrandImage,
  deleteCompanyLogo,
  postCompanyBrandImage,
  postCompanyLogo,
} from '../../api/companies';
import { updateCompanyBrandImage, updateCompanyLogo } from '../../features/company/company.slice';
import Spinner from '../../UI/Spinner/Spinner';

function ImageUploader({ name, imageURL }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    switch (name) {
      case 'logo':
        setIsLoading(true);
        postCompanyLogo(acceptedFiles[0]).then((res) => {
          setIsLoading(false);
          dispatch(updateCompanyLogo(res.logo));
        });
        break;
      case 'brandImage':
        setIsLoading(true);
        postCompanyBrandImage(acceptedFiles[0]).then((res) => {
          setIsLoading(false);
          dispatch(updateCompanyBrandImage(res.brandImage));
        });
        break;
      default:
    }
    // eslint-disable-next-line
  }, []);

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: false,
  });

  const handleDelete = () => {
    switch (name) {
      case 'logo':
        deleteCompanyLogo().then((res) => {
          dispatch(updateCompanyLogo(res.logo));
        });
        break;
      case 'brandImage':
        deleteCompanyBrandImage().then((res) => {
          dispatch(updateCompanyBrandImage(res.brandImage));
        });
        break;
      default:
    }
  };

  return (
    // eslint-disable-next-line
    <div
      // eslint-disable-next-line
      {...getRootProps({ className: 'dropzone disabled' })}
      className={styles.dropzoneWrapper}
      style={{
        background: imageURL && `url(${imageURL}) no-repeat center / cover`,
      }}
      onClick={() => open()}
    >
      {isLoading && <Spinner />}
      {/* eslint-disable-next-line */}
      <input {...getInputProps()} />

      {!imageURL && !isLoading ? (
        <div className={styles.uploadButton}>
          <div className={styles.cloudWrapper}>
            <Upload />
          </div>
          <p>
            <span>Click to upload photo</span> or drag and drop
          </p>
          <p>SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      ) : (
        <div className={styles.changeImage}>
          <div className={styles.cloudWrapper}>
            <Upload />
          </div>
          <div className={styles.trashWrapper}>
            <Trash
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
