import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { hideModal, showModal } from '../../features/modal/modal-slice';
import styles from './ProfileDetails.module.scss';
import Input from '../../UI/Input/Input';
import AvatarUploader from '../AvatarUploader/AvatarUploader';
import Button from '../../UI/Button/Button';
import Modal from '../Modal/Modal';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { editUser } from '../../api/users';
import { magic } from '../../api/constants';
import { setLoggedOut } from '../../features/auth/auth-slice';
import deleteUsers from '../../api/deleteUsers';

function ProfileDetails() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSaveButton, setShowSaveButton] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      username: user.username || '',
      email: user.email || '',
      phone: user.phone || '',
      dateOfBirth: user.dateOfBirth || null,
      startOfWork: user.startOfWork || null,
    },
    onSubmit: (values) => {
      editUser(values).then(() => setShowSaveButton(false));
    },
  });

  const handleLogout = async () => {
    await magic.user.logout().then(() => localStorage.clear());
  };

  const deleteHandler = () => {
    deleteUsers('users').then((res) => {
      localStorage.removeItem('user');
      dispatch(hideModal());
      navigate('/auth/login');
      return res;
    });
  };

  return (
    <div className={styles.wrapper}>
      {showSaveButton && (
        <div className={styles.saveButtons}>
          <Button theme="bordered">Cancel</Button>
          <Button onClick={() => formik.submitForm()}>Save</Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <h3>Personal info</h3>
          <p>Update your photo and personal details here</p>
        </div>
        <div className={styles.secondaryTitleWrapper}>
          <div className={styles.textWrapper}>
            <h4>Your photo</h4>
            <p>This will be displayed on your profile</p>
          </div>
          <AvatarUploader avatarURL={user.avatar} />
        </div>

        <form onSubmit={formik.handleSubmit} onChange={() => setShowSaveButton(true)}>
          <div className={styles.inputWrapper}>
            <h4>Name</h4>
            <div className={styles.nameFieldsWrapper}>
              <div className={styles.fieldWrapper}>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                  name="firstname"
                  placeholder="First name"
                />
              </div>
              <div className={styles.fieldWrapper}>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                  name="lastname"
                  placeholder="Last name"
                />
              </div>
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>User name</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.username}
                name="username"
                placeholder="User name"
              />
            </div>
            <div className={styles.offset} />
          </div>
          <div className={styles.inputWrapper}>
            <h4>Date of Birth</h4>
            <div className={styles.fieldWrapper}>
              <DatePicker
                name="dateOfBirth"
                startDate={formik.values.dateOfBirth}
                onChange={formik.setFieldValue}
              />
            </div>
            <div className={styles.offset} />
          </div>
          <div className={styles.inputWrapper}>
            <h4>Start of Work</h4>
            <div className={styles.fieldWrapper}>
              <DatePicker
                name="startOfWork"
                startDate={formik.values.startOfWork}
                onChange={formik.setFieldValue}
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Email address</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                placeholder="Email address"
              />
            </div>
            <div className={styles.offset} />
          </div>

          <div className={styles.inputWrapper}>
            <h4>Phone number</h4>
            <div className={styles.fieldWrapper}>
              <Input
                onChange={formik.handleChange}
                value={formik.values.phone}
                name="phone"
                placeholder="Phone number"
              />
            </div>
            <div className={styles.offset} />
          </div>
        </form>
        <div className={cn(styles.inputWrapper, styles.inputWrapper_buttons)}>
          <div className={styles.leftOffset} />
          <div className={styles.buttonsWrapper}>
            <Button theme="bordered" onClick={() => dispatch(showModal())}>
              Delete Account
            </Button>
            <Button
              onClick={() => {
                handleLogout().then(() => dispatch(setLoggedOut()));
              }}
            >
              Logout
            </Button>
          </div>
          <div />
        </div>
      </div>
      <Modal>
        <p>Are you sure you want to delete your account?</p>
        <div className={styles.modalButtons}>
          <Button onClick={() => deleteHandler()}>Yes</Button>
          <Button theme="bordered" onClick={() => dispatch(hideModal())}>
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ProfileDetails;
