import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getRequest from '../../api/get';

import Anniversaries from './Anniversaries/Anniversaries';
import { setAnniversaries } from '../../features/anniversaries/anniversaries.slice';

import anniversariesIcon from '../../assets/images/anniversaries.svg';

import styles from './Anniversaries.module.scss';

const AnniversariesList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.anniversaries.list);
  const companyId = useSelector((state) => state.company.info.id);
  useEffect(() => {
    if (companyId) {
      getRequest(`kudos-wall/workAnniversaries?getClosest4=true&companyId=${companyId}`).then(
        (res) => {
          try {
            // console.log(res);
            if (res && res.list) {
              dispatch(setAnniversaries(res.list));
            }
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [companyId]);

  return (
    <div className={styles.anniversaries__wrapper}>
      <div className={styles.anniversaries__title}>
        <img src={anniversariesIcon} alt="anniversaries icon" />
        Upcoming work anniversaries
      </div>
      {data.length > 0 &&
        data.map((item) => (
          <Anniversaries
            key={item.id}
            id={item.id}
            avatar={item.avatar}
            lastname={item.lastname}
            firstname={item.firstname}
            startOfWork={item.startOfWork}
            addedOnByCurrentUser={item.addedOnByCurrentUser}
          />
        ))}
    </div>
  );
};

export default AnniversariesList;
