import React, { forwardRef, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import styles from './ClientsList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg';
import { ReactComponent as Kebab } from '../../assets/icons/kebab-icon.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { showModal } from '../../features/modal/modal-slice';
import { getCompanyInfo } from '../../api/companies';
import { toggleCompanySelected } from '../../features/clients/clients.slice';
import { getClickableLink } from '../../helpers/common.helpers';

// eslint-disable-next-line react/display-name
const CompaniesListItem = forwardRef(({ company, setCompanyInfo, setCompanyInfoModal }, ref) => {
  const [showKebab, setShowKebab] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowKebab(false));

  const dispatch = useDispatch();

  const toggleCheckbox = () => {
    dispatch(toggleCompanySelected(company.id));
  };

  return (
    <li ref={ref} className={cn(styles.row, { [styles.rowSelected]: company.selected })}>
      <div>
        <Checkbox onChange={() => toggleCheckbox()} checked={company.selected} />
      </div>
      <div className={styles.col}>
        <div
          className={styles.userImage}
          style={{
            background: company.logo && `url(${company.logo}) no-repeat center / cover`,
          }}
        ></div>
        <div className={styles.name}>
          {company.name}
          <span>{company.industry.value}</span>
        </div>
      </div>

      <div className={styles.col}>{company._count.users}</div>

      <div className={styles.col}>
        <div
          className={styles.userImage}
          style={{
            background:
              company?.billingAdmin?.avatar &&
              `url(${company?.billingAdmin?.avatar}) no-repeat center / cover`,
          }}
        ></div>
        <div className={styles.name}>
          {company.billingAdmin?.firstname} {company.billingAdmin?.lastname}
          <span>{company.billingAdmin?.email}</span>
        </div>
      </div>

      <div className={styles.col}>card data temp</div>

      <div className={styles.col}>
        {company.enrolledUsers}/{company._count.users}
      </div>

      <div className={styles.col}>billing data temp</div>

      <div className={styles.col}>
        <a href={getClickableLink(company?.website)}>{company?.website}</a>
      </div>

      <div
        ref={menuRef}
        className={cn(styles.col, styles.kebabMenu)}
        onClick={() => setShowKebab(!showKebab)}
      >
        <div className={cn(styles.userMenu, { [styles.userMenu_open]: showKebab })}>
          <div
            className={styles.userMenu_item}
            onClick={() => {
              setCompanyInfo(null);
              setCompanyInfoModal();
              dispatch(showModal(true));
              getCompanyInfo(company.id).then((res) => setCompanyInfo(res));
            }}
          >
            <UserIcon />
            View profile
          </div>
        </div>
        <Kebab />
      </div>
    </li>
  );
});

export default CompaniesListItem;
