import * as Yup from 'yup';

const stepOneSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('This field is required'),
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  username: Yup.string().required('This field is required'),
  recoveringEmail: Yup.string().email('Must be a valid email').required('This field is required'),
  acceptTerms: Yup.boolean().oneOf([true], 'Need to accept Terms & Conditions'),
});

export default stepOneSchema;
