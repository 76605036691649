import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './AvatarUploader.module.scss';
import { ReactComponent as Upload } from '../../assets/icons/upload-cloud.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash-2.svg';
import { deleteUserAvatar, sendUserAvatar } from '../../api/users';
import { updateAvatar } from '../../features/user/user.slice';
import Spinner from '../../UI/Spinner/Spinner';

function AvatarUploader({ avatarURL }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsLoading(true);
      sendUserAvatar(acceptedFiles[0]).then((res) => {
        setIsLoading(false);
        dispatch(updateAvatar(res.avatar));
      });
    },
    [dispatch]
  );

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: false,
  });

  const handleDelete = () => {
    deleteUserAvatar().then((res) => {
      dispatch(updateAvatar(res.avatar));
    });
  };

  return (
    // eslint-disable-next-line
    <div
      // eslint-disable-next-line
      {...getRootProps({ className: 'dropzone disabled' })}
      className={styles.avatarWrapper}
      style={{
        background: avatarURL && `url(${avatarURL}) no-repeat center / cover`,
        border: avatarURL && '1px solid #f9fafb',
      }}
      onClick={() => open()}
    >
      {isLoading && <Spinner />}
      {/* eslint-disable-next-line */}
      <input {...getInputProps()} />
      {!avatarURL && !isLoading ? (
        <div className={styles.uploadButton}>
          <div className={styles.cloudWrapper}>
            <Upload />
          </div>
          <p>Upload photo</p>
        </div>
      ) : (
        <div className={styles.changeAvatar}>
          <div className={styles.cloudWrapper}>
            <Upload />
          </div>
          <div className={styles.trashWrapper}>
            <Trash
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

AvatarUploader.propTypes = {
  avatarURL: PropTypes.string,
};

AvatarUploader.defaultProps = {
  avatarURL: null,
};

export default AvatarUploader;
