import getRequest from './get';
import { postRequest } from './post';
import { putRequest } from './put';

const url = 'billing';

// GET requests
export const getCurrentPlan = async () => {
  return getRequest(`${url}/plans/current`);
};

export const getPaymentInfo = async () => {
  return getRequest(`${url}/paymentInfo`);
};

export const getBillingInfo = async () => {
  return getRequest(`${url}/billingInfo`);
};

export const getPlansInfo = async () => {
  return getRequest(`${url}/plans`);
};

export const getPaymentHistory = async (query = '', page = '') => {
  return getRequest(`${url}/paymentHistory${page && `?page=${page}`}`);
};

// POST requests
export const checkoutSession = async (data) => {
  // eslint-disable-next-line no-return-await
  return postRequest(`${url}/checkout-session`, data);
};

// PUT requests
export const cancelSubscription = async () => {
  return putRequest(`${url}/subscriptions/cancel`);
};

export const updatePaymentMethod = async (data) => {
  return putRequest(`${url}/paymentInfo`, data);
};
