import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './StepTwo.module.scss';
import logo from '../../../../assets/images/logo.svg';
import StepProgress from '../../../StepProgress/StepProgress';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus-square.svg';
import AddWallet from '../../../CryptoWalletForms/AddWallet/AddWallet';
import CreateWallet from '../../../CryptoWalletForms/CreateWallet/CreateWallet';

function StepTwo({ setStep, currentStep }) {
  const [walletForm, setWalletForm] = React.useState(null);
  const [showQuiz, setShowQuiz] = React.useState(false);

  const createHandler = () => {
    setWalletForm('create');
  };

  return (
    <div className={cn(styles.wrapper, { [styles.wrapperWide]: !walletForm })}>
      <div className={styles.header}>
        <img src={logo} alt="" />
        <h2>
          {!walletForm && "Let's set up your crypto wallet"}
          {walletForm === 'add' && 'Import a wallet'}
          {walletForm === 'create' &&
            (showQuiz ? 'Confirm recovery phrase' : 'Create recovery phrase')}
        </h2>
        {walletForm === 'add' && (
          <p>
            Import a wallet with Secret Recovery Phrase. Only the first account on this wallet will
            be uploaded.
          </p>
        )}
        {walletForm === 'create' && (
          <p>You Secret Recovery Phrase makes it easy to back up and restore your account.</p>
        )}
      </div>
      <StepProgress currentStep={currentStep} steps={3} />

      {!walletForm && (
        <div className={styles.walletButtons}>
          <div className={styles.walletButton} onClick={() => setWalletForm('add')}>
            <DownloadIcon />
            <h3>Already have a crypto wallet?</h3>
            <p>Import your existing wallet using a Secret Recovery Phrase</p>
          </div>
          <div className={styles.walletButton} onClick={() => createHandler()}>
            <PlusIcon />
            <h3>Create a new crypto wallet</h3>
            <p>This will create a new wallet and Secret Recovery Phrase</p>
          </div>
        </div>
      )}

      {walletForm === 'add' && <AddWallet setStep={setStep} currentStep={currentStep} />}
      {walletForm === 'create' && (
        <CreateWallet
          setStep={setStep}
          currentStep={currentStep}
          setShowQuiz={setShowQuiz}
          showQuiz={showQuiz}
        />
      )}
    </div>
  );
}

StepTwo.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default StepTwo;
