import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UsersList.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import UsersListItem from './UsersListItem';
import { getCompanyUsers } from '../../api/companies';
import {
  clearCompanyUsers,
  setAllUsersSelected,
  setCompanyUsers,
  setCompanyUsersTotal,
} from '../../features/company/company.slice';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

function UsersList({
  filter,
  setFireData,
  setFireModal,
  setUserInfoModal,
  setUserInfo,
  allChecked,
  setAllChecked,
}) {
  const users = useSelector((state) => state.company.users.list);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    getCompanyUsers().then((res) => {
      dispatch(setCompanyUsersTotal(res.total));
    });
    return () => dispatch(clearCompanyUsers());
  }, [dispatch]);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);
  const { loading, hasMore } = useInfiniteScroll(
    pageNumber,
    getCompanyUsers,
    setCompanyUsers,
    users.length,
    `${filter.input && 'search='}${filter.input}${filter.role && '&role='}${filter.role}${
      filter.status && '&status='
    }${filter.status}` || '',
    clearCompanyUsers
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const selectAllUsers = () => {
    dispatch(setAllUsersSelected(!allChecked));
    setAllChecked(!allChecked);
  };

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox checked={allChecked} onChange={selectAllUsers} />
        </div>
        <div className={styles.col}>Name</div>
        <div className={styles.col}>Email</div>
        <div className={styles.col}>Role</div>
        <div className={cn(styles.col, styles.status_title)}>Status</div>
        <div className={styles.col}>Wallet Address</div>
        <div className={styles.col}></div>
      </li>
      {users.map((user, index) => {
        if (users.length === index + 1) {
          return (
            <UsersListItem
              setFireData={setFireData}
              setFireModal={setFireModal}
              setUserInfoModal={setUserInfoModal}
              setUserInfo={setUserInfo}
              ref={lastElementRef}
              user={user}
              key={index}
            />
          );
        }
        return (
          <UsersListItem
            setFireData={setFireData}
            setFireModal={setFireModal}
            setUserInfoModal={setUserInfoModal}
            setUserInfo={setUserInfo}
            user={user}
            key={index}
          />
        );
      })}
    </ul>
  );
}

export default UsersList;
