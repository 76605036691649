import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import styles from './TransactionsAddonsTable.module.scss';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import useInfiniteScrollAnalytics from '../../../hooks/useInfiniteScrollAnalytics';
import {
  clearTransactionsAddons,
  setTransactionsAddons,
  setAllTransactionsAddonsSelected,
  setFilterSortBy,
} from '../../../features/transactions/transactions.slice';
import { postAddons } from '../../../api/transactions';
import TransactionsAddonsItemTable from './TransactionsAddonsItemTable';

import splitArrow from '../../../assets/images/splitArrow.svg';

const TransactionsTable = ({ filter, allChecked, setAllChecked, type, addonData, filterData }) => {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    return () => dispatch(clearTransactionsAddons());
  }, [dispatch]);

  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  useEffect(() => {
    setPageNumber(1);
  }, [filterData]);

  const { loading, hasMore } = useInfiniteScrollAnalytics(
    pageNumber,
    postAddons,
    setTransactionsAddons,
    addonData.length,
    filterData,
    type,
    clearTransactionsAddons
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const selectAllUsers = () => {
    dispatch(setAllTransactionsAddonsSelected(!allChecked));
    setAllChecked(!allChecked);
  };

  return (
    <ul className={styles.list}>
      <li className={styles.title}>
        <div className={styles.col}>
          <Checkbox checked={allChecked} onChange={selectAllUsers} />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('DateTime'));
            setPageNumber(1);
          }}
        >
          Date & Time
          <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div className={styles.col}>Sender</div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Recipient'));
            setPageNumber(1);
          }}
        >
          Recipient
          <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Amount'));
            setPageNumber(1);
          }}
        >
          Amount <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div
          className={cn(styles.col, styles.img)}
          onClick={() => {
            dispatch(setFilterSortBy('Status'));
            setPageNumber(1);
          }}
        >
          Status <img className={styles.arrow} src={splitArrow} alt="arrow" />
        </div>
        <div className={styles.col}></div>
      </li>
      {addonData.map((user, index) => {
        if (addonData.length === index + 1) {
          return <TransactionsAddonsItemTable user={user} key={index} ref={lastElementRef} />;
        }
        return <TransactionsAddonsItemTable user={user} key={index} />;
      })}
    </ul>
  );
};

export default TransactionsTable;
