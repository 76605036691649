export const sortableArrayFromString = (string) => {
  return string.split(' ').map((word, index) => {
    return { name: word, id: index };
  });
};

export const shuffleArray = (array) => {
  return array.sort(() => Math.floor(Math.random() * Math.floor(3)) - 1);
};

export const sortableArrayToString = (array) => {
  return array.map((item) => item.name).join(' ');
};
